import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {Button, Checkbox, Select} from "antd";

import {AvailabilityQueryInput} from "../../shared-types/availabilityTypes";
import {addressDefaults} from "./order/controls/AddressInput";
import {AddressOrLineIdInputSlice} from "./order/slices/AddressOrLineIdInputSlice";
import {
  availabilityFormSchemaWithAddress,
  availabilityFormSchemaWithLineId
} from "../../schemas/availabilityFormSchema";
import {defaultValidationOptions} from "./validation";
import {AvailabilityLookupType} from "../../shared-types/availabilityTypes";
import {useDefaultsFromFormIntent} from "../../hooks/useDefaultsFromFormIntent";

const {Option} = Select;

export const FORM_AVAILABILITY = 'availability';

export const AvailabilityForm: React.FC<{
  loading?: boolean,
  onCheck: (queryInput: AvailabilityQueryInput, lookupType: AvailabilityLookupType, useCache?: boolean) => void,
}> = ({loading, onCheck}) => {

  const defaultsFromIntent = useDefaultsFromFormIntent(FORM_AVAILABILITY);

  const [isUsingLineId, setIsUsingLineId] = useState<boolean>(false);

  const schema = isUsingLineId ? availabilityFormSchemaWithLineId : availabilityFormSchemaWithAddress;

  const {control, errors, handleSubmit} = useForm({
    defaultValues: {
      address:    addressDefaults,
      lineId:     1,
      lookupType: 'FetchPassiveConnection',
      cache:      true,
      ...defaultsFromIntent,
    },
    resolver:      joiResolver(schema, defaultValidationOptions),
  });

  const onSubmit = handleSubmit(({cache, lookupType, ...values}) => {
    onCheck(values, lookupType as AvailabilityLookupType, cache);
  });

  useEffect(() => {
    // If the form was pre-filled using values from the form intent state, we'll trigger a submit immediately
    onSubmit();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <form onSubmit={onSubmit}>
    <AddressOrLineIdInputSlice
      errors={errors}
      control={control}
      isUsingLineId={isUsingLineId}
      setIsUsingLineId={setIsUsingLineId}
    />
    <div style={{
      margin:     '1rem 0 0',
      display:    'flex',
      alignItems: 'center',
      // justifyContent: 'center',
    }}>
      Lookup type:
      <Controller
        name={'lookupType'}
        control={control}
        render={props => <Select
          {...props}
          style={{marginLeft: '1rem', flex: 1}}
        >
          <Option value={'FetchPassiveConnection'}>FetchPassiveConnection</Option>
          <Option value={'FetchOperatorInfoPassiveConnection'}>FetchOperatorInfoPassiveConnection</Option>
        </Select>}
      />
    </div>
    <div style={{display: 'flex', marginTop: '1rem'}}>
      <span style={{
        display:     'flex',
        alignItems:  'center',
        marginRight: 'auto',
      }}>
        <Controller
          name={'cache'}
          control={control}
          render={({value, onChange}) => <Checkbox
            style={{marginRight: '.5rem'}}
            checked={!!value}
            onChange={e => onChange(!!e.target?.checked)}
          >Use cache</Checkbox>}
        />
      </span>
      <Button
        htmlType={"submit"}
        loading={loading}
        disabled={loading}
        type={"primary"}
        style={{marginLeft: 'auto'}}
      >Check</Button>
    </div>
  </form>;
}
