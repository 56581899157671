import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {joiResolver} from '@hookform/resolvers/joi';
import {Button} from "antd";

import {
  constructionOrderSchemaWithAddress,
  constructionOrderSchemaWithLineId
} from "../../../schemas/constructionOrder";
import {defaultValidationOptions} from "../validation";
import {ControlledModalFormFooter} from "../ControlledModalFormFooter";
import {isDev} from "../../data/env";
import {setAllValues} from "../../../util/setAllValues";
import {mockConstructionOrder} from "../../data/factories";
import {useSubmitOrderForm} from "../../../hooks/useSubmitOrderForm";
import {FormSection} from "../FormSection";
import {equipmentEndpointDefaults, EquipmentEndpointInput} from "./controls/EquipmentEndpointInput";
import {CreateOrderFormProps} from "./common";
import {RadioGroupInputSlice} from "./slices/RadioGroupInputSlice";
import {contactDefaults, ContactInput} from "./controls/ContactInput";
import {addressDefaults} from "./controls/AddressInput";
import {AddressOrLineIdInputSlice} from "./slices/AddressOrLineIdInputSlice";
import {TaskHandlingInputSlice} from "./slices/TaskHandlingInputSlice";
import {WishDateInputSlice} from "./slices/WishDateInputSlice";

export const CreateConstructionOrderForm: React.FC<CreateOrderFormProps> = ({hide}) => {

  const [isUsingLineId, setIsUsingLineId] = useState<boolean>(false);

  const schema = isUsingLineId ? constructionOrderSchemaWithLineId : constructionOrderSchemaWithAddress;

  const {errors, formState, handleSubmit, control, setValue} = useForm({
    defaultValues: {
      fiberCode:               "FIBER_A",
      activeEquipmentEndpoint: equipmentEndpointDefaults,
      contact:                 contactDefaults,
      address:                 addressDefaults,
      lineId:                  1,
      wishDate:                null,
      handle:                  false,
    },
    resolver:      joiResolver(schema, defaultValidationOptions),
  });

  const onSubmit = handleSubmit(
    useSubmitOrderForm('ConstructionOrder', 'construction')
  );

  return <form onSubmit={onSubmit}>
    <FormSection>
      <RadioGroupInputSlice control={control} errors={errors} id={'fiberCode'} options={['FIBER_A', 'FIBER_B']}/>
    </FormSection>
    <FormSection>
      <Controller
        control={control}
        name={'activeEquipmentEndpoint'}
        render={props => <EquipmentEndpointInput errors={errors} {...props}/>}
      />
    </FormSection>
    <FormSection>
      <AddressOrLineIdInputSlice {...{errors, control, isUsingLineId, setIsUsingLineId}}/>
    </FormSection>
    <FormSection>
      <Controller
        control={control}
        name={'contact'}
        render={props => <ContactInput errors={errors} {...props}/>}
      />
    </FormSection>
    <FormSection>
      <WishDateInputSlice {...{errors, control}}/>
    </FormSection>
    <FormSection>
      <TaskHandlingInputSlice control={control} errors={errors}/>
    </FormSection>
    <ControlledModalFormFooter hide={hide} submitting={formState.isSubmitting}>
      {isDev() ? <Button onClick={() => {
        const order = mockConstructionOrder();
        setAllValues(setValue, order);
      }}>Populate with dummy data</Button> : null}
    </ControlledModalFormFooter>
  </form>;
};
