import React, {ComponentProps} from "react";
import {DatePicker} from "antd";
import moment from "moment";

/**
 * Wrapper around the antdesign `<DatePicker/>` component that converts to and from the
 * `Moment` instance used by `<DatePicker/>` to accept a string as the `value` property
 * and the first parameter of the `onChange` handler.
 */
export const FormattedDatePicker: React.FC<Partial<ComponentProps<typeof DatePicker>> & {
  onChange: (value: string | null) => void,
  value: string | null,
  format?: string,
}> = React.forwardRef(({format = 'YYYY-MM-DD', onChange, value, ...props}, ref) => {
  return <DatePicker
    pickerRef={ref as any}
    {...props as ComponentProps<typeof DatePicker>}
    onChange={moment => {
      onChange(moment?.format(format) || null);
    }}
    value={value ? moment(value, format) : null}
  />;
});
