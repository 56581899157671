import React from "react";

/**
 * Displays a list of validation errors or info. Either an array of field names, or an object mapping
 * each field name to their respective error value or description.
 */
export const ValidationFeedbackList: React.FC<{
  title: string;
  hint?: string;
  items: string[] | Record<string, string>;
  type?: 'error' | 'info';
}> = React.memo(({title, hint, items, type = 'error'}) => {
  const keys = Object.keys(items);
  if (!keys.length) return null;
  return <div className={`validation-${type}-list`}>
    <h4>{title}</h4>
    {hint ? <p className={'hint'}>{hint}</p> : null}
    <ul>
      {keys.map(key => {
        if (/^\d+$/.test(key)) {
          return <li key={key}><code>{(items as any)[key]}</code></li>;
        } else {
          return <li key={key}><code>{key}</code>: {(items as any)[key]}</li>;
        }
      })}
    </ul>
  </div>;
});
