import {push} from "connected-react-router";
import {useDispatch} from "react-redux";

/**
 * Returns a function that can be used as the `onRow` prop of an Antd `<Table/>` which will
 * result in the navigation to some URL or path depending on the row, when clicked.
 */
export function useOnRowNavigate<TRecord>(getPathByRow: (row: TRecord) => string) {
  const dispatch = useDispatch();
  return (row: TRecord) => {
    return {
      onClick: () => dispatch(push(getPathByRow(row))),
    };
  };
}
