import {
  faCheck,
  faHourglassHalf,
  faPaperPlane,
  faSync,
  faTimes,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {OrderEventType} from "../../shared-types/orderDefinitions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const EventTypeLabel: React.FC<{ eventType: OrderEventType }> = ({eventType}) => {
  const map: Record<OrderEventType, string> = {
    OUT_REQUEST:             'Order submitted',
    REQUEST_ACK_ACCEPT:      'Order accepted',
    REQUEST_ACK_DENY:        'Order denied',
    REQUEST_ACK_HOLD:        'Order pending',
    OUT_CANCEL:              'Cancellation request sent',
    CANCEL_ACK_ACCEPT:       'Cancellation request accepted',
    CANCEL_ACK_DENY:         'Cancellation request denied',
    OUT_RESCHEDULING:        'Request for rescheduling sent',
    RESCHEDULING_ACK_ACCEPT: 'Request for rescheduling accepted',
    RESCHEDULING_ACK_DENY:   'Request for rescheduling denied',
    HOLD_DELAY:              'Order delayed',
    HOLD_UPDATE:             'Order updated',
    HOLD_DENY:               'Order aborted',
    HANDOVER:                'Hand-over received',
  };
  let icon: IconDefinition | null           = null;
  if (eventType.includes('ACCEPT')) {
    icon = faCheck;
  }
  if (eventType.includes('DENY')) {
    icon = faTimes;
  }
  if (eventType.includes('_HOLD') || eventType.includes('HOLD_DELAY')) {
    icon = faHourglassHalf;
  }
  if (eventType.includes('OUT_')) {
    icon = faPaperPlane;
  }
  if (eventType.includes('HANDOVER')) {
    icon = faCheck;
  }
  if (eventType.includes('UPDATE')) {
    icon = faSync;
  }
  return <span>
    {icon ? <FontAwesomeIcon
      icon={icon}
      style={{marginRight: '.4rem', marginLeft: '-.1rem', minWidth: 20, transform: 'scale(.8)'}}
    /> : null}
    {map[eventType]}
    {' '}&nbsp;<code style={{fontSize: '80%', opacity: .7}}>({eventType})</code>
  </span>;
};
