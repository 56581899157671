import {ApiTaskSetEntity} from "../components/taskhandling/types";
import {bpCodes} from "../shared-types/compiled/bpCodes";
import {BpCodeType} from "../shared-types/compiled/bpTypes";

/**
 * Determine whether the task set is in a state where it can be accepted.
 * @param taskSet
 */
export function isAcceptable(taskSet: ApiTaskSetEntity): boolean {
  return taskSet.status === "NEW" || taskSet.status === "CHANGED";
}

/**
 * Determine whether the task set is in a state where it can be "planned".
 * @param taskSet
 */
export function isPlannable(taskSet: ApiTaskSetEntity): boolean {
  return taskSet.status === "ACCEPTED";
}

export function isHoldable(taskSet: ApiTaskSetEntity): boolean {
  return taskSet.status === "PLANNED" || taskSet.status === "ACCEPTED";
}

export function isClosable(taskSet: ApiTaskSetEntity): { closable: boolean, pendingTasks: number } {
  const pendingTasks = taskSet.tasks?.filter(t => !["CLOSED", "SOLVED", "CANCELLED"].includes(t.status)).length || 0;
  return {
    closable: taskSet.status === 'PLANNED' || taskSet.status === 'ACCEPTED',
    pendingTasks,
  };
}

export function isCancelAckalable(taskSet: ApiTaskSetEntity): boolean {
  return taskSet.status === 'TO_BE_CANCELLED';
}

export function isResumeable(taskSet: ApiTaskSetEntity): boolean {
  return taskSet.status === 'HOLD';
}

/**
 * Retrieve BP codes applicable to the given task entity and/or code type (e.g. hold, resume, etc.)
 */
export function getApplicableBpCodes(taskTypes: string[] | null, codeType?: BpCodeType) {
  let codes = bpCodes;
  if (taskTypes) {
    // Only include the codes that are applicable to at least one of the given task types
    codes = codes.filter(code => {
      return taskTypes.some(taskType => code.eligibleTypes.includes(taskType as any));
    });
  }
  if (codeType) {
    codes = codes.filter(({type}) => type === codeType);
  }
  return codes;
}
