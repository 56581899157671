import React from "react";
import {Layout} from "antd";

export const AppFooter: React.FC = () => {
  return <Layout.Footer className={"app-footer"}>
    <span style={{fontFamily: 'monospace', marginLeft: 'auto', fontSize: '92%'}}>
      <span style={{opacity: '.6'}}>l2-connect / client @ </span>{process.env.REACT_APP_VERSION}
    </span>
  </Layout.Footer>;
};
