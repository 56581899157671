import React, {ComponentProps} from "react";
import {Controller} from "react-hook-form";
import {Input} from "antd";

export const NoteInput: React.FC<{
  name?: string;
  control: ComponentProps<typeof Controller>['control'],
}> = ({name = 'note', control}) => {
  return <Controller
    name={'note'}
    control={control}
    render={props => <Input
      {...props}
      style={{marginBottom: '.75rem'}}
      placeholder={'Add a note (optional)'}
    />}
  />;
};
