import React, {CSSProperties} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";

/**
 * Used to indicate whether an event or message was processed or sent successfully.
 * A `null` or empty error prop indicates success.
 */
export const StatusIndicatorIcon: React.FC<{
  error: string | null,
  style?: CSSProperties,
}> = ({error, style}) => {
  return <FontAwesomeIcon
    icon={error ? faTimes : faCheck}
    className={`status-indicator ${error ? 'status-error' : 'status-success'}`}
    title={error || undefined}
    style={style}
  />;
};
