import React from "react";
import {Breadcrumb, Table} from "antd";
import {NavLink} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";

import {BreadcrumbsBar} from "../parts/BreadcrumbsBar";
import {HeaderBar} from "../parts/HeaderBar";
import {baseOptionsGqlGrid} from "../../constants";
import {QueryResult} from "../QueryResult";
import {ClickableDate} from "../parts/ClickableDate";
import {ApiTaskSetEntity} from "./types";
import {useOnRowNavigate} from "../../util/useOnRowNavigate";
import {TaskSetOverviewSyncButton} from "./TaskSetOverviewSyncButton";

const getTaskSetUrl = (taskSetId: number) => `/task-sets/${taskSetId}`;

export const TaskSetOverview: React.FC<{}> = ({children}) => {

  const onRowNavigate = useOnRowNavigate<ApiTaskSetEntity>(taskSet => getTaskSetUrl(taskSet.id));

    const {loading, error, data} = useQuery(gql`
        query TaskSetOverview {
            taskSets { id uid status taskCount createdAt }
        }
    `, baseOptionsGqlGrid);

  return <>
    <BreadcrumbsBar>
      <Breadcrumb>
        <Breadcrumb.Item><NavLink to={"/task-sets"}>Task handling</NavLink></Breadcrumb.Item>
      </Breadcrumb>
    </BreadcrumbsBar>

    <HeaderBar title={'Task handling'}>
      <TaskSetOverviewSyncButton/>
    </HeaderBar>

    <QueryResult loading={loading} error={error}>
      {() => <Table<ApiTaskSetEntity>
        className={"stretch-grid"}
        rowClassName={"clickable-row"}
        onRow={onRowNavigate}
        loading={loading}
        rowKey={row => row.id}
        size={"middle"}
        dataSource={data?.taskSets}
        columns={[{
          key:       'uid',
          dataIndex: 'uid',
          title:     'UID',
          render:    uid => <code>{uid}</code>,
        }, {
          key:       'status',
          dataIndex: 'status',
          title:     'Status',
        }, {
          key:       'taskCount',
          dataIndex: 'taskCount',
          title:     'Tasks',
        }, {
          key:       'createdAt',
          dataIndex: 'createdAt',
          title:     'Created',
          render:    (date, taskSet) => <ClickableDate path={getTaskSetUrl(taskSet.id)} date={date}/>,
        }]}
      />}
    </QueryResult>
  </>
};
