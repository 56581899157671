import React, {ComponentProps, useCallback, useMemo, useState} from "react";
import {useMutation} from "@apollo/client";
import TextArea from "antd/es/input/TextArea";

import {useDebouncedMutation} from "../../hooks/useDebouncedMutation";
import {SaveState} from "./SaveState";

type Document = Parameters<typeof useMutation>[0];

export const NotesEditor: React.FC<ComponentProps<typeof TextArea>> = ({style, ...props}) => {
  return <TextArea
    style={{
      minHeight: 64,
      ...style,
    }}
    autoSize={true}
    {...props}
  />;
};

export const AutoSyncNotesEditor: React.FC<{
  initialValue: string;
  readOnly?: boolean;
  createMutation: () => Document;
  getMutationVariables: (notes: string) => object;
  getSavedNotesFromMutationResult: (data: any) => string | undefined;
}> = ({initialValue, readOnly, createMutation, getMutationVariables, getSavedNotesFromMutationResult}) => {

  const [notes, setNotes] = useState(initialValue);

  const [update, {data}] = useDebouncedMutation(createMutation, getMutationVariables(notes));

  const savedNotes = useMemo(() => getSavedNotesFromMutationResult(data), [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const saved = savedNotes === notes;

  const onChange = useCallback(e => {
    if (readOnly) return;
    setNotes(e.target.value);
    update();
  }, [readOnly, setNotes, update]);

  if (!notes && readOnly) return null;

  return <div>
    <NotesEditor
      style={readOnly ? {
        border:     'none',
        padding:    0,
        background: 'transparent',
      } : {}}
      value={notes}
      readOnly={readOnly}
      onChange={onChange}
      placeholder={'Notes entered here will be saved automatically.'}
    />
    <SaveState saved={saved}/>
  </div>;
}
