import React from "react";
import {ColumnsType} from "antd/es/table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp} from "@fortawesome/free-solid-svg-icons";
import ReactTimeago from "react-timeago";
import fileSize from "filesize";

import {SoapMessageDetails} from "./SoapMessageDetails";
import {StatusIndicatorIcon} from "../StatusIndicatorIcon";
import {ExpandableGrid} from "../ExpandableGrid";

export type ApiSoapMessageEntity = {
  id: number,
  direction: string,
  soapAction: string,
  requestBytes: number,
  responseBytes: number,
  summary: string | null,
  error: string | null,
  createdAt: string,
};

const UpDown: React.FC<{
  up: number,
  down: number,
  reverseIcons?: boolean,
}> = ({up, down, reverseIcons = false}) => <small>
  <span title={`Request size: ${up} bytes`}>{fileSize(up)}</span>
  <FontAwesomeIcon icon={reverseIcons ? faArrowDown : faArrowUp} style={{margin: '0 .2rem'}}/>
  &nbsp;·&nbsp;
  <FontAwesomeIcon icon={reverseIcons ? faArrowUp : faArrowDown} style={{margin: '0 .2rem'}}/>
  <span title={`Response size: ${up} bytes`}>{fileSize(down)}</span>
</small>;

/**
 * Remove leading and trailing quotes
 */
function trimAction(input: string): string {
  return input.replace(/(^"|"$)/g, "");
}

const soapLogColumns: ColumnsType<ApiSoapMessageEntity> = [{
  key:       'action',
  dataIndex: 'soapAction',
  title:     'Action',
  render:    (action: string | null, message) => {
    const actionShortName = trimAction(action || '').split('/').pop();
    return <div style={{display: 'flex', alignItems: 'center'}}>
      <StatusIndicatorIcon error={message.error} style={{marginLeft: '-.15rem'}}/>
      <div className={`message-classification ${message.direction === 'INCOMING' ? 'incoming' : 'outgoing'}`}>
        <FontAwesomeIcon
          title={message.direction.toLowerCase()}
          icon={message.direction === 'INCOMING' ? faArrowDown : faArrowUp}
          style={{marginRight: '.3rem'}}
        />
        <code style={{wordWrap: "break-word", wordBreak: "break-all"}}>
          <strong>{actionShortName}</strong>
        </code>
      </div>
    </div>;
  },
}, {
  key:       'size',
  dataIndex: 'id',
  title:     'Size',
  render:    (id, row) => <UpDown
    reverseIcons={row.direction === 'INCOMING'}
    up={row.requestBytes}
    down={row.responseBytes}
  />
}, {
  key:       'createdAt',
  dataIndex: 'createdAt',
  title:     'When',
  render:    date => <ReactTimeago date={date}/>,
}];

/**
 * Shows a "history" (log) with the given Soap Messages
 */
export const SoapLog: React.FC<{
  loading?: boolean;
  messages?: ApiSoapMessageEntity[];
  className?: string;
  patchColumns?: (columns: ColumnsType<ApiSoapMessageEntity>) => ColumnsType<ApiSoapMessageEntity>;
}> = ({loading, messages, className = "stretch-grid", patchColumns}) => {
  const columns = patchColumns?.(soapLogColumns) || soapLogColumns;
  return <ExpandableGrid<ApiSoapMessageEntity>
    rowKey={row => row.id}
    className={className}
    loading={loading}
    dataSource={messages}
    expandedRowRender={row => <SoapMessageDetails id={row.id}/>}
    columns={columns}
    rowClassName={row => `clickable-row soap-log-row ${row.error ? 'error grid-row-error' : ''}`}
  />
};
