import joi, {SchemaMap} from "joi";

import {dateSchemaRequired} from "./common";
import {BpCodeType} from "../shared-types/compiled/bpTypes";
import {getApplicableBpCodes} from "../util/taskStatusConditionals";

/**
 * Get the base schema for a taskset state mutation form (such as "hold taskset")
 */
export const taskSetStateSchema = (type: BpCodeType, additionalFields?: SchemaMap) => {
  const codes = getApplicableBpCodes(null, type).map(bpCode => bpCode.code.toString());
  return joi.object({
    ...additionalFields,
    code: joi.string().allow(...codes),
    note: joi.string().allow(''),
  });
}

export const taskSetPlanSchema = taskSetStateSchema("(Re)Plan code", {
  planDate: dateSchemaRequired,
});
