import React from "react";
import {CompositeInputProps, subFieldProps} from "./compositeFields";
import {Input, InputNumber} from "antd";
import {InputRow} from "../../InputRow";

export type AddressValue = {
  zipCode: string;
  houseNumber: number;
  houseNumberExtension: string;
  room: string;
}

export const addressDefaults: AddressValue = {
  zipCode:              "",
  houseNumber:          1,
  houseNumberExtension: "",
  room:                 "",
}

export const AddressInput: React.FC<CompositeInputProps<AddressValue>> = React.forwardRef((
  {value, onChange, errors, name}, ref
) => {
  // This is only wrapped in forwardRef because the component needs to accept
  // a ref in order to be compatible with the `react-hook-form` `<Controller/>`.

  const fieldProps = subFieldProps(value, onChange);

  return <InputRow errors={errors?.[name] || {}} control={false} columns={[{
    id:     'zipCode', label: 'Zip Code', width: '40%',
    render: () => <Input placeholder={'1234AB'} {...fieldProps("zipCode")}/>,
  }, {
    id:     'houseNumber', label: 'Number', width: '20%',
    render: () => <InputNumber placeholder={'1'} {...fieldProps("houseNumber")}/>,
  }, {
    id:     'houseNumberExtension', label: 'Extension', width: '20%', optional: true,
    render: () => <Input placeholder={''} {...fieldProps("houseNumberExtension")}/>,
  }, {
    id:     'room', label: 'Room', width: '20%', optional: true,
    render: () => <Input placeholder={''} {...fieldProps("room")}/>,
  }]}/>;
});
