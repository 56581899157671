import {MutationResult, useMutation} from "@apollo/client";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";

type Document = Parameters<typeof useMutation>[0];

export function useDebouncedMutation(createMutation: () => Document, variables: any, interval: number = 500, mutationMemoDeps: any[] = []): [() => void, MutationResult] {

  const timeout             = useRef<number>(-1);
  const [queued, setQueued] = useState(false);
  const mutation            = useMemo(createMutation, mutationMemoDeps); // eslint-disable-line react-hooks/exhaustive-deps
  const [run, state]        = useMutation(mutation, {variables});

  const loading = state.loading;

  const mutate = useCallback(() => {
    if (loading) setQueued(true);
    else {
      setQueued(false);
      run();
    }
  }, [loading, setQueued, run]);

  useEffect(() => {
    if (!loading && queued) {
      mutate();
    }
  }, [loading, queued]); // eslint-disable-line react-hooks/exhaustive-deps

  const trigger = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = (setTimeout(mutate, interval) as any);
  }, [mutate, interval]);

  return [trigger, state];
}
