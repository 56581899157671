import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

interface FormIntentState {
  form: string | null,
  values: object | null,
}

const initialState: FormIntentState = {
  form:   null,
  values: null,
};

export const formIntentSlice = createSlice({
  name:     'formIntent',
  initialState,
  reducers: {
    setFormIntent(state, action: PayloadAction<FormIntentState>) {
      return {...action.payload};
    },
    clearFormIntent() {
      return {...initialState};
    },
  },
});

export const setFormIntent   = formIntentSlice.actions.setFormIntent;
export const clearFormIntent = formIntentSlice.actions.clearFormIntent;

/**
 * Return a reducer that returns the form intent state values only if the
 * current form identifier in the state matches the given form
 */
export const selectFormIntent = (form: string) => (state: RootState) => {
  return state.formIntent.form === form ? state.formIntent.values : null
}

export const formIntentReducer = formIntentSlice.reducer;
