import {gql, SubscribeToMoreOptions} from "@apollo/client";

import {eventFields, soapMessageFields} from "../data/fragments";
import {uniqBy} from "lodash";

export const orderUpdated = (orderId: number): SubscribeToMoreOptions => ({
    document: gql`
        subscription accessOrderUpdated ($orderId: Int!) {
            accessOrderUpdated(id: $orderId) {
                id

                orderStatus externalOrderUid serviceUid
                blockingNote blockingCode blockingUpdated

                newEvent {...EventFields}
                newSoapMessage {...SoapMessageFields}
            }
        }${eventFields}${soapMessageFields}
    `,
  variables:  {orderId},
  updateQuery({accessOrder}, ...args: any[]) {

    const accessOrderUpdated = args[0]?.subscriptionData?.data?.accessOrderUpdated;
    // TODO this should all be typed using the nest.js gql schema to TS definitions tooling

    if (!accessOrderUpdated) {
      return {accessOrder};
    }

    if (accessOrderUpdated.newEvent) {
      return {
        accessOrder: {
          ...accessOrder,
          events: uniqBy([...(accessOrder?.events || []), accessOrderUpdated.newEvent], ({id}) => id),
        },
      };
    }

    if (accessOrderUpdated.newSoapMessage) {
      return {
        accessOrder: {
          ...accessOrder,
          soapMessages: uniqBy([...(accessOrder?.soapMessages || []), accessOrderUpdated.newSoapMessage], ({id}) => id),
        },
      };
    }

    const {__typename, ...restUpdated} = accessOrderUpdated; // Breaks when assigning __typename to the existing object.

    accessOrder = Object.assign({}, accessOrder, restUpdated);

    return {accessOrder};
  },
});
