import {ApolloClient, ApolloLink, createHttpLink, InMemoryCache, split} from '@apollo/client';
import {setContext} from "@apollo/client/link/context";
import {WebSocketLink} from "@apollo/client/link/ws";

import {store} from "./app/store";
import {getMainDefinition} from "@apollo/client/utilities";

const getTokenGlobally = (): string | null => store.getState().auth.token;

const authHeaderFromStore = () => setContext((_, context: { headers: any }) => {
  const token = getTokenGlobally();
  return {
    headers: {
      ...context?.headers,
      ...token ? {
        authorization: `Bearer ${token}`,
      } : {},
    },
  };
});

const httpLink: ApolloLink = ApolloLink.from([
  authHeaderFromStore(),
  createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  }),
]);

const wsLink = new WebSocketLink({
  uri:     (() => {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    return process.env.REACT_APP_GRAPHQL_ENDPOINT!.replace(/^https?:/, protocol)
  })(),
  options: {
    lazy:             true,
    reconnect:        true,
    connectionParams: () => ({
      headers: {
        authorization: `Bearer ${getTokenGlobally()}`,
      },
    }),
  },
});

// Automatically determine the right link based on whether it is a subscription
const splitLink = split(
  ({query}: { query: any }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

export const apolloClient = new ApolloClient({
  link:  splitLink,
  cache: new InMemoryCache(),
});
