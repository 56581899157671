import React, {ReactNode} from "react";
import {Controller, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {gql, useMutation} from "@apollo/client";

import {ControlledSelect} from "./ControlledSelect";
import {defaultValidationOptions} from "./validation";
import {rescheduleOrderSchema} from "../../schemas/orderReschedule";
import {ErrorsByFields} from "./ValidationErrors";
import {NoteInput} from "./NoteInput";
import {ErrorCodeExplanation} from "../parts/ErrorCodeExplanation";
import {useHandleOrderMutationResult} from "../../hooks/useHandleOrderMutationResult";
import {FormattedDatePicker} from "./order/controls/FormattedDatePicker";
import {changeCodes} from "../../schemas/common";

export const RescheduleOrderForm: React.FC<{
  orderId: number,
  hide?: () => void,
  renderFooter: (submitting: boolean) => ReactNode,
}> = ({orderId, hide, renderFooter}) => {

    const [run] = useMutation(gql`mutation rescheduleOrder($parameters: RescheduleOrder!) {
        rescheduleOrder(data: $parameters) { id }
    }`, {
      refetchQueries: ['orderOverview', 'orderDetails'],
    });

  const {formState, errors, handleSubmit, control} = useForm({
    defaultValues: {
      wishDate: '',
      code:     null,
      note:     '',
    },
    resolver:      joiResolver(rescheduleOrderSchema, defaultValidationOptions),
  });

  const handleResult = useHandleOrderMutationResult(orderId);

  const onSubmit = handleSubmit(async values => {
    const result = await run({variables: {parameters: {...values, id: orderId}}});
    handleResult(result);
  });

  return <form onSubmit={onSubmit}>
    <Controller
      name={'wishDate'}
      control={control}
      render={props => <FormattedDatePicker
        {...props}
        placeholder={'Wish date'}
        style={{width: '100%', marginBottom: '.75rem'}}
      />}
    />
    <ControlledSelect
      name={'code'}
      control={control}
      options={Object.keys(changeCodes)}
      renderOption={code => <>
        <strong>{code}</strong> · <ErrorCodeExplanation>{(changeCodes as any)[code]}</ErrorCodeExplanation>
      </>}
      placeholder={"Reason for rescheduling"}
    />
    <NoteInput control={control}/>
    <ErrorsByFields fields={'all'} errors={errors}/>
    {renderFooter(formState.isSubmitting)}
  </form>
};
