import React from "react";
import {StylableInputSliceProps} from "./common";
import {ServiceUidInput} from "../controls/ServiceUidInput";
import {InputRow} from "../../InputRow";

export const ServiceUidSlice: React.FC<StylableInputSliceProps> = ({errors, control}) => {
  return <InputRow errors={errors} control={control} columns={[{
    id:     'serviceUid',
    render: props => <ServiceUidInput renderProps={props}/>,
  }]}/>;
};
