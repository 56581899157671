import React, {useLayoutEffect} from "react";
import {Breadcrumb} from "antd";
import {faExchangeAlt, faHistory} from "@fortawesome/free-solid-svg-icons";
import {NavLink, useParams} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import ReactTimeago from "react-timeago";

import {HeaderWithIcon} from "../parts/HeaderWithIcon";
import {OrderEventType} from "../../shared-types/orderDefinitions";
import {EventTypeLabel} from "../parts/EventTypeLabel";
import {OrderEventDetails} from "../parts/orders/OrderEventDetails";
import {sortByCreatedAt} from "../../util/sort";
import {QueryResult} from "../QueryResult";
import {baseDetails, eventFields, soapMessageFields} from "../data/fragments";
import {SoapLog} from "../parts/SoapLog";
import {orderUpdated} from "../subscriptions/orderDetails";
import {OrderDetailsCard} from "../parts/orders/OrderDetailsCard";
import {BreadcrumbsBar} from "../parts/BreadcrumbsBar";
import {ExpandableGrid} from "../ExpandableGrid";
import {OrderActions} from "./OrderActions";

export type ApiOrderEventEntity = {
  id: number,
  type: OrderEventType,
  origin: string,
  createdAt: string,
};

const OrderEvents: React.FC<{

  loading?: boolean,
  events?: ApiOrderEventEntity[],
  className?: string,

}> = ({loading, events, className = "stretch-grid"}) => {

  return <ExpandableGrid<ApiOrderEventEntity>
    rowKey={row => row.id}
    className={className}
    loading={loading}
    dataSource={events}
    expandedRowRender={row => <OrderEventDetails id={row.id}/>}
    columns={[{
      key:       'type',
      dataIndex: 'type',
      title:     'Description',
      render:    type => <EventTypeLabel eventType={type}/>
    }, {
      key:       'origin',
      dataIndex: 'origin',
      title:     'Origin',
    }, {
      key:       'createdAt',
      dataIndex: 'createdAt',
      title:     'When',
      render:    date => <ReactTimeago date={date}/>,
    }]}
  />;
};

export const OrderDetails: React.FC<{ orderId: number }> = ({orderId}) => {

    const {loading, error, data, subscribeToMore} = useQuery(gql`query orderDetails ($orderId: Int!) {
        accessOrder(id: $orderId) {
            ...BaseDetails
            events {...EventFields}
            soapMessages {...SoapMessageFields}
        }
    }${baseDetails}${eventFields}${soapMessageFields}`, {variables: {orderId}});

  useLayoutEffect(() => {
    // This breaks in `useEffect` see https://github.com/apollographql/apollo-client/issues/6437
    subscribeToMore?.(orderUpdated(orderId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    <BreadcrumbsBar>
      <Breadcrumb>
        <Breadcrumb.Item><NavLink to={"/orders"}>Orders</NavLink></Breadcrumb.Item>
        <Breadcrumb.Item>{orderId}</Breadcrumb.Item>
      </Breadcrumb>
    </BreadcrumbsBar>
    <OrderDetailsCard orderId={orderId} order={data?.accessOrder}>
      {data ? <OrderActions order={data.accessOrder}/> : null}
    </OrderDetailsCard>
    <QueryResult
      loading={loading}
      error={error}
    >{() => <div style={{marginTop: '2rem'}}>
      <HeaderWithIcon icon={faHistory}>History</HeaderWithIcon>
      <OrderEvents
        loading={loading}
        className={"stretch-grid"}
        events={sortByCreatedAt(data.accessOrder.events)}
      />
      <HeaderWithIcon icon={faExchangeAlt}>Reggefiber interaction</HeaderWithIcon>
      <SoapLog
        loading={loading}
        className={"stretch-grid"}
        messages={sortByCreatedAt(data.accessOrder.soapMessages)}
      />
    </div>}</QueryResult>
  </>;
};

export const OrderDetailsPageRoute: React.FC = () => {
  const {orderId} = useParams<{ orderId: string }>();
  return <OrderDetails orderId={parseInt(orderId)}/>;
};
