import {Button} from "antd";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faTimes} from "@fortawesome/free-solid-svg-icons";

import {ApiOrderEntity} from "./types";
import {CancelOrderModal} from "../modals/CancelOrderModal";
import {isCancellable, isChangeable} from "../../util/orderStatusConditionals";
import {RescheduleOrderModal} from "../modals/RescheduleOrderModal";

export const OrderActions: React.FC<{ order: ApiOrderEntity }> = ({order}) => {

  const cancellable = isCancellable(order);
  const changeable  = isChangeable(order);

  const [currentModal, setCurrentModal] = useState<null | 'cancel' | 'change'>(null);

  const hide = () => setCurrentModal(null);

  if (!cancellable && !changeable) return null;

  return <div style={{margin: '1.5em 0'}}>
    {changeable ? <>
      <Button
        style={{marginRight: '.5rem'}}
        onClick={() => setCurrentModal('change')}
        type={changeable === "required" ? "primary" : undefined}
      >
        <FontAwesomeIcon style={{marginRight: '.4rem'}} icon={faCalendarAlt}/>
        Reschedule
      </Button>
      <RescheduleOrderModal orderId={order.id} hide={hide} visible={currentModal === "change"}/>
    </> : null}
    {cancellable ? <>
      <Button danger onClick={() => setCurrentModal('cancel')}>
        <FontAwesomeIcon style={{marginRight: '.4rem'}} icon={faTimes}/>
        Cancel
      </Button>
      <CancelOrderModal orderId={order.id} hide={hide} visible={currentModal === "cancel"}/>
    </> : null}
  </div>;
};
