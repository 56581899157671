import React, {useState} from "react";
import {OrderType} from "../../shared-types/orderDefinitions";
import {Button, Dropdown, Menu} from "antd";
import {modals} from "../modals/CreateOrderModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

/**
 * The dropdown button for triggering modals holding an order creation form.
 * @constructor
 */
export const ShowOrderModalButton: React.FC = () => {

  const [visibleType, setVisibleType] = useState<OrderType | null>(null);

  const menu = <Menu>
    <Menu.Item onClick={() => setVisibleType("construction")}>
      Construction order
    </Menu.Item>
    <Menu.Item onClick={() => setVisibleType("deconstruction")}>
      Deconstruction order
    </Menu.Item>
    <Menu.Item onClick={() => setVisibleType("operatorMigration")}>
      Operator Migration order
    </Menu.Item>
    <Menu.Item disabled onClick={() => setVisibleType("networkTerminator")}>
      Network Terminator order
    </Menu.Item>
    <Menu.Item onClick={() => setVisibleType("operationalAndMaintenanceSla")}>
      SLA order
    </Menu.Item>
    <Menu.Item onClick={() => setVisibleType("portMigration")}>
      Port Migration order
    </Menu.Item>
  </Menu>;

  return <>
    {Object.keys(modals).map(type => {
      const Component = (modals as any)[type];
      return <Component key={type} visible={type === visibleType} hide={() => setVisibleType(null)}/>;
    })}
    <Dropdown overlay={menu}>
      <Button type={"primary"} style={{display: 'flex', alignItems: 'center'}}>
        <FontAwesomeIcon style={{marginRight: '.4rem'}} icon={faPlus}/> Create new order
      </Button>
    </Dropdown>
  </>;
};
