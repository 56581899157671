import React from "react";
import {gql, useQuery} from "@apollo/client";

import {QueryResult} from "../QueryResult";
import {ApiTaskEntity} from "./types";
import {SubTaskDetails} from "./SubTaskDetails";
import {sortByCreatedAt} from "../../util/sort";

/**
 * Show all subtasks with their related actions under a given "parent" taskSet ID.
 * @param taskSetId
 * @constructor
 */
export const SubTaskList: React.FC<{
  taskSetId: number;
}> = ({taskSetId}) => {

    const {loading, error, data} = useQuery(gql`query SubTaskList($taskSetId: Int!) {
        taskSet(id: $taskSetId) {
            tasks {
                id uid type status projectedAttributeErrors
                actualAttributeErrors pendingAttributes attributes createdAt updatedAt
            }
        }
    }`, {
      variables: {taskSetId},
    });

  return <QueryResult loading={loading} error={error}>
    {() => <>
      <div style={{
        margin:       '1.5rem -35px 2.5rem',
        borderBottom: '1px solid rgba(0,0,0,0.05)',
      }}>
        {sortByCreatedAt<ApiTaskEntity>((data?.taskSet.tasks || [])).map(task => {
          return <div key={task.uid} style={{
            padding:   '10px 35px',
            margin:    '5px 0',
            borderTop: '1px solid rgba(0,0,0,0.05)',
          }}>
            <SubTaskDetails task={task}/>
          </div>
        })}
      </div>
    </>}
  </QueryResult>;
};
