import React from "react";
import {Layout} from "antd";

import {AppHeader} from "./layout/AppHeader";
import {AppFooter} from "./layout/AppFooter";
import {DashboardRouter} from "./DashboardRouter";

export const Dashboard: React.FC = () => {
  return <div className={"app-layout-wrapper"}>
    <Layout className={"app-layout"}>
      <AppHeader/>
      <Layout.Content className={"app-content"}>
        <DashboardRouter/>
      </Layout.Content>
      <AppFooter/>
    </Layout>
  </div>;
}
