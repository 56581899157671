import React from "react";
import {Input, InputNumber} from "antd";

import {InputRow} from "../../InputRow";
import {CompositeInputProps, subFieldProps} from "./compositeFields";

export type EquipmentEndpointValue = {
  popName: string;
  row: number;
  frame: number;
  block: string;
  module: string;
  port: string;
};

export const equipmentEndpointDefaults: EquipmentEndpointValue = {
  popName: "",
  row:     1,
  frame:   1,
  block:   "",
  module:  "",
  port:    "",
};

/**
 * Acts as a single input component with `value` and `onChange` props, the value being
 * an object/structure containing all fields defining an equipment endpoint (port)
 */
export const EquipmentEndpointInput: React.FC<CompositeInputProps<EquipmentEndpointValue>> = React.forwardRef((
  {value, onChange, errors, name}, ref
) => {
  // This is only wrapped in forwardRef because the component needs to accept
  // a ref in order to be compatible with the `react-hook-form` `<Controller/>`.

  const fieldProps = subFieldProps(value, onChange);

  return <div>
    <InputRow errors={errors?.[name] || {}} control={false} columns={[{
      id:     'popName',
      label:  'POP',
      render: () => <Input placeholder={'POP'} {...fieldProps("popName")}/>,
    }, {
      id:     'row',
      render: () => <InputNumber style={{width: '100%'}} placeholder={'row'} {...fieldProps("row")}/>,
    }, {
      id:     'frame',
      render: () => <InputNumber style={{width: '100%'}} placeholder={'Frame'} {...fieldProps("frame")}/>,
    }]}/>

    <InputRow errors={errors?.[name] || {}} control={false} columns={[{
      id:     'block',
      render: () => <Input maxLength={1} placeholder={'Block'} {...fieldProps("block")}/>,
    }, {
      id:     'module',
      render: () => <Input placeholder={'Module'} {...fieldProps("module")}/>,
    }, {
      id:     'port',
      render: () => <Input placeholder={'Port'} {...fieldProps("port")}/>,
    }]}/>
  </div>;
});
