import React, {CSSProperties} from "react";
import {Alert} from "antd";

import {isValid, SchemaValidationResult} from "../../../shared-types/compiled/bpAttributeRoutines";
import {ValidationFeedbackList} from "./ValidationFeedbackList";

/**
 * Displays in a human readable format the full results from a schema validation call.
 */
export const SchemaValidationResultPresenter: React.FC<{
  style?: CSSProperties;
  result: SchemaValidationResult;
}> = React.memo(({result, style}) => {
  const valid = isValid(result);
  if (valid) {
    return <Alert
      showIcon={true}
      type={"success"}
      style={style}
      message={'Attributes valid.'}
    />;
  } else {
    return <div style={style} className={'schema-validation-result'}>
      <ValidationFeedbackList
        title={'Unknown field(s)'}
        hint={'These attributes are unknown and therefore not allowed:'}
        items={Object.keys(result.illegalValues)}
      />
      <ValidationFeedbackList
        title={'Illegal mutation(s)'}
        hint={'These attributes cannot be updated:'}
        items={result.illegalMutations}
      />
      <ValidationFeedbackList
        title={'Missing field(s)'}
        hint={'These attributes are required, but not provided:'}
        items={result.missing}
      />
      <ValidationFeedbackList
        title={'Optional field(s)'}
        hint={'These attributes are not required, but you may choose to enter them.'}
        items={result.optional}
      />
      <ValidationFeedbackList title={'Errors'} items={result.errors}/>
    </div>;
  }
});
