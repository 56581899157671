import React from "react";

import {LogoBar} from "../LogoBar";
import {Menu} from "./Menu";

export const AppHeader: React.FC = () => {
  return <div className={"app-header"}>
    <LogoBar hideBrandNameOnMobile={true}/>
    <Menu/>
  </div>;
};
