import {FetchResult} from "@apollo/client";
import {push} from "connected-react-router";
import {useDispatch} from "react-redux";
import {notifyErrorGeneric} from "../util/notifications";

/**
 * Return a callback that will handle the result of an order-related mutation.
 * On success, redirect the user to the newly created order. On error, display a notification.
 * @param orderId Either the order ID as a number or a callback that extracts the order ID from
 * the resulting data of the mutation.
 */
export function useHandleOrderMutationResult<TData = {
  [key: string]: any;
}>(orderId: number | ((resultData: TData) => number)) {

  const dispatch = useDispatch();

  return (result: FetchResult<TData>) => {

    if (result.errors) {
      notifyErrorGeneric(result.errors[0].message);
    } else if (result.data) {
      // Redirect to the newly created order.
      const id = typeof orderId === "number" ? orderId : orderId(result.data);
      dispatch(push(`/orders/${id}`));
    }
  };
}
