import {notification} from "antd";

/**
 * Display "generic" error notification with the given message. A more context-specific error notification is
 * preferable but this might be used as a placeholder or a way to handle uncommon exceptions.
 * @param message
 */
export function notifyErrorGeneric(message: string): void {
  notification.error({
    message:     'An error occurred.',
    description: message,
  });
}
