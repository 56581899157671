import React from "react";
import {Alert, Modal} from "antd";
import {ModalStateProps} from "./common";
import {CancelOrderForm} from "../forms/CancelOrderForm";
import {ControlledModalFormFooter} from "../forms/ControlledModalFormFooter";

export const CancelOrderModal: React.FC<ModalStateProps & { orderId: number }> = ({orderId, visible, hide}) => {
  return <Modal
    title={'Cancel order'}
    visible={visible}
    footer={null}
    closable={false}
  >
    <CancelOrderForm
      orderId={orderId}
      hide={hide}
      renderFooter={submitting => <>
        <Alert
          style={{marginBottom: '.75rem'}}
          message={<strong>Cancelling an order</strong>}
          description={<>A cancellation request will be issued through the Reggefiber interface. The network
            administrator will approve/deny asynchronously - the order status will be set to <code>CANCELLATION_PENDING</code>
            {' '}in the meantime.</>}
          showIcon={true}
        />
        <ControlledModalFormFooter
          cancelText={'Back'}
          hide={hide}
          submitting={submitting}
        />
      </>}
    />
  </Modal>;
};
