import {ApiOrderEntity} from "../components/orders/types";

export function isCancellable(order: ApiOrderEntity): boolean {
  return [
    'PENDING',
    'ACTIVE',
    'RESCHEDULING_REQUIRED',
  ].includes(order.orderStatus);
}

export function isChangeable(order: ApiOrderEntity): false | 'possible' | 'required' {
  if (order.orderStatus === 'ACTIVE') return 'possible';
  if (order.orderStatus === "RESCHEDULING_REQUIRED") return 'required';
  return false;
}

/**
 * Determine whether the order is awaiting confirmation on the handover.
 * If Reggefiber has specified that a confirmation is required/allowed, `'ack'` will be returned.
 * If we'll just need to wait until the
 * @param order
 */
export function isCompletionPending(order: ApiOrderEntity): boolean {
  if (order.orderStatus === "HANDED_OVER") return !!order.handover;
  return false;
}
