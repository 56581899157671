import React from "react";
import {Modal} from "antd";

import {OrderType} from "../../shared-types/orderDefinitions";
import {CreateConstructionOrderForm} from "../forms/order/CreateConstructionOrderForm";
import {ModalStateProps} from "./common";
import {CreateDeconstructionOrderForm} from "../forms/order/CreateDeconstructionOrderForm";
import {CreateSLAOrderForm} from "../forms/order/CreateSLAOrderForm";
import {mapValues} from "lodash";
import {CreateOrderFormProps} from "../forms/order/common";
import {CreatePortMigrationOrderForm} from "../forms/order/CreatePortMigrationOrderForm";
import {CreateOperatorMigrationForm} from "../forms/order/CreateOperatorMigrationForm";

export const CreateOrderModal: React.FC<ModalStateProps & {
  type: OrderType,
  FormComponent: React.FC<CreateOrderFormProps>,
}> = ({type, visible, hide, FormComponent}) => {
  return <Modal
    visible={visible}
    title={`Add "${type}" order`}
    closable={false}
    footer={null}
  >
    <FormComponent hide={hide}/>
  </Modal>;
};

const modalForms: Partial<Record<OrderType, React.FC<CreateOrderFormProps>>> = {
  construction:      CreateConstructionOrderForm,
  deconstruction:    CreateDeconstructionOrderForm,
  operatorMigration: CreateOperatorMigrationForm,
  // networkTerminator:            null,
  operationalAndMaintenanceSla: CreateSLAOrderForm,
  portMigration:                CreatePortMigrationOrderForm,
};

export const modals: Partial<Record<OrderType, React.FC<ModalStateProps>>> = mapValues(modalForms, (FormComponent, orderType: OrderType) => {
  return (props: ModalStateProps) => <CreateOrderModal {...props} type={orderType} FormComponent={FormComponent!}/>;
});
