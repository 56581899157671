import React from "react";
import {Alert} from "antd";

import {ModalType} from "./types";

export type TaskSetStateMutationContext = {
  pendingTasks?: number;
};

export type TaskSetStateMutationWarningFn = (context: TaskSetStateMutationContext) => string | null;

export const warningInfo: Partial<Record<ModalType, TaskSetStateMutationWarningFn>> = {
  close(context) {
    return context.pendingTasks ? `${context.pendingTasks} pending task(s) associated with this TaskSet - Reggefiber may reject our request to close it.` : null;
  },
};

export const TaskSetStateMutationModalWarning: React.FC<{
  type: ModalType;
  context: TaskSetStateMutationContext;
}> = ({type, context}) => {
  const warningText = warningInfo[type]?.(context);
  return warningText ?
    <Alert
      style={{marginBottom: '.75rem'}}
      showIcon={true}
      type={"warning"}
      message={<strong>Warning</strong>}
      description={warningText}
    /> : null;
};
