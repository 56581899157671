// Copied directly from the "Reggefiber_ODF_Access_order_interface_v20140102.pdf" spec file
export const orderErrorCodes = {
  'XRA_B-01': 'Gebruikersadres onjuist',
  'XRA_B-02': 'ODF locatie (Area PoP) onjuist',
  'XRA_B-03': 'Poort identificatie onjuist',
  'XRA_B-04': 'Opgegeven service-ID onjuist',
  'XRA_B-05': 'Wensdatum / Uitvoerdatum in het verleden',
  'XRA_B-06': 'Wensdatum / Uitvoerdatum te ver in de toekomst',
  'XRA_B-07': 'Uitvoerdatum is geen werkdag',
  'XRA_B-08': 'Ordertype niet toegestaan op pigtail project',
  'XRA_B-09': 'Geen eigenaar van de aansluiting',
  'XRA_B-10': 'PoP locatie is niet open voor levering',
  'XRA_B-11': 'Geen contract voor de gevraagde dienst',
  'XRA_B-12': 'Aansluiting heeft geen orderbare status',
  'XRA_B-13': 'Order reeds ingediend',
  'XRA_B-14': 'Uitvoering niet mogelijk i.v.m. lopende order',
  'XRA_B-15': 'Correspondentie telefoonnummer niet opgegeven',
  'XRA_B-16': 'opdracht te dicht op uitvoerdatum',
  'XRA_B-17': '“bestel namens” functionaliteit niet toegestaan voor combinatie aanvrager <aanvrager:coin> en recipiënt operator <recipiënt-operator:coin>',
  'XRA_B-18': '“bestel namens” functionaliteit is niet beschikbaar voor vezel A',
  'XRA_B-19': '“bestel namens” aanvraag <type> niet toegestaan vanwege vezel A status <status>',
  'XRA_B-20': '“bestel namens” aanvraag <type> niet toegestaan omdat operator <recipiënt-operator:coin> geen eigenaar is',
  'XRA_B-21': 'Vezel is reeds verhuurd. Migratie is mogelijk.',
  'XRA_B-22': 'Oplospartij in het AppointmentId is onbekend',
  'XRA_B-23': 'Voor deze dienst kan geen appointment-id worden meegegeven',
  'XRA_B-24': 'Rij Actieve lade en Rij Passieve lade komen niet overeen',
  'XRA_B-25': 'Blok Actieve lade en Blok Passieve lade komen niet overeen',
  'XRA_B-99': '<vrij tekst in te vullen door medewerker>',

  'XRA_H-01': 'Indirect orderbaar volgens passieve status',
  'XRA_H-99': '<vrije tekst>',

  // hold (delay)
  'DELAY-01': 'Klant niet bereikbaar voor maken afspraak (KIB)',
  'DELAY-02': 'Klant niet thuis op afspraakdatum',
  'DELAY-03': 'Klant wil diensten niet',
  'DELAY-04': 'Administratief',
  'DELAY-05': 'Geen NT voorraad van AO ontvangen',
  'DELAY-06': 'In opdracht van AO op HOLD gezet',
  'DELAY-07': 'Geen medewerking onderburen in hoogbouw',
  'DELAY-99': '<vrije tekst>',

  // hold (deny)
  'DENY-01': 'Klant niet bereikbaar voor maken afspraak (KIB)',
  'DENY-02': 'Klant wil de dienst niet',
  'DENY-11': 'Toestemming – Geen toestemming VVE',
  'DENY-12': 'Toestemming – Geen toestemming gemeente',
  'DENY-13': 'Vergunningverzoek afgewezen',
  'DENY-14': 'Technisch beletsel – Hoogbouw/onderburen',
  'DENY-15': 'Technisch beletsel - overig',
  'DENY-21': 'Actief poort niet gevonden – Actieve poort bestaat niet.',
  'DENY-22': 'Actief poort reeds in gebruik (bezet)',
  'DENY-23': 'Actieve poort defect (zichtbaar defect)',
  'DENY-31': 'In opdracht van AO geannuleerd',
  'DENY-32': 'NT order op een niet verhuurde aansluiting',
  'DENY-33': 'NT zonder ODFA order niet toegestaan',
  'DENY-40': 'Administratief',
  'DENY-99': '<vrije tekst>',

  // hold (update)
  'UPDATE-01': 'Klant niet bereikbaar voor maken afspraak (KIB)',
  'UPDATE-02': 'Klant niet thuis op afspraakdatum',
  'UPDATE-03': 'Klant wil dienst niet',
  'UPDATE-04': 'Wacht op klant nieuwbouw/verbouw woning',
  'UPDATE-05': 'Wacht op klant – vakantie',
  'UPDATE-06': 'Wacht op klant overig',
  'UPDATE-07': 'Beletsel door natuurinvloeden (Bijvoorbeeld vorst)',
  'UPDATE-14': 'Toestemming – nog geen toestemming VVE',
  'UPDATE-15': 'Toestemming – nog geen toestemming gemeente',
  'UPDATE-16': 'Technisch beletsel hoogbouw/onderburen',
  'UPDATE-17': 'Administratief',
  'UPDATE-18': 'Geen NT voorraad van AO ontvangen',
  'UPDATE-19': 'In opdracht van AO op HOLD gezet',
  'UPDATE-20': '(Actieve) obstructie in behandeling bij CO',
  'UPDATE-21': '(Passieve) obstructie in behandeling bij CO',
  'UPDATE-90': 'Plantype gewijzigd door verandering in set van orders op de aansluiting',
  'UPDATE-95': 'Herplannen van plandatum vanuit klant',
  'UPDATE-96': 'Herplannan van plandatum vanuit Reggefiber',
  'UPDATE-97': 'Plandatum aangegeven.',
  'UPDATE-98': 'Klantafspraak wordt in de toekomst gemaakt (verwachte datum is aangegeven)',
  'UPDATE-99': '<vrije tekst>',

  'CH-01': 'Afspraak op aangeven van de klant verzet',
  'CH-02': 'Afspraak vanuit de operator verzet',
  'CH-03': 'Afspraak vanuit retentie opnieuw gemaakt.',
  'CH-99': '<vrije tekst>',

  'CHA-00': 'Wijziging is geaccepteerd',
  'CHA-01': 'Wijziging te dicht op de uitvoerdatum',
  'CHA-02': 'Nieuwe wens- of uitvoerdatum ligt in het verleden of te dicht in de toekomst.',
  'CHA-99': '<vrije tekst>',

  'CAN-01': 'Klant wil dienst niet',
  'CAN-02': 'Opdracht verkeerd ingelegd.',
  'CAN-99': '<vrije tekst>',

  'CANA-00': 'Annuleeropdracht is geaccepteerd',
  'CANA-01': 'Annuleeropdracht te laat ingelegd',
  'CANA-02': 'Order niet gevonden',
  'CANA-99': '<vrije tekst>',

  'INFO-00': 'ok (ontvangstbevestiging)',
  'INFO-55': 'Onbekende serviceID',
  'INFO-88': 'Ontvangstbevestiging (legacy)',

  'XHO-00':  'Oplevering succesvol (zonder verdere bijzonderheden)',
  'XHO-50':  'NT wel geplaatst maar geen licht door de vezel',
  'XHO-51':  'NT wel geplaatst, wel licht, geen opleversignaal',
  'XHO-99':  '<vrije tekst>',
  'XHOA-00': 'Levering succesvol (zonder verdere bijzonderheden)',
  'XHOA-99': '<vrije tekst>',
  'XERR-01': 'Niet verwacht bericht <berichttype> op status <orderstatus> van de order',
  'XERR-99': '<vrije tekst>',
}
