import React, {CSSProperties} from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboard} from "@fortawesome/free-solid-svg-icons";

import "./ClipboardItem.scss";
import {notification} from "antd";

export const ClipboardItem: React.FC<{
  label: string,
  value?: string,
  style?: CSSProperties,
}> = ({label, value, style}) => {
  return <div style={{...style}}>{label}:{' '}
    {value ? <div style={{display: 'inline-flex', alignItems: 'center'}}>
      <code>{value}</code>
      <CopyToClipboard text={value} onCopy={() => {
        notification.info({
          message:     'Copied to clipboard',
          description: <code>{value}</code>,
        });
      }}>
        <span className={'copy-clipboard-icon'} title={'Copy this value to clipboard'}>
          <FontAwesomeIcon icon={faClipboard}/>
        </span>
      </CopyToClipboard>
    </div> : <span style={{opacity: .5}}>(unknown)</span>}
  </div>;
};
