import React, {ComponentProps} from "react";
import {Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlug} from "@fortawesome/free-solid-svg-icons";

import {OdfAccessOrderServerServiceSoapTypes} from "../../shared-types/OdfAccessOrderServerService/OdfAccessOrderServerServiceSoap";

const Dot: React.FC = () => {
  return <span style={{opacity: '.5'}}>{' · '}</span>;
}

/**
 * Shows a "summary" of an equipment endpoint (a port connected to a consumer home)
 */
export const EquipmentEndpointSummary: React.FC<{
  endpoint: OdfAccessOrderServerServiceSoapTypes.IActiveEquipmentEndpoint,
  overrideIcon?: React.ReactNode,
} & Pick<ComponentProps<typeof Tag>, "color">> = ({endpoint, overrideIcon, color}) => {
  return <Tag color={color}>
    {overrideIcon || <FontAwesomeIcon
        icon={faPlug}
        style={{marginRight: '.4rem'}}
    />}
    {endpoint.popName} <Dot/>
    {endpoint.row} <Dot/>
    {endpoint.frame} <Dot/>
    {endpoint.block} <Dot/>
    {endpoint.module} <Dot/>
    {endpoint.port}
  </Tag>;
};
