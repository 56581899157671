import {ChangeEvent} from "react";

export function changeProperty<TValue extends object>(value: TValue, onChange: (value: TValue) => void) {
  return (property: keyof TValue) => (e: ChangeEvent<HTMLInputElement>) => {
    onChange({...value, [property]: e?.target ? e.target.value : e});
  }
}

export function subFieldProps<TValue extends object>(value: TValue, onChange: (value: TValue) => void) {
  const changer = changeProperty(value, onChange);
  // TODO typing (below at `any`), `TValue[keyof TValue]` didn't fly.
  return (property: keyof TValue): {
    value: any,
    onChange: (e: any) => void,
  } => {
    return {
      value:    value[property],
      onChange: changer(property),
    };
  };
}

export type CompositeInputProps<TValue> = {
  name: string;
  value: TValue;
  onChange: (value: TValue) => void;
  errors?: any;
};
