import faker from 'faker';

import {EquipmentEndpointValue} from "../forms/order/controls/EquipmentEndpointInput";
import {ContactValue} from "../forms/order/controls/ContactInput";
import {AddressValue} from "../forms/order/controls/AddressInput";

export type FakeConstructionOrder = {
  activeEquipmentEndpoint: EquipmentEndpointValue;
  contact: ContactValue;
  address: AddressValue;
};

function mockActiveEquipmentEndpoint(): EquipmentEndpointValue {
  return {
    popName: 'pop-1',
    frame:   faker.random.number({min: 1, max: 10}),
    row:     faker.random.number({min: 1, max: 10}),
    block:   faker.random.arrayElement(['A', 'B', 'C']),
    module:  faker.random.number({min: 1, max: 10}).toString(),
    port:    faker.random.number({min: 1, max: 10}).toString(),
  };
}

function mockContact(): ContactValue {
  const firstName = faker.name.firstName();
  const lastName  = faker.name.lastName();
  return {
    firstName:     firstName,
    lastName:      lastName,
    intercalation: '',
    phoneNumber:   '+31612345678',
    email:         faker.internet.email(firstName, lastName),
  };
}

function mockAddress(): AddressValue {
  return {
    houseNumber:          faker.random.number({min: 1, max: 100}),
    zipCode:              '1111AA',
    houseNumberExtension: '',
    room:                 '',
  };
}

export function mockConstructionOrder(): FakeConstructionOrder {
  return {
    activeEquipmentEndpoint: mockActiveEquipmentEndpoint(),
    contact:                 mockContact(),
    address:                 mockAddress(),
  };
}
