import React from "react";
import {useDispatch} from "react-redux";
import {Alert, Button, Input} from "antd";
import {Controller, useForm} from "react-hook-form";
import {goBack} from "connected-react-router";
import {gql, useMutation} from "@apollo/client";

import {SplashScreen} from "../layout/SplashScreen";
import {setToken} from "../../app/authSlice";

const LOGIN = gql`
    mutation($username: String!, $password: String!) {
        login(username: $username, password: $password)
    }
`;

const LoginForm: React.FC = () => {

  const [login, {error}]        = useMutation(LOGIN);
  const loading                 = false;
  const dispatch                = useDispatch();
  const {handleSubmit, control} = useForm({
    defaultValues: {
      username: '',
      password: '',
    }
  });

  const onSubmit = handleSubmit(async values => {
    try {
      const r = await login({variables: values});
      dispatch(setToken({token: r.data.login}));
      dispatch(goBack());
    } catch (e) {
      // Error will be shown using the destructured error return value from the useMutation hook
    }
  });

  return <form onSubmit={onSubmit}>

    {error ? <Alert
      type={"error"}
      style={{marginBottom: '.75rem'}}
      message={error.message}
    /> : null}

    <Controller
      control={control}
      name={'username'}
      render={props => <Input
        placeholder={'username'}
        style={{marginBottom: '.75rem'}}
        {...props}
      />}
    />

    <Controller
      control={control}
      name={'password'}
      render={props => <Input
        placeholder={'password'}
        type={'password'}
        style={{marginBottom: '.75rem'}}
        {...props}
      />}
    />

    <div style={{display: 'flex'}}>
      <Button
        htmlType={"submit"}
        loading={loading}
        disabled={loading}
        type={"primary"}
        style={{marginLeft: 'auto'}}
      >Login</Button>
    </div>

  </form>;
};

export const LoginPage: React.FC = () => {
  return <SplashScreen>
    <LoginForm/>
  </SplashScreen>;
};
