import React from "react";
import {Button, ButtonProps} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons";

/**
 * Circular button with a sync icon. Inherits all props from ant `Button`.
 */
export const SyncButton: React.FC<ButtonProps> = props => {
  return <Button shape={'circle'} {...props}>
    <FontAwesomeIcon
      style={{
        transform: 'translateY(1px)'
      }}
      icon={faSync}
    />
  </Button>;
};
