import React, {CSSProperties} from "react";
import {TaskSetStatus, TaskStatus} from "../../shared-types/taskSetDefinitions";
import {Tag} from "antd";

export const TaskStatusTag: React.FC<{
  style?: CSSProperties;
  status: TaskStatus | TaskSetStatus;
}> = ({style, status}) => {
  let color = undefined;
  if (status === "SOLVED") {
    color = 'green';
  }
  return <Tag
    color={color}
    style={style}
    title={status}
  >{status}</Tag>;
};
