"use strict";
exports.__esModule = true;
exports.bpProcessAttributes = exports.getSchemaForTaskType = void 0;
var bpAttributeValidators_1 = require("./bpAttributeValidators");
var bpProcessStructureAttributes_1 = require("./bpProcessStructureAttributes");
var RELATEDTASKSET = bpProcessStructureAttributes_1.BpProcessStructureAttributes.RELATEDTASKSET;
var ORGANISATION = bpProcessStructureAttributes_1.BpProcessStructureAttributes.ORGANISATION;
var CABLE = bpProcessStructureAttributes_1.BpProcessStructureAttributes.CABLE;
var SOLUTION = bpProcessStructureAttributes_1.BpProcessStructureAttributes.SOLUTION;
var REPORTER = bpProcessStructureAttributes_1.BpProcessStructureAttributes.REPORTER;
var GPS = bpProcessStructureAttributes_1.BpProcessStructureAttributes.GPS;
var CONTACT = bpProcessStructureAttributes_1.BpProcessStructureAttributes.CONTACT;
/**
 * Return the "schema" (array of all root attribute specifications) for the given task type.
 * @param type
 */
function getSchemaForTaskType(type) {
    return exports.bpProcessAttributes.filter(function (attr) { return attr.operationType === type; });
}
exports.getSchemaForTaskType = getSchemaForTaskType;
exports.bpProcessAttributes = [{
        "operationType": "NT_INSTALL",
        "key": "NT_TYPE",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(255),
        "description": "Type van de te installeren NT"
    }, {
        "operationType": "NT_INSTALL",
        "key": "INSTALLATION_DESCRIPTION",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(255),
        "description": "Begeleidende tekst"
    }, {
        "operationType": "NT_INSTALL",
        "key": "SERVICE_PROVIDER_NAME",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Naam van de Service provider"
    }, {
        "operationType": "NT_INSTALL",
        "key": "POP",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Identificatie (naam) van de PoP locatie"
    }, {
        "operationType": "PATCH_REMOVE",
        "key": "POP",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Naam van de PoP locatie"
    }, {
        "operationType": "PATCH_REMOVE",
        "key": "ROW",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Rij identificatie"
    }, {
        "operationType": "PATCH_REMOVE",
        "key": "FRAME",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Kastnummer"
    }, {
        "operationType": "PATCH_REMOVE",
        "key": "BLOCK",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^[A-Z]$/),
        "description": "Blok identificatie"
    }, {
        "operationType": "PATCH_REMOVE",
        "key": "ODF_TRAY",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Nummer van de ODF lade"
    }, {
        "operationType": "PATCH_REMOVE",
        "key": "ODF_POSITION",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Positie in de ODF lade"
    }, {
        "operationType": "PATCH_REMOVE",
        "key": "PORT_TYPE",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^[AB]$/),
        "description": "Welke van de twee vezels op de aansluiting"
    }, {
        "operationType": "PATCH_REMOVE",
        "key": "EQUIPMENT",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(20),
        "description": "Identificatie van de actieve apparatuur"
    }, {
        "operationType": "PATCH_REMOVE",
        "key": "ACTIVE_PORT",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(20),
        "description": "Identificatie van de poort op de actieve apparatuur"
    }, {
        "operationType": "PATCH_REMOVE",
        "key": "EQUIPMENT_POSITION",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(20),
        "description": "Identificatie van de plaats van de apparatuur"
    }, {
        "operationType": "PATCH_REMOVE",
        "key": "OPERATOR_ORDER_ID",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(64),
        "description": "Referentie naar de order van de operator achter deze taak."
    }, {
        "operationType": "PATCH_INSTALL",
        "key": "POP",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Naam van de PoP locatie"
    }, {
        "operationType": "PATCH_INSTALL",
        "key": "ROW",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Rij identificatie"
    }, {
        "operationType": "PATCH_INSTALL",
        "key": "FRAME",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Kastnummer"
    }, {
        "operationType": "PATCH_INSTALL",
        "key": "BLOCK",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^[A-Z]$/),
        "description": "Blok identificatie"
    }, {
        "operationType": "PATCH_INSTALL",
        "key": "ODF_TRAY",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Nummer van de ODF lade"
    }, {
        "operationType": "PATCH_INSTALL",
        "key": "ODF_POSITION",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Positie in de ODF lade"
    }, {
        "operationType": "PATCH_INSTALL",
        "key": "PORT_TYPE",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^[AB]$/),
        "description": "Welke van de twee vezels op de aansluiting"
    }, {
        "operationType": "PATCH_INSTALL",
        "key": "EQUIPMENT",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(20),
        "description": "Identificatie van de actieve apparatuur"
    }, {
        "operationType": "PATCH_INSTALL",
        "key": "ACTIVE_PORT",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(20),
        "description": "Identificatie van de poort op de actieve apparatuur"
    }, {
        "operationType": "PATCH_INSTALL",
        "key": "EQUIPMENT_POSITION",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(20),
        "description": "Identificatie van de plaats van de apparatuur"
    }, {
        "operationType": "PATCH_INSTALL",
        "key": "OPERATOR_ORDER_ID",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(64),
        "description": "Referentie naar de order van de operator achter deze taak."
    }, {
        "operationType": "PATCH_INSTALL",
        "key": "PATCH_LENGTH",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^\d{1,5}(,\d{1,10})?$/),
        "description": "Patchcord lengte (komma getal in meters)"
    }, {
        "operationType": "PATCH_INSTALL",
        "key": "PATCH_WIDTH",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^\d{1,5}(,\d{1,10})?$/),
        "description": "Patchcord dikte (komma getal in millimeter)"
    }, {
        "operationType": "PATCH_INSTALL",
        "key": "PASSIVE_CONNECTORTYPE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Passief connector type"
    }, {
        "operationType": "PATCH_INSTALL",
        "key": "ACTIVE_CONNECTORTYPE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Actief connector type"
    }, {
        "operationType": "PATCH_MIGRATE",
        "key": "POP",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Naam van de PoP locatie"
    }, {
        "operationType": "PATCH_MIGRATE",
        "key": "ROW",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Rij identificatie"
    }, {
        "operationType": "PATCH_MIGRATE",
        "key": "FRAME",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^\d{1,4}$/),
        "description": "Kastnummer"
    }, {
        "operationType": "PATCH_MIGRATE",
        "key": "BLOCK",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^[A-Z]$/),
        "description": "Blok identificatie"
    }, {
        "operationType": "PATCH_MIGRATE",
        "key": "ODF_TRAY",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Nummer van de ODF lade"
    }, {
        "operationType": "PATCH_MIGRATE",
        "key": "ODF_POSITION",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Positie in de ODF lade"
    }, {
        "operationType": "PATCH_MIGRATE",
        "key": "PORT_TYPE",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^[AB]$/),
        "description": "Welke van de twee vezels op de aansluiting"
    }, {
        "operationType": "PATCH_MIGRATE",
        "key": "EQUIPMENT",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(20),
        "description": "Identificatie van de actieve apparatuur"
    }, {
        "operationType": "PATCH_MIGRATE",
        "key": "ACTIVE_PORT",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(20),
        "description": "Identificatie van de poort op de actieve apparatuur"
    }, {
        "operationType": "PATCH_MIGRATE",
        "key": "EQUIPMENT_POSITION",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(20),
        "description": "Identificatie van de plaats van de apparatuur"
    }, {
        "operationType": "PATCH_MIGRATE",
        "key": "OPERATOR_ORDER_ID",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(64),
        "description": "Referentie naar de order van de operator achter deze taak."
    }, {
        "operationType": "PATCH_MIGRATE",
        "key": "PATCH_LENGTH",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^\d{1,5}(,\d{1,10})?$/),
        "description": "Patchcord lengte (komma getal in meters)"
    }, {
        "operationType": "PATCH_MIGRATE",
        "key": "PATCH_WIDTH",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^\d{1,5}(,\d{1,10})?$/),
        "description": "Patchcord dikte (komma getal in millimeter)"
    }, {
        "operationType": "PATCH_MIGRATE",
        "key": "PASSIVE_CONNECTORTYPE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Passief connector type"
    }, {
        "operationType": "PATCH_MIGRATE",
        "key": "ACTIVE_CONNECTORTYPE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Actief connector type"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "EXISTING_FTU",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.ftu(),
        "description": "Identificatie van de bestaande FTU"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "NEW_FTU",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.ftu(),
        "description": "Identificatie van de nieuwe te plaatsen FTU"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "POP",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Naam van de PoP locatie"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "ROW",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Rij identificatie"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "FRAME",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Kastnummer"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "BLOCK",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^[A-Z]$/),
        "description": "Blok identificatie"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "ODF_TRAY_A",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Nummer van de ODF lade vezel A"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "ODF_POSITION_A",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Positie in de ODF lade vezel A"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "ODF_TRAY_B",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Nummer van de ODF lade vezel B"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "ODF_POSITION_B",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Positie in de ODF lade vezel B"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "INSTALLED_FTU",
        "requiredOnOpen": false,
        "requiredOnClose": true,
        "mutableByInitiator": false,
        "mutableByContractor": true,
        "format": bpAttributeValidators_1.ftu(),
        "description": "Identificatie van de geïnstalleerde FTU"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "OPERATOR_ORDER_ID",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(64),
        "description": "Referentie naar de order van de operator achter deze taak."
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "RENTED_FIBER",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^.{13}$/),
        "description": "(Connection id)"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "PASSIVE_DELIVERY_DATE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^\d{4}-\d{2}-\d{2}$/),
        "description": "(Opleverdatum)"
    }, {
        "operationType": "CONSTRUCT",
        "key": "POP",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Naam van de PoP locatie"
    }, {
        "operationType": "CONSTRUCT",
        "key": "ROW",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^\d{0,3}$/),
        "description": "Rij identificatie"
    }, {
        "operationType": "CONSTRUCT",
        "key": "FRAME",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^\d{1,4}$/),
        "description": "Kastnummer"
    }, {
        "operationType": "CONSTRUCT",
        "key": "BLOCK",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^[A-Z]$/),
        "description": "Blok identificatie"
    }, {
        "operationType": "CONSTRUCT",
        "key": "ODF_TRAY_A",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(1, 9999),
        "description": "Nummer van de ODF lade vezel A"
    }, {
        "operationType": "CONSTRUCT",
        "key": "ODF_TRAY_B",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(1, 9999),
        "description": "Nummer van de ODF lade vezel B"
    }, {
        "operationType": "CONSTRUCT",
        "key": "ODF_POSITION_A",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(1, 255),
        "description": "Positie in de ODF lade vezel A"
    }, {
        "operationType": "CONSTRUCT",
        "key": "ODF_POSITION_B",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(1, 255),
        "description": "Positie in de ODF lade vezel B"
    }, {
        "operationType": "CONSTRUCT",
        "key": "PASSIVE_DELIVERY_STATUS",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 99),
        "description": "Opleverstatus vanuit het passieve register (BC)"
    }, {
        "operationType": "CONSTRUCT",
        "key": "PASSIVE_DELIVERY_DATE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^\d{4}-\d{2}-\d{2}$/),
        "description": "(Opleverdatum)"
    }, {
        "operationType": "CONSTRUCT",
        "key": "NEW_FTU",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.ftu(),
        "description": "Identificatie van de nieuwe te plaatsen FTU"
    }, {
        "operationType": "CONSTRUCT",
        "key": "NETWORK_CONCEPT",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(4),
        "description": "netwerk concept vanuit de BC; geeft aan hoeveel vezels afgemonteerd moeten worden. Mogelijke waarden momenteel (kan wijzigen) 2V, 1VA, 1VE, 1V, 2VI"
    }, {
        "operationType": "CONSTRUCT",
        "key": "EXT_REF",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(15),
        "description": "Referentie aan bijbehorende infra aanvraag (LIP aanvraagId of GemmaId)"
    }, {
        "operationType": "CONSTRUCT",
        "key": "WISHEDWEEK",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 54),
        "description": "weeknummer"
    }, {
        "operationType": "CONSTRUCT",
        "key": "WISHEDYEAR",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(2000, 3000),
        "description": "Jaar"
    }, {
        "operationType": "CONSTRUCT",
        "key": "RENTED_FIBER_UID",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^.{13}$/),
        "description": "(Connection id)"
    }, {
        "operationType": "CONSTRUCT",
        "key": "NR_OF_WELDED_FIBERS",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(1, 2),
        "description": "Aantal te lassen vezels"
    }, {
        "operationType": "CONSTRUCT",
        "key": "POP",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Naam van de PoP locatie"
    }, {
        "operationType": "CONSTRUCT",
        "key": "ROW",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Rij identificatie"
    }, {
        "operationType": "CONSTRUCT",
        "key": "FRAME",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Kastnummer"
    }, {
        "operationType": "CONSTRUCT",
        "key": "BLOCK",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^[A-Z]$/),
        "description": "Blok identificatie"
    }, {
        "operationType": "CONSTRUCT",
        "key": "ODF_TRAY",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Nummer van de ODF lade"
    }, {
        "operationType": "CONSTRUCT",
        "key": "ODF_POSITION",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Positie in de ODF lade"
    }, {
        "operationType": "CONSTRUCT",
        "key": "PORT_TYPE",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^[AB]$/),
        "description": "Welke van de twee vezels op de aansluiting"
    }, {
        "operationType": "CONSTRUCT",
        "key": "PASSIVE_DELIVERY_STATUS",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 99),
        "description": "Opleverstatus vanuit het passieve register (BC)"
    }, {
        "operationType": "CONSTRUCT",
        "key": "HOME_CONNECTION_DATE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^\d{4}-\d{2}-\d{2}$/),
        "description": "(HAS-Datum)"
    }, {
        "operationType": "CONSTRUCT",
        "key": "EQUIPMENT",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(20),
        "description": "Identificatie van de actieve apparatuur"
    }, {
        "operationType": "CONSTRUCT",
        "key": "ACTIVE_PORT",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(20),
        "description": "Identificatie van de poort op de actieve apparatuur"
    }, {
        "operationType": "CONSTRUCT",
        "key": "PASSIVE_DELIVERY_DATE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^\d{4}-\d{2}-\d{2}$/),
        "description": "(Opleverdatum)"
    }, {
        "operationType": "CONSTRUCT",
        "key": "RENTED_FIBER_UID",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^.{13}$/),
        "description": "(Connection id)"
    }, {
        "operationType": "CONSTRUCT",
        "key": "EQUIPMENT_POSITION",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(20),
        "description": "Identificatie van de plaats van de apparatuur"
    }, {
        "operationType": "CONSTRUCT",
        "key": "OPERATOR_ORDER_ID",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(64),
        "description": "Referentie naar de order van de operator achter deze taak."
    }, {
        "operationType": "CONSTRUCT",
        "key": "ODF_TRAY_2",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Nummer van de ODF lade voor de tweede vezel"
    }, {
        "operationType": "CONSTRUCT",
        "key": "ODF_POSITION_2",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Positie van de tweede vezel in de ODF lade"
    }, {
        "operationType": "CONSTRUCT",
        "key": "NR_OF_WELDED_FIBERS",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(1, 2),
        "description": "Aantal te lassen vezels"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "POP",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Naam van de PoP locatie"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "ROW",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Rij identificatie"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "FRAME",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Kastnummer"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "BLOCK",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^[A-Z]$/),
        "description": "Blok identificatie"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "ODF_TRAY",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Nummer van de ODF lade"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "ODF_POSITION",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Positie in de ODF lade"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "PORT_TYPE",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^[AB]$/),
        "description": "Welke van de twee vezels op de aansluiting"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "EQUIPMENT",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(20),
        "description": "Identificatie van de actieve apparatuur"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "ACTIVE_PORT",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(20),
        "description": "Identificatie van de poort op de actieve apparatuur"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "PASSIVE_DELIVERY_DATE",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^\d{4}-\d{2}-\d{2}$/),
        "description": "(Opleverdatum)"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "RENTED_FIBER_UID",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^.{13}$/),
        "description": "(Connection id)"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "EXISTING_FTU",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.ftu(),
        "description": "Identificatie van de bestaande FTU"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "NEW_FTU",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.ftu(),
        "description": "Identificatie van de nieuwe te plaatsen FTU"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "EQUIPMENT_POSITION",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(20),
        "description": "Identificatie van de plaats van de apparatuur"
    }, {
        "operationType": "FTU_RECONSTRUCT",
        "key": "OPERATOR_ORDER_ID",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(64),
        "description": "Referentie naar de order van de operator achter deze taak."
    }, {
        "operationType": "CUSTOMER_INCIDENT",
        "key": "ORGANISATION",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(1, 1, ORGANISATION),
        "description": "contact gegevens van de aanvrager, zie sub-paragraaf organisation"
    }, {
        "operationType": "CUSTOMER_INCIDENT",
        "key": "FIBERTERMINATIONTYPE",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.ftu(),
        "description": "Type FTU"
    }, {
        "operationType": "CUSTOMER_INCIDENT",
        "key": "POP",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Naam van de PoP locatie"
    }, {
        "operationType": "CUSTOMER_INCIDENT",
        "key": "ROW",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Rij identificatie"
    }, {
        "operationType": "CUSTOMER_INCIDENT",
        "key": "FRAME",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Kastnummer"
    }, {
        "operationType": "CUSTOMER_INCIDENT",
        "key": "BLOCK",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^[A-Z]$/),
        "description": "Blok identificatie"
    }, {
        "operationType": "CUSTOMER_INCIDENT",
        "key": "ODF_TRAY",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Nummer van de ODF lade"
    }, {
        "operationType": "CUSTOMER_INCIDENT",
        "key": "ODF_POSITION_A",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Positie in de ODF lade"
    }, {
        "operationType": "CUSTOMER_INCIDENT",
        "key": "ODF_POSITION_B",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Positie in de ODF lade"
    }, {
        "operationType": "CUSTOMER_INCIDENT",
        "key": "RENTED_FIBER_UID_A",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^.{13}$/),
        "description": "Connection id A vezel (indien verhuurd)"
    }, {
        "operationType": "CUSTOMER_INCIDENT",
        "key": "RENTED_FIBER_UID_B",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^.{13}$/),
        "description": "Connection id B vezel (indien verhuurd)"
    }, {
        "operationType": "CUSTOMER_INCIDENT",
        "key": "AO_TICKET",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Ticketnummer van Actieve Operator"
    }, {
        "operationType": "CUSTOMER_INCIDENT",
        "key": "EQUIPMENT",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(256),
        "description": "Identificatie van de actieve apparatuur"
    }, {
        "operationType": "CUSTOMER_INCIDENT",
        "key": "MECHANIC_ON_SITE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^(YES|NO)$/),
        "description": "Monteur ter plaatse?"
    }, {
        "operationType": "CUSTOMER_INCIDENT",
        "key": "RELATEDTASKSET",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(0, 5, RELATEDTASKSET),
        "description": "taakset ID’s van een vorige incident"
    }, {
        "operationType": "CUSTOMER_INCIDENT",
        "key": "ANALYSE_CODE",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(40),
        "description": "Analyse code"
    }, {
        "operationType": "CUSTOMER_INCIDENT",
        "key": "SOLUTION",
        "requiredOnOpen": false,
        "requiredOnClose": true,
        "mutableByInitiator": false,
        "mutableByContractor": true,
        "format": bpAttributeValidators_1.collection(1, 1, SOLUTION),
        "description": "Afsluit categorie,"
    }, {
        "operationType": "NETWORK_INCIDENT",
        "key": "ORGANISATION",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(1, 1, ORGANISATION),
        "description": "contact gegevens van de aanvrager, zie sub-paragraaf organisation"
    }, {
        "operationType": "NETWORK_INCIDENT",
        "key": "REPORTER",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(1, 1, REPORTER),
        "description": "contact gegevens van de melder, zie sub-paragraaf reporter"
    }, {
        "operationType": "NETWORK_INCIDENT",
        "key": "AO_TICKET",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Ticketnummer van Actieve Operator"
    }, {
        "operationType": "NETWORK_INCIDENT",
        "key": "ACTIVITY",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(40),
        "description": "Aard van werkzaamheden waarbij incident is ontstaan. (Gas, Water, etc)"
    }, {
        "operationType": "NETWORK_INCIDENT",
        "key": "KLIC_NO",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Klic nummer van werkzaamheden waarbij incident is ontstaan"
    }, {
        "operationType": "NETWORK_INCIDENT",
        "key": "CABLE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(1, 99, CABLE),
        "description": "Kabel gegevens , zie sub-paragraaf cable"
    }, {
        "operationType": "NETWORK_INCIDENT",
        "key": "GPS",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(1, 1, GPS),
        "description": "GPS coördinaat (bij kabelschade), zie sub-paragraaf gps"
    }, {
        "operationType": "NETWORK_INCIDENT",
        "key": "ANALYSE_CODE",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(40),
        "description": "Analyse code"
    }, {
        "operationType": "NETWORK_INCIDENT",
        "key": "SOLUTION",
        "requiredOnOpen": false,
        "requiredOnClose": true,
        "mutableByInitiator": false,
        "mutableByContractor": true,
        "format": bpAttributeValidators_1.collection(1, 1, SOLUTION),
        "description": "Afsluit categorie, zie sub-paragraaf solution"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "ORGANISATION",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(1, 1, ORGANISATION),
        "description": "contact gegevens van de aanvrager, zie sub-paragraaf organisation"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "FIBERTERMINATIONTYPE",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.ftu(),
        "description": "Type FTU"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "POP",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Naam van de PoP locatie"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "ROW",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Rij identificatie"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "FRAME",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Kastnummer"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "BLOCK",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^[A-Z]$/),
        "description": "Blok identificatie"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "ODF_TRAY",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Nummer van de ODF lade"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "ODF_POSITION_A",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Positie in de ODF lade"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "ODF_POSITION_B",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Positie in de ODF lade"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "RENTED_FIBER_UID_A",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^.{13}$/),
        "description": "Connection id A vezel (indien verhuurd)"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "RENTED_FIBER_UID_B",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^.{13}$/),
        "description": "Connection id B vezel (indien verhuurd)"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "BUILDINGTYPE",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(16),
        "description": "Gebouwtype"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "THROUGHPUTDEPENDENCY",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^(YES|NO)$/),
        "description": "Doorvoerafhankelijkheid"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "ATTENUATION",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^\d,\d$/),
        "description": "Dempingwaardes (in db)"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "AO_TICKET",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Ticketnummer van Actieve Operator"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "EQUIPMENT",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(256),
        "description": "Identificatie van de actieve apparatuur"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "DOMAINPERMISSION",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^(YES|NO)$/),
        "description": "Domeintoestemming"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "CABLE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(1, 99, CABLE),
        "description": "Kabel gegevens , zie sub-paragraaf cable"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "GPS",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(1, 1, GPS),
        "description": "GPS coördinaat (bij kabelschade), zie sub-paragraaf gps"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "MECHANIC_ON_SITE",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^(YES|NO)$/),
        "description": "Monteur ter plaatse?"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "RELATEDTASKSET",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(0, 5, RELATEDTASKSET),
        "description": "taakset ID’s van een vorige incident"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "ANALYSE_CODE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(40),
        "description": "Analyse code"
    }, {
        "operationType": "CUSTOMER_PROBLEM",
        "key": "SOLUTION",
        "requiredOnOpen": false,
        "requiredOnClose": true,
        "mutableByInitiator": false,
        "mutableByContractor": true,
        "format": bpAttributeValidators_1.collection(1, 1, SOLUTION),
        "description": "Afsluit categorie, zie sub-paragraaf solution"
    }, {
        "operationType": "NETWORK_MAINTENANCE",
        "key": "ORGANISATION",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(1, 1, ORGANISATION),
        "description": "contact gegevens van de aanvrager, zie sub-paragraaf organisation"
    }, {
        "operationType": "NETWORK_MAINTENANCE",
        "key": "REPORTER",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(1, 1, REPORTER),
        "description": "contact gegevens van de melder, zie sub-paragraaf reporter"
    }, {
        "operationType": "NETWORK_MAINTENANCE",
        "key": "CABLE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(1, 99, CABLE),
        "description": "Kabel gegevens , zie sub-paragraaf cable"
    }, {
        "operationType": "NETWORK_MAINTENANCE",
        "key": "GPS",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(1, 1, GPS),
        "description": "GPS coördinaat (bij kabelschade), zie sub-paragraaf gps"
    }, {
        "operationType": "NETWORK_MAINTENANCE",
        "key": "ACTIVITY",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(40),
        "description": "Aard van werkzaamheden waarbij incident is ontstaan. (Gas, Water, etc)"
    }, {
        "operationType": "NETWORK_MAINTENANCE",
        "key": "KLIC_NO",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Klic nummer van werkzaamheden waarbij incident is ontstaan"
    }, {
        "operationType": "NETWORK_MAINTENANCE",
        "key": "ANALYSE_CODE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(40),
        "description": "Analyse code"
    }, {
        "operationType": "NETWORK_MAINTENANCE",
        "key": "SOLUTION",
        "requiredOnOpen": false,
        "requiredOnClose": true,
        "mutableByInitiator": false,
        "mutableByContractor": true,
        "format": bpAttributeValidators_1.collection(1, 1, SOLUTION),
        "description": "Afsluit categorie, zie sub-paragraaf solution_code"
    }, {
        "operationType": "RESEARCH",
        "key": "ORGANISATION",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(1, 1, ORGANISATION),
        "description": "contact gegevens van de aanvrager, zie sub-paragraaf organisation"
    }, {
        "operationType": "RESEARCH",
        "key": "POP",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Naam van de PoP locatie"
    }, {
        "operationType": "RESEARCH",
        "key": "ROW",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Rij identificatie"
    }, {
        "operationType": "RESEARCH",
        "key": "FRAME",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Kastnummer"
    }, {
        "operationType": "RESEARCH",
        "key": "BLOCK",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^[A-Z]$/),
        "description": "Blok identificatie"
    }, {
        "operationType": "RESEARCH",
        "key": "ODF_TRAY",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Nummer van de ODF lade"
    }, {
        "operationType": "RESEARCH",
        "key": "ODF_POSITION_A",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Positie in de ODF lade"
    }, {
        "operationType": "RESEARCH",
        "key": "ODF_POSITION_B",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Positie in de ODF lade"
    }, {
        "operationType": "RESEARCH",
        "key": "RENTED_FIBER_UID_A",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^.{13}$/),
        "description": "Connection id A vezel (indien verhuurd)"
    }, {
        "operationType": "RESEARCH",
        "key": "RENTED_FIBER_UID_B",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^.{13}$/),
        "description": "Connection id B vezel (indien verhuurd)"
    }, {
        "operationType": "RESEARCH",
        "key": "TYPE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Type apparatuur (airco, brandinstallatie etc)"
    }, {
        "operationType": "RESEARCH",
        "key": "MANUFACTURER",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Merk"
    }, {
        "operationType": "RESEARCH",
        "key": "MODEL",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Model/type"
    }, {
        "operationType": "RESEARCH",
        "key": "YEAR",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(2000, 3000),
        "description": "jaar dat apparaat geplaatst is."
    }, {
        "operationType": "RESEARCH",
        "key": "FIBERTERMINATIONTYPE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.ftu(),
        "description": "Type FTU"
    }, {
        "operationType": "RESEARCH",
        "key": "BUILDINGTYPE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(16),
        "description": "Gebouwtype"
    }, {
        "operationType": "RESEARCH",
        "key": "THROUGHPUTDEPENDENCY",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^(YES|NO)$/),
        "description": "Doorvoerafhankelijkheid"
    }, {
        "operationType": "RESEARCH",
        "key": "CABLE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(1, 99, CABLE),
        "description": "Kabel gegevens , zie sub-paragraaf cable"
    }, {
        "operationType": "RESEARCH",
        "key": "ANALYSE_CODE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(40),
        "description": "Analyse code"
    }, {
        "operationType": "RESEARCH",
        "key": "SOLUTION",
        "requiredOnOpen": false,
        "requiredOnClose": true,
        "mutableByInitiator": false,
        "mutableByContractor": true,
        "format": bpAttributeValidators_1.collection(1, 1, SOLUTION),
        "description": "Afsluit categorie, zie sub-paragraaf solution"
    }, {
        "operationType": "RECOVERY",
        "key": "ORGANISATION",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(1, 1, ORGANISATION),
        "description": "contact gegevens van de aanvrager, zie sub-paragraaf organisation"
    }, {
        "operationType": "RECOVERY",
        "key": "POP",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Naam van de PoP locatie"
    }, {
        "operationType": "RECOVERY",
        "key": "ROW",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Rij identificatie"
    }, {
        "operationType": "RECOVERY",
        "key": "FRAME",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Kastnummer"
    }, {
        "operationType": "RECOVERY",
        "key": "BLOCK",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^[A-Z]$/),
        "description": "Blok identificatie"
    }, {
        "operationType": "RECOVERY",
        "key": "ODF_TRAY",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Nummer van de ODF lade"
    }, {
        "operationType": "RECOVERY",
        "key": "ODF_POSITION_A",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Positie in de ODF lade"
    }, {
        "operationType": "RECOVERY",
        "key": "ODF_POSITION_B",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Positie in de ODF lade"
    }, {
        "operationType": "RECOVERY",
        "key": "RENTED_FIBER_UID_A",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^.{13}$/),
        "description": "Connection id A vezel (indien verhuurd)"
    }, {
        "operationType": "RECOVERY",
        "key": "RENTED_FIBER_UID_B",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^.{13}$/),
        "description": "Connection id B vezel (indien verhuurd)"
    }, {
        "operationType": "RECOVERY",
        "key": "TYPE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Type apparatuur (airco, brandinstallatie etc)"
    }, {
        "operationType": "RECOVERY",
        "key": "MANUFACTURER",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Merk"
    }, {
        "operationType": "RECOVERY",
        "key": "MODEL",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Model/type"
    }, {
        "operationType": "RECOVERY",
        "key": "YEAR",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(2000, 3000),
        "description": "jaar dat apparaat geplaatst is."
    }, {
        "operationType": "RECOVERY",
        "key": "FIBERTERMINATIONTYPE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.ftu(),
        "description": "Type FTU"
    }, {
        "operationType": "RECOVERY",
        "key": "BUILDINGTYPE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(16),
        "description": "Gebouwtype"
    }, {
        "operationType": "RECOVERY",
        "key": "THROUGHPUTDEPENDENCY",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^(YES|NO)$/),
        "description": "Doorvoerafhankelijkheid"
    }, {
        "operationType": "RECOVERY",
        "key": "CABLE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(1, 99, CABLE),
        "description": "Kabel gegevens , zie sub-paragraaf cable"
    }, {
        "operationType": "RECOVERY",
        "key": "ANALYSE_CODE",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(40),
        "description": "Analyse code"
    }, {
        "operationType": "RECOVERY",
        "key": "SOLUTION",
        "requiredOnOpen": false,
        "requiredOnClose": true,
        "mutableByInitiator": false,
        "mutableByContractor": true,
        "format": bpAttributeValidators_1.collection(1, 1, SOLUTION),
        "description": "Afsluit categorie, zie sub-paragraaf solution"
    }, {
        "operationType": "DECONSTRUCT",
        "key": "UID",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(11),
        "description": "Unieke identificatie van de sloop order, dit is een betekenis-loos uniek nummer."
    }, {
        "operationType": "DECONSTRUCT",
        "key": "EXT_REF",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(15),
        "description": "Referentie aan bijbehorende infra aanvraag (LIP aanvraagId of GemmaId)"
    }, {
        "operationType": "DECONSTRUCT",
        "key": "DESCRIPTION",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(512),
        "description": "Ruimte waarin Reggefiber medewerker (bijvoorbeeld de werk voorbereider) een toelichting kan geven bij de sloop opdracht."
    }, {
        "operationType": "DECONSTRUCT",
        "key": "CONTACT",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(0, 2, CONTACT),
        "description": "contact gegevens van de aanvrager, zie sub-paragraaf contact"
    }, {
        "operationType": "DECONSTRUCT",
        "key": "PROJECT_DECONSTRUCTION",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": true,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^(true|false)$/),
        "description": "Is het een projectmatige sloop"
    }, {
        "operationType": "DECONSTRUCT",
        "key": "CABLE_ONLY",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": true,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^(true|false)$/),
        "description": "Kabelsloop"
    }, {
        "operationType": "DECONSTRUCT",
        "key": "PASSIVE_DELIVERY_STATUS",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": true,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 100),
        "description": "Nummer ( 0 t/m 100)"
    }, {
        "operationType": "DECONSTRUCT",
        "key": "WISHEDWEEK",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 54),
        "description": "weeknummer"
    }, {
        "operationType": "DECONSTRUCT",
        "key": "WISHEDYEAR",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(2000, 3000),
        "description": "Jaar"
    }, {
        "operationType": "RELOCATE",
        "key": "UID",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(11),
        "description": "Unieke identificatie van de verplaats order, dit is een betekenisloos uniek nummer."
    }, {
        "operationType": "RELOCATE",
        "key": "EXT_REF",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(15),
        "description": "Referentie aan bijbehorende infra aanvraag (LIP aanvraagId of GemmaId)"
    }, {
        "operationType": "RELOCATE",
        "key": "DESCRIPTION",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(512),
        "description": "Ruimte waarin Reggefiber medewerker (bijvoorbeeld de werk voorbereider) een toelichting kan geven bij de verplaats opdracht."
    }, {
        "operationType": "RELOCATE",
        "key": "CONTACT",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.collection(0, 2, CONTACT),
        "description": "contact gegevens van de aanvrager, zie sub-paragraaf contact"
    }, {
        "operationType": "RELOCATE",
        "key": "WISHEDWEEK",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 54),
        "description": "weeknummer"
    }, {
        "operationType": "RELOCATE",
        "key": "WISHEDYEAR",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(2000, 3000),
        "description": "Jaar"
    }, {
        "operationType": "RELOCATE",
        "key": "POP",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.max(32),
        "description": "Naam van de PoP locatie"
    }, {
        "operationType": "RELOCATE",
        "key": "ROW",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 255),
        "description": "Rij identificatie"
    }, {
        "operationType": "RELOCATE",
        "key": "FRAME",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(0, 9999),
        "description": "Kastnummer"
    }, {
        "operationType": "RELOCATE",
        "key": "BLOCK",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.pattern(/^[A-Z]$/),
        "description": "Blok identificatie"
    }, {
        "operationType": "RELOCATE",
        "key": "ODF_TRAY_A",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(1, 9999),
        "description": "Nummer van de ODF lade vezel A"
    }, {
        "operationType": "RELOCATE",
        "key": "ODF_POSITION_A",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(1, 255),
        "description": "Positie in de ODF lade vezel A"
    }, {
        "operationType": "RELOCATE",
        "key": "ODF_TRAY_B",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(1, 9999),
        "description": "Nummer van de ODF lade vezel B"
    }, {
        "operationType": "RELOCATE",
        "key": "ODF_POSITION_B",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(1, 255),
        "description": "Positie in de ODF lade vezel B"
    }, {
        "operationType": "RELOCATE",
        "key": "EXISTING_FTU",
        "requiredOnOpen": false,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.ftu(),
        "description": "Identificatie van de bestaande FTU"
    }, {
        "operationType": "RELOCATE",
        "key": "NEW_FTU",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.ftu(),
        "description": "Identificatie van de nieuwe te plaatsen FTU"
    }, {
        "operationType": "RELOCATE",
        "key": "NETWORK_CONCEPT",
        "requiredOnOpen": true,
        "requiredOnClose": false,
        "mutableByInitiator": false,
        "mutableByContractor": false,
        "format": bpAttributeValidators_1.range(1, 64),
        "description": "Netwerk concept van de aansluiting"
    }];
