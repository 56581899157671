import React from "react";
import {Breadcrumb} from "antd";
import {NavLink} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import ReactTimeago from "react-timeago";

import {IxinfoInput} from "../../shared-types/OdfAccessOrderClientService/OdfAccessOrderClientServiceSoap";
import {baseOptionsGqlGrid} from "../../constants";
import {HeaderBar} from "../parts/HeaderBar";
import {BreadcrumbsBar} from "../parts/BreadcrumbsBar";
import {QueryResult} from "../QueryResult";
import {sortByCreatedAt} from "../../util/sort";
import {Json} from "../parts/Json";
import {ExpandableGrid} from "../ExpandableGrid";

export type ApiHandoffEntity = {
  id: number;
  attributes: IxinfoInput;
  createdAt: string;
  updatedAt?: string;
};

const HandoffAttributes: React.FC<{ id: number }> = ({id}) => {
  const {loading, error, data} = useQuery(gql`
        query handoffOverview($id: Int!) {
            handoff(id: $id) { attributes }
        }
    `, {variables: {id}});
  return <QueryResult loading={loading} error={error}>{() => (
    <Json json={data.handoff.attributes} collapsed={1}/>
  )}</QueryResult>;
};

export const HandoffOverview: React.FC = () => {

  const {loading, error, data} = useQuery(gql`
        query handoffOverview {
            handoffs { id externalOrderUid processStep createdAt }
        }
    `, baseOptionsGqlGrid);

  return <>
    <BreadcrumbsBar>
      <Breadcrumb>
        <Breadcrumb.Item><NavLink to={"/handoffs"}>Departing customers</NavLink></Breadcrumb.Item>
      </Breadcrumb>
    </BreadcrumbsBar>

    <HeaderBar title={'Departing customer updates'}/>

    <QueryResult loading={loading} error={error}>
      {() => <ExpandableGrid<ApiHandoffEntity>
        className={"stretch-grid"}
        loading={loading}
        rowKey={row => row.id}
        dataSource={data ? sortByCreatedAt(data.handoffs) : undefined}
        pagination={{
          defaultPageSize: 25,
        }}
        expandedRowRender={handoff => <HandoffAttributes id={handoff.id}/>}
        columns={[{
          key:       'externalOrderUid',
          dataIndex: 'externalOrderUid',
          title:     'UID',
          render:    uid => <code>{uid}</code>,
        }, {
          key:       'processStep',
          dataIndex: 'processStep',
          title:     'Process step',
        }, {
          key:       'createdAt',
          dataIndex: 'createdAt',
          title:     'Created',
          render:    date => <ReactTimeago date={date}/>,
        }]}
      />}
    </QueryResult>
  </>;
};
