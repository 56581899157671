import React, {CSSProperties} from "react";
import classnames from "classnames";

import Logo from "./Logo";

export const LogoBar: React.FC<{
  style?: CSSProperties,
  header?: string,
  hideBrandNameOnMobile?: boolean,
}> = ({
        style,
        header,
        hideBrandNameOnMobile,
      }) => (
  <div
    style={{
      display:    'flex',
      alignItems: 'center',
      ...style,
    }}
  >
    <Logo
      size={30}
      style={{marginRight: '1rem'}}
    />
    <h3 style={{marginBottom: 0}}>
      <strong
        className={classnames({
          'hide-on-mobile': hideBrandNameOnMobile,
        })}
        style={{marginRight: '.5rem'}}
      >L2-connect</strong>
      {header ? <span style={{opacity: .6}}>{header}</span> : null}
    </h3>
  </div>
);
