import React from "react";
import {Button} from "antd";

export const ControlledModalFormFooter: React.FC<{
  hide?: () => void,
  submitting: boolean,
  cancelText?: string,
}> = ({hide, submitting, cancelText = 'Cancel', children}) => {
  return <div style={{display: "flex"}}>
    {children}
    <Button onClick={hide} style={{marginLeft: 'auto', marginRight: '.5rem'}}>{cancelText}</Button>
    <Button loading={submitting} type={"primary"} htmlType={"submit"}>Confirm</Button>
  </div>;
};
