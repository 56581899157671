import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import {OrderOverview} from "./orders/OrderOverview";
import {OrderDetailsPageRoute} from "./orders/OrderDetails";
import {HandoffOverview} from "./handoffs/HandoffOverview";
import {Availability} from "./pages/Availability";
import {TaskSetOverview} from "./taskhandling/TaskSetOverview";
import {TaskSetDetailsPageRoute} from "./taskhandling/TaskSetDetails";

export const DashboardRouter: React.FC = () => {
  return <Switch>

    <Route path={"/orders"} exact component={OrderOverview}/>
    <Route path={"/orders/:orderId"} component={OrderDetailsPageRoute}/>

    <Route path={"/handoffs"} exact component={HandoffOverview}/>
    <Route path={"/handoffs/:handoffId"} component={HandoffOverview}/>

    <Route path={"/task-sets"} exact component={TaskSetOverview}/>
    <Route path={"/task-sets/:taskSetId/:taskId?"} component={TaskSetDetailsPageRoute}/>

    <Route path={"/availability"} component={Availability}/>

    <Route><Redirect to={"/orders"}/></Route>
  </Switch>;
};
