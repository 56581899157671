import React, {ComponentProps} from "react";
import {Button, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouseUser} from "@fortawesome/free-solid-svg-icons";

import {OdfAccessOrderServerServiceSoapTypes} from "../../shared-types/OdfAccessOrderServerService/OdfAccessOrderServerServiceSoap";

import './LocationSummary.scss';
import {useTriggerAvailabilityLookup} from "../../hooks/useTriggerAvailabilityLookup";
import {useTriggerSearchOrders} from "../../hooks/useTriggerSearchOrders";

export const LocationSummary: React.FC<{
  location: OdfAccessOrderServerServiceSoapTypes.ILocation
} & Pick<ComponentProps<typeof Tag>, "color">> = ({location, color}) => {

  const triggerLookup = useTriggerAvailabilityLookup();
  const triggerSearch = useTriggerSearchOrders();

  return <div className={'location-summary'}>

    <div className={'popunder'}>
      <Button
        title={'Perform availability checker lookup'}
        size={"small"}
        style={{margin: '5px 0', width: '100%', fontSize: '93%'}}
        onClick={() => triggerLookup({
          zipCode:              location.zipCode,
          houseNumber:          location.houseNumber.value,
          houseNumberExtension: location.houseNumber.extension,
          room:                 location.room || '',
        })}
      >
        Availability
      </Button>
      <Button
        title={'Find orders on this and similar addresses'}
        size={"small"}
        style={{width: '100%', fontSize: '93%'}}
        onClick={() => triggerSearch(`${location.zipCode} ${location.houseNumber.value}`)}
      >
        Orders
      </Button>
    </div>

    <Tag color={color}>
      <FontAwesomeIcon
        icon={faHouseUser}
        style={{marginRight: '.4rem'}}
      />
      {location.zipCode}{' '}
      {location.houseNumber.value}
      {location.houseNumber.extension}
      {location.room}
    </Tag>
  </div>
};
