import React, {useRef} from "react";
import {Input} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

export const HeaderBar: React.FC<{

  title: string,
  showSearch?: boolean,
  searchPlaceholder?: string,
  query?: string | null,
  setQuery?: (query: string | null) => void,

}> = ({title, showSearch, searchPlaceholder, query, setQuery, children}) => {

  const inputRef = useRef<any>();

  const startSearch = () => {
    setQuery!('');
    inputRef.current?.focus();
  };

  return <div style={{
    display:    'flex',
    margin:     '1rem 0 2rem',
    alignItems: 'center',
    position:   'relative',
  }}>

    <h2 style={{margin: 0}}>
      {showSearch ? <div
        style={{
          cursor:  'pointer',
          padding: 3,
          margin:  -3,
          display: 'inline-block',
        }}
        onClick={startSearch}
      >
        <FontAwesomeIcon
          icon={faSearch}
          style={{
            transform: `scale(.53) translate(-2px, 1px)`,
            opacity:   .5,
            cursor:    'pointer',
            margin:    '0 5px 0 8px',
          }}
        />
      </div> : null}
      <span style={{
        opacity:    showSearch && query !== null ? 0 : 1,
        transition: 'opacity .2s',
      }}>
        {title}
      </span>
    </h2>

    <div style={{marginLeft: 'auto'}}>
      {children}
    </div>

    {(showSearch) ? <div style={{
      position:      'absolute',
      opacity:       query !== null ? 1 : 0,
      pointerEvents: query !== null ? 'initial' : 'none',
      transition:    'opacity .2s',
      width:         'calc(100% - 200px)',
    }}>
      <Input
        ref={inputRef}
        prefix={<FontAwesomeIcon
          icon={faSearch}
          style={{
            transform: `scale(.8) translateX(-2px)`,
            opacity:   .5,
          }}
        />}
        onKeyDown={e => {
          if (e.key === "Escape") {
            setQuery!(null);
          }
        }}
        placeholder={searchPlaceholder}
        value={query!}
        onChange={event => setQuery!(event.target.value)}
      />
    </div> : null}
  </div>;
};
