import React, {CSSProperties} from "react";

const logo = require('../images/l2c-logo-vector.svg').default;

const Logo: React.FC<{
  size?: number,
  style?: CSSProperties,
}> = ({size = 40, style}) => {
  return <img
    style={{
      width: size,
      ...style,
    }}
    src={logo}
    alt={'L2-connect logo'}
  />;
};

export default Logo;
