import React from "react";
import {HeaderWithIcon} from "../parts/HeaderWithIcon";
import {faExchangeAlt} from "@fortawesome/free-solid-svg-icons";
import {gql, useQuery} from "@apollo/client";
import {soapMessageFields} from "../data/fragments";
import {QueryResult} from "../QueryResult";
import {SoapLog} from "../parts/SoapLog";
import {sortByCreatedAt} from "../../util/sort";
import {Alert} from "antd";

export const TaskSetSoapHistory: React.FC<{
  taskSetId: number;
}> = ({taskSetId}) => {

    const {loading, error, data} = useQuery(gql`query TaskSetSoapLog ($id: Int!) {
        taskSet(id: $id) {relatedSoapMessages {...SoapMessageFields}}
    }${soapMessageFields}`, {
      variables: {id: taskSetId},
    });

  return <>
    <HeaderWithIcon icon={faExchangeAlt}>Reggefiber interaction</HeaderWithIcon>
    <Alert
      message={'Because of the nature of the protocol, incoming messages affecting the TaskSet may not be shown here. '
      // 'Please refer to the mutation history on this page to get an idea of how this TaskSet and the underlying tasks ' +
      // 'have changed over time.'
      }
      style={{margin: '1rem 0 .5rem'}}
    />
    <QueryResult loading={loading} error={error}>
      {() => <SoapLog
        loading={loading}
        className={"stretch-grid"}
        messages={sortByCreatedAt(data.taskSet.relatedSoapMessages)}
      />}
    </QueryResult>
  </>;
};
