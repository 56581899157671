import React, {CSSProperties} from "react";
import {Alert} from "antd";
import ReactTimeago from "react-timeago";
import {orderErrorCodes} from "../../../shared-types/orderErrorCodes";
import {ErrorCodeExplanation} from "../ErrorCodeExplanation";
import {ApiOrderEntity} from "../../orders/types";

export const BlockingStatus: React.FC<{ order: ApiOrderEntity, style?: CSSProperties }> = ({order, style}) => {
  if (!order.blockingUpdated || order.handover) return null;
  if (!order.blockingCode) return <Alert
    type={"success"}
    showIcon={true}
    message={<strong>On track</strong>}
    description={<>Blocking status clear as of <ReactTimeago date={order.blockingUpdated}/></>}
    style={style}
  />;
  return <Alert
    type={"warning"}
    showIcon={true}
    message={<>
      <strong>{order.blockingCode}</strong> (<ReactTimeago date={order.blockingUpdated}/>)
    </>}
    description={<>
      {<ErrorCodeExplanation
        style={{display: 'block'}}>{order.blockingCode in orderErrorCodes ? (orderErrorCodes as any)[order.blockingCode] : "Unknown error code"}
      </ErrorCodeExplanation>}
      {order.blockingNote ? <>{order.blockingNote}</> : null}
    </>}
    style={style}
  />
};
