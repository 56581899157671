import React from "react";
import {gql} from "@apollo/client";

import {OrderTypeTag} from "./OrderTypeTag";
import {OrderStatusTag} from "./OrderStatusTag";
import {OrderSummary} from "./OrderSummary";
import {BlockingStatus} from "./BlockingStatus";
import {Json} from "../Json";
import {AutoSyncNotesEditor} from "../NotesEditor";
import {HandoverStatus} from "./HandoverStatus";
import {ClipboardItem} from "../ClipboardItem";
import {ApiOrderEntity} from "../../orders/types";
import {EntityCard} from "../EntityCard";

const OrderDetailNotes: React.FC<{
  orderId: number,
  initialValue: string,
  readOnly?: boolean,
}> = ({orderId, initialValue, readOnly = false}) => {

    return <AutoSyncNotesEditor
      initialValue={initialValue}
      createMutation={() => gql`mutation UpdateOrderNotes ($id: Int!, $notes: String!) {
        updateOrderNotes(id: $id, notes: $notes) { id notes }
      }`}
      getMutationVariables={notes => ({id: orderId, notes})}
      getSavedNotesFromMutationResult={data => data?.updateOrderNotes?.notes}
    />
};

export const OrderDetailsCard: React.FC<{
  orderId: number,
  order?: ApiOrderEntity
  readOnly?: boolean,
}> = ({children, orderId, order, readOnly}) => {

  let tags, summaryBlock, json, notes;

  if (order) {
    tags = <>
      <OrderTypeTag style={{marginLeft: '1rem'}} type={order.type}/>
      <OrderStatusTag style={{marginLeft: '.25rem'}} status={order.orderStatus}/>
    </>;

    summaryBlock = <div style={{marginBottom: '1rem'}}>
      <OrderSummary order={order} style={{marginBottom: '1rem'}}/>
      <p style={{marginBottom: '.2rem'}}>Created: <strong>{order.createdAt}</strong></p>
      <ClipboardItem style={{marginBottom: '.2rem'}} label={'External UID'} value={order.externalOrderUid}/>
      <ClipboardItem style={{marginBottom: '1rem'}} label={'Service UID'} value={order.serviceUid}/>
      <BlockingStatus order={order} style={{marginBottom: '1rem'}}/>
      <HandoverStatus order={order}/>
    </div>;

    json = <Json json={order.attributes} collapsed={1}/>;

    notes = <div style={{marginBottom: '1rem'}}>
      <OrderDetailNotes orderId={orderId} initialValue={order.notes} readOnly={readOnly}/>
    </div>;
  }

  return <EntityCard
    title={`Order ${orderId}`}
    afterTitle={<div>{tags}</div>}
    left={<>{children}{json}</>}
    right={<>{summaryBlock}{notes}</>}
  />;
};
