import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import {connectRouter, routerMiddleware} from 'connected-react-router'
import {createBrowserHistory} from 'history';

import {authReducer as auth} from "./authSlice";
import {formIntentReducer as formIntent} from "./formIntentSlice";

export const history = createBrowserHistory();

export const store = configureStore({
  reducer:    {
    auth,
    formIntent,
    router: connectRouter(history) as any,
  },
  middleware: [
    routerMiddleware(history),
  ],
});

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>;
