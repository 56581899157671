import {splitSchemaLocationType} from "./common";
import joi from "joi";

const schemas = splitSchemaLocationType(joi.object({
  cache:      joi.boolean(),
  lookupType: joi.required().valid('FetchPassiveConnection', 'FetchOperatorInfoPassiveConnection'),
}));

export const availabilityFormSchemaWithAddress = schemas[0];

export const availabilityFormSchemaWithLineId = schemas[1];
