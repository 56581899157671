import {gql} from "@apollo/client";

export const baseDetails = gql`
    fragment BaseDetails on AccessOrder {
        id type notes handover {code note deliveryDateTime}

        orderStatus externalOrderUid serviceUid
        blockingNote blockingCode blockingUpdated

        createdAt updatedAt
        attributes
    }
`;

export const eventFields = gql`
    fragment EventFields on AccessOrderEvent { id type createdAt origin }
`;

export const soapMessageFields = gql`
    fragment SoapMessageFields on SoapMessage {
        id direction requestBytes responseBytes summary error soapAction createdAt
    }
`;
