import React, {ComponentProps, CSSProperties} from "react";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Menu as AntMenu} from "antd";
import {push} from "connected-react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PayloadAction} from "@reduxjs/toolkit";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faCheck, faClipboardList, faExchangeAlt, faSignOutAlt, faWrench} from "@fortawesome/free-solid-svg-icons";
import {logOut} from "../../app/authSlice";

const MenuItem: React.FC<{ target: string | PayloadAction, icon: IconProp, label: string, style?: CSSProperties }> = (
  {target, icon, label, style}
) => {
  const dispatch = useDispatch();
  const action   = typeof target === 'string' ? push(target) : target;
  const key      = typeof target === 'string' ? target : action.type;
  return <AntMenu.Item
    key={key}
    onClick={() => dispatch(action)}
    icon={<FontAwesomeIcon icon={icon}/>}
    style={style}
  >
    {label}
  </AntMenu.Item>;
}

/**
 * Base stateless menu that takes in an array of the menu items to be rendered
 */
const BaseMenu: React.FC<{
  items: ComponentProps<typeof MenuItem>[],
}> = ({items}) => {
  const location = useLocation();
  return <AntMenu mode={"horizontal"} className={"main-nav"} selectedKeys={[
    location.pathname,
    ...location.pathname.startsWith("/orders") ? ["/orders"] : [],
    ...location.pathname.startsWith("/task-sets") ? ["/task-sets"] : [],
  ]}>
    {items.map(item => {
      // Invoking MenuItem as a regular function because AntMenu expects AntMenu.Item as immediate children
      return MenuItem(item);
    })}
  </AntMenu>;
}

export const Menu: React.FC = () => {
  return <BaseMenu items={[
    {label: 'Orders', icon: faClipboardList, target: "/orders"},
    {label: 'Departing customers', icon: faExchangeAlt, target: "/handoffs"},
    {label: 'Task handling', icon: faWrench, target: "/task-sets"},
    {label: 'Availability', icon: faCheck, target: "/availability"},
    {label: 'Log out', icon: faSignOutAlt, target: logOut(), style: {marginLeft: 'auto', marginRight: 0}},
  ]}/>;
}
