import React from "react";

export const BreadcrumbsBar: React.FC = ({children}) => {
  return <div style={{
    margin:       '-10px -35px 0',
    padding:      '0 35px 10px',
    borderBottom: '1px solid #f0f0f0'
  }}>
    {children}
  </div>;
};
