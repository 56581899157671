import React from "react";
import {Controller, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";

import {ControlledModalFormFooter} from "../ControlledModalFormFooter";
import {defaultValidationOptions} from "../validation";
import {deconstructionOrderSchema} from "../../../schemas/deconstructionOrder";
import {ServiceUidSlice} from "./slices/ServiceUidSlice";
import {useSubmitOrderForm} from "../../../hooks/useSubmitOrderForm";
import {FormSection} from "../FormSection";
import {equipmentEndpointDefaults, EquipmentEndpointInput} from "./controls/EquipmentEndpointInput";
import {CreateOrderFormProps} from "./common";

export const CreateDeconstructionOrderForm: React.FC<CreateOrderFormProps> = ({hide}) => {

  const {errors, formState, handleSubmit, control} = useForm({
    defaultValues: {
      serviceUid:              "",
      activeEquipmentEndpoint: equipmentEndpointDefaults,
    },
    resolver:      joiResolver(deconstructionOrderSchema, defaultValidationOptions),
  });

  const onSubmit = handleSubmit(
    useSubmitOrderForm('DeconstructionOrder', 'deconstruction')
  );

  return <form onSubmit={onSubmit}>
    <FormSection>
      <ServiceUidSlice errors={errors} control={control}/>
    </FormSection>
    <FormSection>
      <Controller
        control={control}
        name={'activeEquipmentEndpoint'}
        render={props => <EquipmentEndpointInput errors={errors} {...props}/>}
      />
    </FormSection>
    <ControlledModalFormFooter hide={hide} submitting={formState.isSubmitting}/>
  </form>
};
