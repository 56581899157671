/**
 * Remove nesting and non-strings from the object by casting all top-level values to string
 * @param obj
 * @param keys If given, only applies the conversion to values of the given keys
 */
export const stringifyValues = (obj: any, keys?: string[]): any => {
  return Object.keys(obj).reduce((carry, key) => {
    const value         = obj[key];
    const shouldConvert = !keys || keys.includes(key);
    const converted     = shouldConvert ? typeof value === 'string' ? value : JSON.stringify(value) : value;
    return {...carry, [key]: converted};
  }, {});
}

/**
 * Uppercase the keys in the given object
 */
export function keysToUpper<T extends object = object>(obj: T): T {
  return Object.keys(obj).reduce((result: any, key) => {
    result[key.toUpperCase()] = (obj as any)[key];
    return result;
  }, {} as Partial<T>) as T;
}

/**
 * Create a string "list" of all the keys in the given object line-by-line
 * @param obj
 */
export const listValues = (obj: any): string => {
  return Object.keys(obj).reduce((lines, key) => {
    return [
      ...lines,
      `${key}: ${obj[key]}`,
    ];
  }, [] as string[]).join("\n");
}
