import React from "react";
import {Alert} from "antd";

export const ModalActionHint: React.FC<{
  title: string;
}> = ({title, children}) => {
  return <Alert
    style={{marginBottom: '.75rem'}}
    message={<strong>{title}</strong>}
    description={children}
    showIcon={true}
  />;
};
