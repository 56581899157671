import {gql, useMutation} from "@apollo/client";

export function useCreateOrder(inputType: string, orderType: string) {

    const [run] = useMutation(gql`mutation createOrder($parameters: ${inputType}!, $handle: Boolean) {
        createOrder(data: {
            type: ${orderType},
            handle: $handle,
            ${orderType}Parameters: $parameters,
        }) { id }
    }`, {
      refetchQueries: ['orderOverview'],
    });

  return run;
}
