import React from "react";

import {ModalStateProps} from "../../modals/common";
import {Modal} from "antd";
import {ConnectedAttributeWorkspace} from "./AttributeWorkspace";

export const AttributeModal: React.FC<ModalStateProps & {
  taskId: number;
  hide: () => void;
}> = ({taskId, visible, hide}) => {
  return <Modal
    title={'Attributes'}
    closable={false}
    visible={visible}
    width={720}
    style={{
      width: 720,
    }}
    bodyStyle={{
      width: 720
    }}
    footer={null}
  >
    <ConnectedAttributeWorkspace
      taskId={taskId}
      hide={hide}
    />
  </Modal>
};
