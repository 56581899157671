import React from "react";
import {gql, useQuery} from "@apollo/client";
import {Alert, Col, Row, Skeleton} from "antd";
import {XmlViewer} from "./XmlViewer";

export const ErrorTrace: React.FC<{ error: string }> = ({error}) => {
  return <>
    {(error)
      .replace(/\\t/g, '. . ')
      .split("\\n")
      .map((line: string, i: number) => <pre className={'error-trace-line'} key={i}>{line}</pre>)}
  </>;
};

export const SoapMessageDetails: React.FC<{ id: number }> = ({id}) => {

    const {loading, data} = useQuery(gql`query($id: Int!) {
        soapMessage(id: $id) {
            response request direction error
        }
    }`, {
      variables: {id},
    });

  if (loading || !data) return <Skeleton/>;

  return <>
    {data.soapMessage.error ? (
      <Alert
        message={'This message was not processed properly because of the following error:'}
        description={<ErrorTrace error={data.soapMessage.error}/>}
        type={"error"}
        showIcon={true}
        style={{margin: '.5rem 0 1rem'}}
      />
    ) : null}
    <Row gutter={16}>
      <Col xs={24} md={12}>
        <h4>Request ({data.soapMessage.direction.toLowerCase()})</h4>
        <XmlViewer xml={data.soapMessage.request}/>
      </Col>
      <Col xs={24} md={12}>
        <h4>Response</h4>
        <XmlViewer xml={data.soapMessage.response}/>
      </Col>
    </Row></>;
};
