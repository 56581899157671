"use strict";
exports.__esModule = true;
exports.bpCodes = void 0;
exports.bpCodes = [
    {
        "type": "Hold code",
        "code": 101,
        "description": "Geen klant contact – KIB/Brief",
        "hint": "Minstens 3 contactmomenten in max. 5 werkdagen en verzending  KIB loggen in Bouwportaal-logboek",
        "eligibleTypes": [
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "Aannemer"
    },
    {
        "type": "Hold code",
        "code": 102,
        "description": "Klant niet thuis op afspraakdatum",
        "hint": "KIB loggen in Bouwportaal-logboek",
        "eligibleTypes": [
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "Aannemer"
    },
    {
        "type": "Hold code",
        "code": 103,
        "description": "Klant wil diensten niet",
        "hint": "In opmerkingenveld aangeven wat de reden hiervan is (indien bekend)",
        "eligibleTypes": [
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "Actieve Operator"
    },
    {
        "type": "Hold code",
        "code": 104,
        "description": "Wacht op klant nieuwbouw/verbouw  woning",
        "hint": "Aangeven in opmerkingenveld wanneer klant beschikbaar denkt te zijn",
        "eligibleTypes": [
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "Aannemer"
    },
    {
        "type": "Hold code",
        "code": 105,
        "description": "Wacht op klant - vakantie",
        "hint": "Aangeven in opmerkingenveld wanneer klant beschikbaar denkt te zijn",
        "eligibleTypes": [
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "Aannemer"
    },
    {
        "type": "Hold code",
        "code": 106,
        "description": "Wacht op klant overig",
        "hint": "Aangeven in opmerkingenveld wanneer klant beschikbaar denkt te zijn",
        "eligibleTypes": [
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "Aannemer"
    },
    {
        "type": "Hold code",
        "code": 107,
        "description": "Onaangekondigd bezoek - klant niet thuis (KIB)",
        "hint": "KIB loggen in Bouwportaal-logboek",
        "eligibleTypes": [
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY"
        ],
        "party": "Aannemer"
    },
    {
        "type": "Hold code",
        "code": 108,
        "description": "Geen klant contact – Na 2 belpogingen en SMS",
        "hint": "Minstens 3 contactmomenten in max. 5 werkdagen en verzending  SMS loggen in Bouwportaal-logboek",
        "eligibleTypes": [
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "Aannemer"
    },
    {
        "type": "Hold code",
        "code": 201,
        "description": "Toestemming – Nog geen toestemming VVE",
        "hint": "Aangeven wie hier actiehouder van is en verwachte datum van toestemming invullen in opmerkingenveld",
        "eligibleTypes": [
            "CONSTRUCT",
            "CUSTOMER_INCIDENT",
            "NETWORK_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "Aannemer"
    },
    {
        "type": "Hold code",
        "code": 202,
        "description": "Toestemming – Nog geen toestemming gemeente",
        "hint": "Datum van de vergunningaanvraag door aannemer invullen in opmerkingenveld",
        "eligibleTypes": [
            "CONSTRUCT",
            "CUSTOMER_INCIDENT",
            "NETWORK_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "Aannemer"
    },
    {
        "type": "Hold code",
        "code": 203,
        "description": "Vergunningverzoek afgewezen",
        "hint": "In opmerkingenveld melden waarom en door wie vergunningsverzoek is afgewezen",
        "eligibleTypes": [
            "CONSTRUCT",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "KPN Netwerk NL"
    },
    {
        "type": "Hold code",
        "code": 204,
        "description": "Technisch beletsel – Hoogbouw/onderburen",
        "hint": "Aangeven welke buren (huisnummer) het betreft en of er actie is uitgezet om buren te benaderen",
        "eligibleTypes": [
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "Aannemer"
    },
    {
        "type": "Hold code",
        "code": 205,
        "description": "Technisch beletsel - overig",
        "hint": "Reden in opmerkingenveld",
        "eligibleTypes": [
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "KPN netwerk NL + Aannemer"
    },
    {
        "type": "Hold code",
        "code": 206,
        "description": "Offerte (hoogbouw/meerwerk)",
        "hint": "Na het versturen van offerte naar KPN Netwerk NL mag de order op HOLD (voortgang blijven vermelden in logboek)",
        "eligibleTypes": [
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "KPN Netwerk NL"
    },
    {
        "type": "Hold code",
        "code": 207,
        "description": "Tracé onvindbaar",
        "hint": "In opmerkingveld aangeven wat er moet gebeuren",
        "eligibleTypes": [
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY"
        ],
        "party": "KPN netwerk NL + Aannemer"
    },
    {
        "type": "Hold code",
        "code": 208,
        "description": "PoP / (I)DP niet toegankelijk",
        "hint": "In opmerkingveld aangeven wat er moet gebeuren",
        "eligibleTypes": [
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY"
        ],
        "party": "POP toegang + Aannemer"
    },
    {
        "type": "Hold code",
        "code": 209,
        "description": "Geen Cocongegevens",
        "hint": "In opmerkingveld aangeven wat er moet gebeuren",
        "eligibleTypes": [
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY"
        ],
        "party": "KPN netwerk NL + Aannemer"
    },
    {
        "type": "Hold code",
        "code": 210,
        "description": "Vrijschakeling aangevraagd – klant niet tijdelijk in dienst",
        "hint": "In opmerkingveld aangeven wat er moet gebeuren",
        "eligibleTypes": [
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY"
        ],
        "party": "SQC + Aannemer"
    },
    {
        "type": "Hold code",
        "code": 211,
        "description": "Vrijschakeling aangevraagd – klant wel tijdelijk in dienst",
        "hint": "In opmerkingveld aangeven wat er moet gebeuren",
        "eligibleTypes": [
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY"
        ],
        "party": "SQC + Aannemer"
    },
    {
        "type": "Hold code",
        "code": 212,
        "description": "Offerte (meerwerk Assurance)",
        "hint": "Na het versturen van offerte naar KPN Netwerk NL mag de order op HOLD (voortgang blijven vermelden in logboek)",
        "eligibleTypes": [
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY"
        ],
        "party": "SLM + Aannemer"
    },
    {
        "type": "Hold code",
        "code": 250,
        "description": "Offerte niet mogelijk",
        "hint": "In opmerkingenveld melden waarom er geen offerte uitgebracht kan worden",
        "eligibleTypes": [
            "ZPI_ERQ",
            "MPI_ERQ"
        ],
        "party": "KPN Netwerk NL"
    },
    {
        "type": "Hold code",
        "code": 251,
        "description": "Nadere informatie nodig",
        "hint": "In opmerkingenveld melden waarom er nadere informatie nodig is en welke informatie dat dan is",
        "eligibleTypes": [
            "ZPI_ERQ",
            "MPI_ERQ"
        ],
        "party": "KPN Netwerk NL"
    },
    {
        "type": "Hold code",
        "code": 270,
        "description": "Ter akkoord aangeboden",
        "hint": "In opmerkingenveld kan vermeld worden dat het ter akoord aangeboden is",
        "eligibleTypes": [
            "ZPI_ERQ",
            "MPI_ERQ"
        ],
        "party": "KPN Netwerk NL"
    },
    {
        "type": "Hold code",
        "code": 301,
        "description": "Actieve poort niet gevonden – Actieve poort bestaat niet",
        "hint": "In opmerkingenveld aangeven in welk bouwblok deze wel zit (indien bekend).",
        "eligibleTypes": [
            "PATCH_INSTALL",
            "PATCH_MIGRATE",
            "RELOCATE"
        ],
        "party": "KPN Netwerk NL"
    },
    {
        "type": "Hold code",
        "code": 302,
        "description": "ODF poort niet gevonden – ODF poort bestaat niet",
        "hint": "In opmerkingenveld aangeven in welk bouwblok deze wel zit (indien bekend).",
        "eligibleTypes": [
            "PATCH_INSTALL",
            "PATCH_REMOVE",
            "PATCH_MIGRATE",
            "RELOCATE"
        ],
        "party": "KPN Netwerk NL"
    },
    {
        "type": "Hold code",
        "code": 303,
        "description": "Actieve poort reeds in gebruik (bezet)",
        "hint": "In opmerkingenveld aangeven met welke ODF-poort de actieve poort bezet is.",
        "eligibleTypes": [
            "PATCH_INSTALL",
            "PATCH_MIGRATE",
            "RELOCATE"
        ],
        "party": "KPN Netwerk NL"
    },
    {
        "type": "Hold code",
        "code": 304,
        "description": "ODF poort reeds in gebruik  (bezet)",
        "hint": "In opmerkingenveld aangeven met welke actieve poort de ODF-poort bezet is.",
        "eligibleTypes": [
            "PATCH_INSTALL"
        ],
        "party": "KPN Netwerk NL"
    },
    {
        "type": "Hold code",
        "code": 305,
        "description": "Actieve poort defect (zichtbaar defect)",
        "hint": "In opmerkingenveld aangeven wat er is geconstateerd.",
        "eligibleTypes": [
            "PATCH_INSTALL",
            "PATCH_MIGRATE",
            "RELOCATE"
        ],
        "party": "Actieve Operator"
    },
    {
        "type": "Hold code",
        "code": 306,
        "description": "ODF poort defect (zichtbaar defect)",
        "hint": "In opmerkingenveld aangeven wat er is geconstateerd.",
        "eligibleTypes": [
            "PATCH_INSTALL"
        ],
        "party": "KPN Netwerk NL"
    },
    {
        "type": "Hold code",
        "code": 307,
        "description": "Geen licht (vanaf klant) gemeten in ODF lade",
        "hint": "Vezel getest vanaf klantlocatie naar POP (min. a.d.h.v. lichtpen)",
        "eligibleTypes": [
            "PATCH_INSTALL",
            "PATCH_MIGRATE",
            "RELOCATE"
        ],
        "party": "KPN Netwerk NL"
    },
    {
        "type": "Hold code",
        "code": 401,
        "description": "Administratief beletsel",
        "hint": "bijvoorbeeld Telefoonnummer klant is onjuist, invullen in opmerkingen veld",
        "eligibleTypes": [
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "KPN Netwerk NL"
    },
    {
        "type": "Hold code",
        "code": 402,
        "description": "Contactgegevens onjuist/ontbreken (KIB)",
        "hint": "Datum KIB vermelden in opmerkingenveld",
        "eligibleTypes": [
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "Actieve Operator"
    },
    {
        "type": "Hold code",
        "code": 410,
        "description": "Geen NT voorraad van AO ontvangen",
        "hint": "dit geldt alleen voor eigen (spare) voorraad van aannemer (niet voor DHZ pakket)",
        "eligibleTypes": [
            "NT_INSTALL"
        ],
        "party": "Actieve Operator"
    },
    {
        "type": "Hold code",
        "code": 411,
        "description": "In opdracht van AO gecancelled",
        "hint": "In opmerkingenveld aangeven wat hiervan de aanleiding is (wie/wat)",
        "eligibleTypes": [
            "PATCH_INSTALL",
            "PATCH_REMOVE",
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "Actieve Operator"
    },
    {
        "type": "Hold code",
        "code": 413,
        "description": "Dubbele order",
        "hint": "Er is op dit adres al een openstaande order van het zelfde ordertype",
        "eligibleTypes": [
            "NT_INSTALL"
        ],
        "party": "Actieve Operator"
    },
    {
        "type": "Hold code",
        "code": 414,
        "description": "Dubbel incidentticket",
        "hint": "Er is op dit adres al een openstaand ticket van hetzelfde type incident",
        "eligibleTypes": [
            "CUSTOMER_INCIDENT",
            "NETWORK_INCIDENT",
            "CUSTOMER_PROBLEM",
            "NETWORK_MAINTENANCE",
            "RESEARCH",
            "RECOVERY"
        ],
        "party": "Actieve Operator"
    },
    {
        "type": "Hold code",
        "code": 901,
        "description": "Overig uitstel/afstel",
        "hint": "De reden komt niet voor in de lijst van voorgedefinieerde lijst (invullen in opmerkingveld).",
        "eligibleTypes": [
            "PATCH_INSTALL",
            "PATCH_REMOVE",
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "CUSTOMER_INCIDENT",
            "NETWORK_INCIDENT",
            "CUSTOMER_PROBLEM",
            "NETWORK_MAINTENANCE",
            "RESEARCH",
            "RECOVERY",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB",
            "ZPI_ERQ",
            "MPI_ERQ"
        ],
        "party": "KPN Netwerk NL"
    },
    {
        "type": "Hold code",
        "code": 902,
        "description": "Beletsel door natuurinvloeden (Bijvoorbeeld vorst)",
        "hint": "Met reden invullen in opmerkingenveld",
        "eligibleTypes": [
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "CUSTOMER_INCIDENT",
            "NETWORK_INCIDENT",
            "CUSTOMER_PROBLEM",
            "NETWORK_MAINTENANCE",
            "RESEARCH",
            "RECOVERY",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "Aannemer"
    },
    {
        "type": "Cancel Accept",
        "code": 500,
        "description": "Op tijd geannuleerd, geen werk gedaan",
        "hint": "Alleen bij NT taak toegestaan",
        "eligibleTypes": [
            "PATCH_INSTALL",
            "PATCH_REMOVE",
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB",
            "ZPI_ERQ",
            "MPI_ERQ"
        ],
        "party": "-"
    },
    {
        "type": "Cancel Accept",
        "code": 501,
        "description": "NT reeds geplaatst",
        "hint": "Alleen bij NT taak toegestaan",
        "eligibleTypes": [
            "NT_INSTALL"
        ],
        "party": "-"
    },
    {
        "type": "Cancel Accept",
        "code": 502,
        "description": "Vezels geblazen of graafwerkzaamheden uitgevoerd",
        "hint": "Alleen bij Construct taak toegestaan, de aannemer moet Cocon en de BC bijwerken.",
        "eligibleTypes": [
            "CONSTRUCT"
        ],
        "party": "Aannemer"
    },
    {
        "type": "Cancel Accept",
        "code": 599,
        "description": "Aannemer heeft cancel gezien",
        "hint": "Gebruiken als aannemer maar 1 mogelijkheid heeft: aangeven dat hij de cancel heeft gezien.",
        "eligibleTypes": [
            "PATCH_INSTALL",
            "PATCH_REMOVE",
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "CUSTOMER_INCIDENT",
            "NETWORK_INCIDENT",
            "CUSTOMER_PROBLEM",
            "NETWORK_MAINTENANCE",
            "RESEARCH",
            "RECOVERY",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB",
            "ZPI_ERQ",
            "MPI_ERQ"
        ],
        "party": "Aannemer"
    },
    {
        "type": "Close code",
        "code": 0,
        "description": "oplevering is succesvol  geweest",
        "hint": "",
        "eligibleTypes": [
            "PATCH_INSTALL",
            "PATCH_REMOVE",
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB",
            "ZPI_ERQ",
            "MPI_ERQ",
            "MA_NL_REQUEST"
        ],
        "party": "-"
    },
    {
        "type": "Close code",
        "code": 50,
        "description": "NT geplaatst, geen licht door vezel",
        "hint": "Gemeld bij Actieve Operator (+naam callcenter agent): Alleen met NT",
        "eligibleTypes": [
            "NT_INSTALL"
        ],
        "party": "Actieve Operator"
    },
    {
        "type": "Close code",
        "code": 51,
        "description": "NT wel geplaatst, wel licht, geen opleversignaal",
        "hint": "Gemeld bij Actieve Operator (+naam callcenter agent): Alleen met NT",
        "eligibleTypes": [
            "NT_INSTALL"
        ],
        "party": "Actieve Operator"
    },
    {
        "type": "Close code",
        "code": 52,
        "description": "Geen 230V wandcontactdoos aanwezig in de meterkast",
        "hint": "Alleen bij NT-type: KPN-1NTCUF of KPN-GNTCUF",
        "eligibleTypes": [
            "NT_INSTALL"
        ],
        "party": "Actieve Operator"
    },
    {
        "type": "Close code",
        "code": 53,
        "description": "Aansluiten modem niet mogelijk vanwege complex kabeltracé",
        "hint": "Alleen bij NT-type: KPN-1NTCUF of KPN-GNTCUF",
        "eligibleTypes": [
            "NT_INSTALL"
        ],
        "party": "Actieve Operator"
    },
    {
        "type": "Close code",
        "code": 54,
        "description": "Klant sluit modem zelf aan",
        "hint": "Alleen bij NT-type: KPN-1NTCUF of KPN-GNTCUF",
        "eligibleTypes": [
            "NT_INSTALL"
        ],
        "party": "Actieve Operator"
    },
    {
        "type": "Close code",
        "code": 55,
        "description": "Omsteken niet succesvol, klant heeft bij terugsteken naar koper gee",
        "hint": "Alleen bij NT-type: KPN-1NTCUF of KPN-GNTCUF",
        "eligibleTypes": [
            "NT_INSTALL"
        ],
        "party": "Aannemer"
    },
    {
        "type": "Close code",
        "code": 60,
        "description": "Issue opgelost.",
        "hint": "In opmerkingveld aangeven wat er moet gebeuren",
        "eligibleTypes": [
            "CUSTOMER_INCIDENT",
            "NETWORK_INCIDENT",
            "CUSTOMER_PROBLEM",
            "NETWORK_MAINTENANCE",
            "RESEARCH",
            "RECOVERY"
        ],
        "party": "-"
    },
    {
        "type": "Close code",
        "code": 61,
        "description": "Issue opgelost, vervolg actie noodzakelijk door RF",
        "hint": "In opmerkingveld aangeven wat er moet gebeuren",
        "eligibleTypes": [
            "CUSTOMER_INCIDENT",
            "NETWORK_INCIDENT",
            "CUSTOMER_PROBLEM",
            "NETWORK_MAINTENANCE",
            "RESEARCH",
            "RECOVERY"
        ],
        "party": "KPN Netwerk NL"
    },
    {
        "type": "Close code",
        "code": 62,
        "description": "Issue opgelost, vervolg actie noodzakelijk door AO",
        "hint": "In opmerkingveld aangeven wat er moet gebeuren",
        "eligibleTypes": [
            "CUSTOMER_INCIDENT",
            "NETWORK_INCIDENT",
            "CUSTOMER_PROBLEM"
        ],
        "party": "Actieve Operator"
    },
    {
        "type": "Close code",
        "code": 65,
        "description": "Issue tijdelijk opgelost.",
        "hint": "In opmerkingveld aangeven wat er moet gebeuren",
        "eligibleTypes": [
            "CUSTOMER_INCIDENT",
            "NETWORK_INCIDENT",
            "CUSTOMER_PROBLEM",
            "NETWORK_MAINTENANCE",
            "RESEARCH",
            "RECOVERY"
        ],
        "party": "-"
    },
    {
        "type": "Close code",
        "code": 66,
        "description": "Issue tijdelijk opgelost, vervolg actie noodzakelijk door RF",
        "hint": "In opmerkingveld aangeven wat er moet gebeuren",
        "eligibleTypes": [
            "CUSTOMER_INCIDENT",
            "NETWORK_INCIDENT",
            "CUSTOMER_PROBLEM",
            "NETWORK_MAINTENANCE",
            "RESEARCH",
            "RECOVERY"
        ],
        "party": "KPN Netwerk NL"
    },
    {
        "type": "Close code",
        "code": 67,
        "description": "Issue tijdelijk opgelost, vervolg actie noodzakelijk door AO",
        "hint": "In opmerkingveld aangeven wat er moet gebeuren",
        "eligibleTypes": [
            "CUSTOMER_INCIDENT",
            "NETWORK_INCIDENT",
            "CUSTOMER_PROBLEM"
        ],
        "party": "Actieve Operator"
    },
    {
        "type": "Close code",
        "code": 70,
        "description": "Issue niet opgelost, vervolg actie noodzakelijk door RF",
        "hint": "In opmerkingveld aangeven wat er moet gebeuren",
        "eligibleTypes": [
            "CUSTOMER_INCIDENT",
            "NETWORK_INCIDENT",
            "CUSTOMER_PROBLEM",
            "NETWORK_MAINTENANCE",
            "RESEARCH",
            "RECOVERY"
        ],
        "party": "KPN Netwerk NL"
    },
    {
        "type": "Close code",
        "code": 71,
        "description": "Issue niet opgelost, vervolg actie noodzakelijk door AO",
        "hint": "In opmerkingveld aangeven wat er moet gebeuren",
        "eligibleTypes": [
            "CUSTOMER_INCIDENT",
            "NETWORK_INCIDENT",
            "CUSTOMER_PROBLEM"
        ],
        "party": "Actieve Operator"
    },
    {
        "type": "Resume code",
        "code": 799,
        "description": "Overige",
        "hint": "",
        "eligibleTypes": [
            "PATCH_INSTALL",
            "PATCH_REMOVE",
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "CUSTOMER_INCIDENT",
            "NETWORK_INCIDENT",
            "CUSTOMER_PROBLEM",
            "NETWORK_MAINTENANCE",
            "RESEARCH",
            "RECOVERY",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB",
            "ZPI_ERQ",
            "MPI_ERQ"
        ],
        "party": "-"
    },
    {
        "type": "(Re)Plan code",
        "code": 800,
        "description": "Eerste plandatum",
        "hint": "Invullen bij de initiële planning (1e plandatum)",
        "eligibleTypes": [
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "CUSTOMER_INCIDENT",
            "NETWORK_INCIDENT",
            "CUSTOMER_PROBLEM",
            "NETWORK_MAINTENANCE",
            "RESEARCH",
            "RECOVERY",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "-"
    },
    {
        "type": "(Re)Plan code",
        "code": 801,
        "description": "Herplannen op aannemer verzoek",
        "hint": "Invullen als de aannemer de initiator van de herplanning is",
        "eligibleTypes": [
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "CUSTOMER_INCIDENT",
            "NETWORK_INCIDENT",
            "CUSTOMER_PROBLEM",
            "NETWORK_MAINTENANCE",
            "RESEARCH",
            "RECOVERY",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "-"
    },
    {
        "type": "(Re)Plan code",
        "code": 802,
        "description": "Herplannen op klantverzoek",
        "hint": "Invullen als de klant de initiator van de herplanning is",
        "eligibleTypes": [
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "CUSTOMER_INCIDENT",
            "CUSTOMER_PROBLEM",
            "RESEARCH",
            "RECOVERY",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "-"
    },
    {
        "type": "(Re)Plan code",
        "code": 803,
        "description": "Herplannen vanwege natuursinvloeden",
        "hint": "Invullen als herpland moet worden vanwege vorst",
        "eligibleTypes": [
            "CONSTRUCT",
            "FTU_RECONSTRUCT",
            "PATCH_MIGRATE",
            "NT_INSTALL",
            "RELOCATE",
            "DECONSTRUCT",
            "AANLUITING_HBO",
            "CONSTRUCT_NB"
        ],
        "party": "-"
    }
];
