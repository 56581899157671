import React from "react";
import {gql, useQuery} from "@apollo/client";

import {baseOptionsGqlGrid} from "../../constants";
import {sortByCreatedAt} from "../../util/sort";
import {QueryResult} from "../QueryResult";
import {ColumnsType} from "antd/es/table";
import {OrderSummary} from "../parts/orders/OrderSummary";
import {OrderTypeTag} from "../parts/orders/OrderTypeTag";
import {OrderStatusTag} from "../parts/orders/OrderStatusTag";
import {ClickableDate} from "../parts/ClickableDate";
import {ApiOrderEntity} from "./types";
import {Table} from "antd";
import {useOnRowNavigate} from "../../util/useOnRowNavigate";

const getOrderUrl = (orderId: number) => `/orders/${orderId}`;

const orderGridColumns: ColumnsType<ApiOrderEntity> = [{
  key:       'externalOrderUid',
  dataIndex: 'externalOrderUid',
  title:     'UID',
  render:    uid => <code>{uid}</code>,
}, {
  key:    'summary',
  render: (_, row) => <OrderSummary order={row}/>,
  title:  'Summary',
}, {
  key:       'type',
  dataIndex: 'type',
  title:     'Type',
  render:    type => <OrderTypeTag type={type}/>,
}, {
  key:       'orderStatus',
  dataIndex: 'orderStatus',
  title:     'Status',
  render:    status => <OrderStatusTag status={status}/>,
}, {
  key:       'createdAt',
  dataIndex: 'createdAt',
  title:     'Created',
  render:    (date: string, row) => <ClickableDate path={`/orders/${row.id}`} date={date}/>
}];

export const OrderGrid: React.FC<{

  addressPartial?: string
  serviceUidPartial?: string,

}> = React.memo(({addressPartial, serviceUidPartial}) => {

  const onRowNavigate = useOnRowNavigate<ApiOrderEntity>(order => getOrderUrl(order.id));

    const {loading, error, data} = useQuery(gql`
        query orderOverview($addressPartial: String, $serviceUidPartial: String) {
            accessOrders(addressPartial: $addressPartial, serviceUidPartial: $serviceUidPartial) {
                id type attributes externalOrderUid orderStatus createdAt
            }
        }
    `, {
      ...baseOptionsGqlGrid,
      variables: {addressPartial, serviceUidPartial},
    });

  return <QueryResult
    loading={loading}
    error={error}
  >{() => <Table<ApiOrderEntity>
    className={"stretch-grid"}
    loading={loading}
    rowClassName={"clickable-row"}
    size={"middle"}
    onRow={onRowNavigate}
    rowKey={row => row.id}
    columns={orderGridColumns}
    dataSource={data ? sortByCreatedAt(data.accessOrders) : undefined}
    pagination={{defaultPageSize: 25}}
  />}</QueryResult>;
});
