import React, {ComponentProps, CSSProperties, ReactNode} from "react";
import {Select} from "antd";
import {Controller} from "react-hook-form";

export const ControlledSelect: React.FC<{
  name: string,
  control: ComponentProps<typeof Controller>['control'],
  style?: CSSProperties,
  placeholder?: string,
  options: string[],
  renderOption?: (option: string) => ReactNode,
}> = ({name, control, style, placeholder, options, renderOption}) => {
  return <Controller
    name={name}
    control={control}
    render={props => <Select
      {...props}
      placeholder={placeholder}
      style={{width: '100%', marginBottom: '.75rem', ...style}}
    >
      {options.map(value => (
        <Select.Option key={value} value={value}>
          {renderOption?.(value) || value}
        </Select.Option>
      ))}
    </Select>}
  />;
};
