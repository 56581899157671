import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef} from "react";

import {clearFormIntent, selectFormIntent} from "../app/formIntentSlice";

/**
 * Return the form intent state values for the given form.
 * If the form identifier in the state matches, the values are returned
 * while the state is cleared (returned values will remain populated).
 * If the form does not match, an empty object is returned
 * @param form
 */
export function useDefaultsFromFormIntent(form: string): object {

  const dispatch = useDispatch();

  const values = useRef(
    useSelector(selectFormIntent(form))
  );

  useEffect(() => {
    if (values !== null) {
      dispatch(clearFormIntent());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return values.current || {};
}
