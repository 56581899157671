import React from "react";
import {NavLink, useParams} from "react-router-dom";
import {Breadcrumb} from "antd";

import {BreadcrumbsBar} from "../parts/BreadcrumbsBar";
import {EntityCard} from "../parts/EntityCard";
import {gql, useQuery} from "@apollo/client";
import {Json} from "../parts/Json";
import {ApiTaskSetEntity} from "./types";
import {QueryResult} from "../QueryResult";
import {TaskStatusTag} from "./TaskStatusTag";
import {TaskSetNotes} from "./TaskSetNotes";
import {SubTaskList} from "./SubTaskList";
import {ClipboardItem} from "../parts/ClipboardItem";
import {TaskSetActions} from "./TaskSetActions";
import {TaskSetSoapHistory} from "./TaskSetSoapHistory";
import {TaskSetSyncButton} from "./TaskSetSyncButton";

export const TaskSetCard: React.FC<{
  taskSet: ApiTaskSetEntity;
}> = ({taskSet}) => {
  return <EntityCard
    title={`TaskSet ${taskSet.id}`}
    afterTitle={<div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
      <TaskStatusTag style={{marginLeft: '1rem'}} status={taskSet.status}/>
      <TaskSetSyncButton taskSetId={taskSet.id} style={{margin: '-5px 0 -5px auto'}}/>
    </div>}
    left={<>
      <TaskSetActions taskSet={taskSet} style={{marginBottom: '1rem'}}/>
      <Json json={taskSet.attributes} collapsed={0}/>
    </>}
    right={<>
      <p style={{marginBottom: '.2rem'}}>Created: <strong>{taskSet.createdAt}</strong></p>
      <p style={{marginBottom: '.2rem'}}>Updated: <strong>{taskSet.updatedAt}</strong></p>
      <ClipboardItem style={{marginBottom: 'calc(1rem - 1px)'}} label={'External UID'} value={taskSet.uid}/>
      <TaskSetNotes taskSetId={taskSet.id}/>
    </>}
  />;
};

export const TaskSetDetails: React.FC<{
  taskSetId: number;
}> = React.memo(({taskSetId}) => {

    const {loading, error, data} = useQuery(gql`query TaskSetDetails ($taskSetId: Int!) {
        taskSet(id: $taskSetId) {
            id uid status attributes createdAt updatedAt
            tasks { status }
        }
    }`, {
      variables: {taskSetId},
    });

  return <>
    <BreadcrumbsBar>
      <Breadcrumb>
        <Breadcrumb.Item><NavLink to={"/task-sets"}>Task handling</NavLink></Breadcrumb.Item>
        <Breadcrumb.Item>{taskSetId}</Breadcrumb.Item>
      </Breadcrumb>
    </BreadcrumbsBar>
    <QueryResult loading={loading} error={error}>
      {() => data?.taskSet ? <>
        <TaskSetCard taskSet={data.taskSet}/>
        <SubTaskList taskSetId={taskSetId}/>
        <TaskSetSoapHistory taskSetId={taskSetId}/>
      </> : null}
    </QueryResult>
  </>;
});

export const TaskSetDetailsPageRoute: React.FC = () => {
  const {taskSetId} = useParams<{ taskSetId: string }>();
  return <TaskSetDetails taskSetId={parseInt(taskSetId)}/>;
};
