import React from "react";
import {Modal} from "antd";

import {ModalStateProps} from "./common";
import {RescheduleOrderForm} from "../forms/RescheduleOrderForm";
import {ControlledModalFormFooter} from "../forms/ControlledModalFormFooter";
import {ModalActionHint} from "./ModalActionHint";

export const RescheduleOrderModal: React.FC<ModalStateProps & { orderId: number }> = ({visible, hide, orderId}) => {
  return <Modal
    title={'Change / reschedule order'}
    visible={visible}
    footer={null}
    closable={false}
  >
    <RescheduleOrderForm
      orderId={orderId}
      hide={hide}
      renderFooter={submitting => <>
        <ModalActionHint title={'Rescheduling an order'}>
          A rescheduling request will be issued through the Reggefiber interface. The network
          administrator will approve/deny asynchronously - the order status will be set
          to <code>RESCHEDULING_PENDING</code>
          {' '}in the meantime.
        </ModalActionHint>
        <ControlledModalFormFooter
          hide={hide}
          submitting={submitting}
        />
      </>}
    />
  </Modal>;
};
