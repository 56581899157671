import React, {CSSProperties} from "react";
import {ValidationErrorItem} from "joi";
import {Alert} from "antd";

export const ValidationErrors: React.FC<{
  errors: ValidationErrorItem[],
  style?: CSSProperties,
}> = ({errors, style}) => {
  return <Alert
    type={"error"}
    style={style}
    className={"validation-errors-alert"}
    message={errors.map(({message, type}: ValidationErrorItem) => {
      return <p key={`${type}.${message}`}>
        {message}</p>;
    })}
  />
};

export const ErrorsByFields: React.FC<{ errors?: Record<string, string> | any, fields: 'all' | string[] }> = (
  {errors, fields}
) => {
  const errorsToShow = Object.keys(errors || {})
    .filter(errorKey => fields === 'all' || fields.includes(errorKey))
    .map(errorKey => (errors as any)[errorKey]) as any;
  return errorsToShow.length ? <ValidationErrors style={{marginBottom: '.75rem'}} errors={errorsToShow}/> : null;
};
