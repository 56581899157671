import React, {ReactNode} from "react";
import {useForm} from "react-hook-form";
import {joiResolver} from '@hookform/resolvers/joi';
import {cancelOrderSchema} from "../../schemas/orderCancel";
import {defaultValidationOptions} from "./validation";
import {ErrorsByFields} from "./ValidationErrors";
import {gql, useMutation} from "@apollo/client";
import {ControlledSelect} from "./ControlledSelect";
import {NoteInput} from "./NoteInput";
import {ErrorCodeExplanation} from "../parts/ErrorCodeExplanation";
import {useHandleOrderMutationResult} from "../../hooks/useHandleOrderMutationResult";
import {cancellationCodes} from "../../schemas/common";

export const CancelOrderForm: React.FC<{
  orderId: number,
  hide?: () => void,
  renderFooter: (submitting: boolean) => ReactNode,
}> = ({orderId, hide, renderFooter}) => {

    const [run] = useMutation(gql`mutation cancelOrder($parameters: CancelOrder!) {
        cancelOrder(data: $parameters) { id }
    }`, {
      refetchQueries: ['orderOverview', 'orderDetails'],
    });

  const {errors, formState, handleSubmit, control} = useForm({
    defaultValues: {
      code: null,
      note: '',
    },
    resolver:      joiResolver(cancelOrderSchema, defaultValidationOptions),
  });

  const handleResult = useHandleOrderMutationResult(orderId);

  const onSubmit = handleSubmit(async values => {
    const result = await run({variables: {parameters: {...values, id: orderId}}});
    handleResult(result);
  });

  return <form onSubmit={onSubmit}>
    <ControlledSelect
      name={'code'}
      control={control}
      options={Object.keys(cancellationCodes)}
      renderOption={code => <>
        <strong>{code}</strong> · <ErrorCodeExplanation>{(cancellationCodes as any)[code]}</ErrorCodeExplanation>
      </>}
      placeholder={"Cancellation reason"}
    />
    <NoteInput control={control}/>
    <ErrorsByFields fields={'all'} errors={errors}/>
    {renderFooter(formState.isSubmitting)}
  </form>;
};
