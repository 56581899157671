import React, {useMemo} from "react";
import {useMeasure} from "react-use";
import Highlight from "react-highlight.js";
import format from "xml-formatter";

export const XmlViewer: React.FC<{ xml: string }> = ({xml}) => {

  const [innerRef, {height: innerHeight}] = useMeasure();

  const formattedXml = useMemo(() => {
    try {
      return format(xml, {indentation: '  '});
    } catch (e) {
      return `Failed to parse XML: ${e.message}`;
    }
  }, [xml]);

  return <div style={{position: "relative", height: innerHeight, minWidth: 0, width: '100%', overflowX: "scroll"}}>
    <div ref={innerRef as any} style={{position: "absolute", top: 0, left: 0, fontSize: '90%'}}>
      <Highlight language={"xml"}>
        {formattedXml}
      </Highlight>
    </div>
  </div>;
};
