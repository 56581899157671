import React, {CSSProperties} from "react";

export const UnplugIcon: React.FC<{ style?: CSSProperties }> = ({style}) => {
  return <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" x="0px"
              y="0px" viewBox="0 0 640 640" overflow="visible" style={{
    width:   '.95rem',
    display: 'inline-block',
    ...style,
  }}>
    <path fill="currentColor" d="M448,96c0-17.7-14.3-32-32-32c-17.7,0-32,14.3-32,32v96h64V96z"/>
    <path fill="currentColor" d="M160,320c0,76,53.5,141.6,128,156.8V576h64v-91.9l-192-192V320z"/>
    <path fill="currentColor" d="M524.6,509.9l-84.4-84.4C465.2,397.1,480,360,480,320v-32h16c8.8,0,16-7.2,16-16v-32c0-8.8-7.2-16-16-16H238.7l-32-32H256
			V96c0-17.7-14.3-32-32-32s-32,14.3-32,32v81.3l-61.5-61.5c-4.7-4.7-12.3-4.7-17,0l-24.8,24.8c-4.7,4.7-4.7,12.3,0,17l66.5,66.5
			l0,0l238.2,238.2l0,0l89.4,89.4c4.7,4.7,12.3,4.7,17,0l24.8-24.8C529.3,522.2,529.3,514.6,524.6,509.9z"/>
  </svg>;

};
