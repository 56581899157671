import React, {useState} from "react";
import {Breadcrumb} from "antd";
import {NavLink} from "react-router-dom";

import {ShowOrderModalButton} from "../parts/ShowOrderModalButton";
import {BreadcrumbsBar} from "../parts/BreadcrumbsBar";
import {HeaderBar} from "../parts/HeaderBar";
import {OrderGrid} from "./OrderGrid";
import {useOnFormIntentUpdated} from "../../hooks/useOnFormIntentUpdated";

export const FORM_ORDER_SEARCH = 'order_search';

export const OrderOverview: React.FC = () => {

  const [query, setQuery] = useState<string | null>(null);

  useOnFormIntentUpdated<{ query: string }>(FORM_ORDER_SEARCH, ({query}) => setQuery(query));

  const queryIsAddress           = query && /[0-9]{4} ?[a-z]{2}/i.test(query);
  const queryIsServiceUidPartial = (query?.length || 0) > 7 && !queryIsAddress;

  return <>
    <BreadcrumbsBar>
      <Breadcrumb>
        <Breadcrumb.Item><NavLink to={"/orders"}>Orders</NavLink></Breadcrumb.Item>
      </Breadcrumb>
    </BreadcrumbsBar>

    <HeaderBar
      title={'Order overview'}
      showSearch={true}
      searchPlaceholder={'Address or service UID'}
      query={query}
      setQuery={setQuery}
    >
      <ShowOrderModalButton/>
    </HeaderBar>

    <OrderGrid
      addressPartial={queryIsAddress ? query!.trim() : undefined}
      serviceUidPartial={queryIsServiceUidPartial ? query!.trim() : undefined}
    />
  </>;
};
