import {notification} from "antd";
import React, {CSSProperties} from "react";
import {gql, useMutation} from "@apollo/client";

import {SyncButton} from "./SyncButton";
import {notifyErrorGeneric} from "../../util/notifications";

export const TaskSetSyncButton: React.FC<{
  taskSetId: number;
  style?: CSSProperties;
}> = ({taskSetId, style}) => {

    const [sync, {loading}] = useMutation(gql`mutation SyncTaskSet($id: Int!) {
        syncTaskSetById(id: $id) {id}
    }`, {
      refetchQueries:      ['TaskSetDetails', 'SubTaskList', 'TaskSetSoapLog', 'TaskSetNotes'],
      awaitRefetchQueries: true,
      variables:           {id: taskSetId},
      onError:             error => notifyErrorGeneric(error.message),
      onCompleted() {
        notification.success({
          message: 'TaskSet synchronized.',
        });
      },
    });

  return <SyncButton
    title={'Synchronizes the TaskSet with Reggefiber'}
    disabled={loading}
    style={style}
    onClick={() => sync()}
  />;
};
