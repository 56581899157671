import React from "react";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import TimeAgo from "react-timeago";

export const ClickableDate: React.FC<{
  path: string,
  date: string,
}> = ({path, date}) => {
  const dispatch = useDispatch();
  return <button
    onClick={() => dispatch(push(path))}
    className={'link-button'}
    style={{fontSize: '95%'}}
  >
    <TimeAgo date={date}/>
  </button>;
};
