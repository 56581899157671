import React, {CSSProperties} from "react";
import {Tag} from "antd";
import {
  faCalendarAlt,
  faCheck,
  faClipboardCheck,
  faHourglassHalf,
  faTimes,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TagProps} from "antd/es/tag";

import {OrderStatus} from "../../../shared-types/orderDefinitions";
import {capitalize} from "../../../util/case";

export const OrderStatusLabels: Partial<Record<OrderStatus, string>> = {
  HANDED_OVER: 'Completed',
}

export const OrderStatusTag: React.FC<{ status: OrderStatus, style?: CSSProperties }> = ({status, style}) => {

  let icon: IconDefinition | null          = null;
  let color: TagProps['color'] | undefined = undefined;

  if (["PENDING", "CANCELLATION_PENDING", "RESCHEDULING_PENDING"].includes(status)) {
    icon  = faHourglassHalf;
    color = "orange";
  }
  if (status === "ACTIVE") {
    icon  = faClipboardCheck;
    color = "processing";
  }
  if (status === "ABORTED" || status === "CANCELED") {
    icon  = faTimes;
    color = "error";
  }
  if (status === "RESCHEDULING_REQUIRED") {
    icon  = faCalendarAlt;
    color = "warning";
  }
  if (status === "HANDED_OVER") {
    icon  = faCheck;
    color = "success";
  }

  const label = status in OrderStatusLabels ? OrderStatusLabels[status] :
    capitalize(status.toLowerCase()).replace('_', ' ');

  return <Tag color={color} style={style} title={status}>
    {icon ? <FontAwesomeIcon icon={icon} style={{marginRight: '.3rem'}}/> : null}
    {label}
  </Tag>;
};
