import React from "react";
import {useTimeout} from "react-use";
import {LogoBar} from "../LogoBar";

export const SplashScreen: React.FC<{
  fadeIn?: boolean,
}> = ({fadeIn = true, children}) => {
  const [entered] = useTimeout(100);
  return <div style={{
    background:     '#eee',
    minHeight:      '100vh',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
  }}>
    <div style={{
      background:   'white',
      borderRadius: 2,
      minWidth:     280,
      maxWidth:     340,
      padding:      24,
      margin:       24,
      opacity:      (entered() || !fadeIn) ? 1 : 0,
      transition:   'opacity .4s',
    }}>
      <LogoBar style={{
        borderBottom:  '1px solid rgba(0,0,0,0.1)',
        paddingBottom: '1rem',
        marginBottom:  '1rem',
      }}/>
      {children}
    </div>
  </div>;
};
