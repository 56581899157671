import React, {ReactNode} from "react";
import {Control, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {gql, useMutation} from "@apollo/client";
import {AnySchema} from "joi";

import {taskSetStateSchema} from "../../schemas/taskSetCommon";
import {defaultValidationOptions} from "./validation";
import {notifyErrorGeneric} from "../../util/notifications";
import {ErrorsByFields} from "./ValidationErrors";
import {NoteInput} from "./NoteInput";
import {ErrorCodeExplanation} from "../parts/ErrorCodeExplanation";
import {ControlledSelect} from "./ControlledSelect";
import {BpCode, BpCodeType} from "../../shared-types/compiled/bpTypes";
import {capitalize} from "../../util/case";
import {Skeleton} from "antd";
import {useAvailableCodes} from "../../hooks/useAvailableCodes";

export type TaskSetStateMutationFormProps = {

  taskSetId: number;
  codeType: BpCodeType;
  mutation: string;
  mutationParameterType?: string;

  codeSelectControlPlaceholder?: string;
  schema?: AnySchema;

  hide?: () => void;
  renderFooter?: (submitting: boolean) => ReactNode;

  // The next props are meant to inject additional fields, e.g. for the
  // "plan/replan" form which also requires a datetime field
  additionalDefaultValues?: Record<string, any>;
  additionalFields?: (control: Control) => ReactNode;

};

/**
 * `<ControlledSelect/>` (react-hook-forms compatible antd Select input) with as
 * available options the given contextually appropriate BpCodes
 */
const BpCodeSelect: React.FC<{
  control: Control;
  availableCodes: BpCode[];
  placeholder: string;
}> = ({control, availableCodes, placeholder}) => {
  return <ControlledSelect
    name={'code'}
    control={control}
    options={availableCodes.map(({code}) => code.toString())}
    renderOption={code => {
      const bpCode = availableCodes.find(availableCode => availableCode.code.toString() === code);
      return <>
        <strong>{code}</strong> · <ErrorCodeExplanation>{bpCode!.description}</ErrorCodeExplanation>
      </>
    }}
    placeholder={placeholder}
  />;
};

/**
 * A form that will trigger a taskset state mutation such as TaskSetHold, generically applicable
 * to any of the taskset state mutations.
 */
export const TaskSetStateMutationForm: React.FC<TaskSetStateMutationFormProps> = (
  {
    taskSetId,
    renderFooter,
    codeType,
    codeSelectControlPlaceholder,
    mutation,
    mutationParameterType = 'TaskSetStateMutation',
    schema,
    hide,
    additionalDefaultValues,
    additionalFields,
    children,
  }) => {

    const [run] = useMutation(gql`mutation ${capitalize(mutation)}($parameters: ${mutationParameterType}!) {
        ${mutation}(data: $parameters) { id }
    }`, {
      onError: error => {
        notifyErrorGeneric(error.message);
        hide?.();
      },
    }); // TODO refetch queries (?)

    // The "schema" prop is optional, and since the task set state mutation form schema is generic
    // for most state mutation types, we'll default to the default once based on the code type.
  if (!schema) schema = taskSetStateSchema(codeType);

  const {errors, handleSubmit, formState, control} = useForm({
    defaultValues: {
      code: null,
      note: '',
      ...additionalDefaultValues,
    },
    resolver:      joiResolver(schema, defaultValidationOptions),
  });

  const onSubmit = handleSubmit(async values => {
    // eslint-disable-next-line
    const result = await run({variables: {parameters: {...values, id: taskSetId}}});
    // TODO process / show result?
    hide?.();
  });

  const {codes: availableCodes, loading: loadingCodes} = useAvailableCodes(codeType, taskSetId);

  if (loadingCodes) return <Skeleton/>;

  return <form onSubmit={onSubmit}>
    {children}
    {additionalFields?.(control)}
    <BpCodeSelect
      control={control}
      availableCodes={availableCodes || []}
      placeholder={codeSelectControlPlaceholder || codeType}
    />
    <NoteInput control={control}/>
    <ErrorsByFields fields={"all"} errors={errors}/>
    {renderFooter?.(formState.isSubmitting)}
  </form>;
};
