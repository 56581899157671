/**
 * Helper population to populate multiple fields given that react-hook-form only
 * provides a function to set the value of one field at the time.
 */
export function setAllValues<TValues>(setValue: (field: keyof TValues, value: any) => void, values: TValues) {
  for (const field in values) {
    const value = values[field];
    setValue(field as any, value);
  }
}
