import React from "react";
import {useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";

import {ControlledModalFormFooter} from "../ControlledModalFormFooter";
import {useSubmitOrderForm} from "../../../hooks/useSubmitOrderForm";
import {ServiceUidSlice} from "./slices/ServiceUidSlice";
import {slaOrderSchema} from "../../../schemas/orderSla";
import {defaultValidationOptions} from "../validation";
import {FormSection} from "../FormSection";
import {InputRow} from "../InputRow";
import {FormattedDatePicker} from "./controls/FormattedDatePicker";
import {CreateOrderFormProps} from "./common";
import {RadioGroupInputSlice} from "./slices/RadioGroupInputSlice";

export const CreateSLAOrderForm: React.FC<CreateOrderFormProps> = ({hide}) => {
  const {errors, formState, handleSubmit, control} = useForm({
    defaultValues: {
      serviceUid: "",
      omSla:      "",
      wishDate:   "",
    },
    resolver:      joiResolver(slaOrderSchema, defaultValidationOptions),
  });

  const onSubmit = handleSubmit(
    useSubmitOrderForm('SlaOrder', 'operationalAndMaintenanceSla')
  );

  return <form onSubmit={onSubmit}>
    <FormSection>
      <ServiceUidSlice errors={errors} control={control}/>
    </FormSection>
    <FormSection>
      <RadioGroupInputSlice control={control} errors={errors} id={'omSla'} label={'SLA'}
                            options={['BASIC', 'PREMIUM']}/>
    </FormSection>
    <FormSection>
      <InputRow control={control} errors={errors} columns={[{
        id:     'wishDate',
        render: props => <FormattedDatePicker placeholder={'Wish date'} {...props}/>,
      }]}/>
    </FormSection>
    <ControlledModalFormFooter hide={hide} submitting={formState.isSubmitting}/>
  </form>;
};
