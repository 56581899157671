import {useDispatch} from "react-redux";
import {push} from "connected-react-router";

import {setFormIntent} from "../app/formIntentSlice";
import {FORM_ORDER_SEARCH} from "../components/orders/OrderOverview";

export function useTriggerSearchOrders(): (query: string) => void {
  const dispatch = useDispatch();
  return (query) => {
    dispatch(setFormIntent({form: FORM_ORDER_SEARCH, values: {query}}));
    dispatch(push('/orders'));
  };
}
