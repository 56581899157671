import React from "react";
import {Controller, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";

import {FormSection} from "../FormSection";
import {equipmentEndpointDefaults, EquipmentEndpointInput} from "./controls/EquipmentEndpointInput";
import {portMigrationOrderSchema} from "../../../schemas/portMigrationOrderSchema";
import {defaultValidationOptions} from "../validation";
import {useSubmitOrderForm} from "../../../hooks/useSubmitOrderForm";
import {CreateOrderFormProps} from "./common";
import {ControlledModalFormFooter} from "../ControlledModalFormFooter";
import {ServiceUidSlice} from "./slices/ServiceUidSlice";
import {InputRow} from "../InputRow";
import {FormattedDatePicker} from "./controls/FormattedDatePicker";

export const CreatePortMigrationOrderForm: React.FC<CreateOrderFormProps> = ({hide}) => {

  const {errors, formState, handleSubmit, control} = useForm({
    defaultValues: {
      serviceUid:                  "",
      fromActiveEquipmentEndpoint: equipmentEndpointDefaults,
      toActiveEquipmentEndpoint:   equipmentEndpointDefaults,
      executionDate:               "",
    },
    resolver:      joiResolver(portMigrationOrderSchema, defaultValidationOptions),
  });

  const onSubmit = handleSubmit(
    useSubmitOrderForm('PortMigrationOrder', 'portMigration')
  );

  return <form onSubmit={onSubmit}>
    <FormSection>
      <ServiceUidSlice errors={errors} control={control}/>
    </FormSection>
    <FormSection title={'Current port'}>
      <Controller
        control={control}
        name={'fromActiveEquipmentEndpoint'}
        render={props => <EquipmentEndpointInput errors={errors} {...props}/>}
      />
    </FormSection>
    <FormSection title={'Destination port'}>
      <Controller
        control={control}
        name={'toActiveEquipmentEndpoint'}
        render={props => <EquipmentEndpointInput errors={errors} {...props}/>}
      />
    </FormSection>
    <FormSection>
      <InputRow control={control} errors={errors} columns={[{
        id:     'executionDate',
        render: props => <FormattedDatePicker placeholder={'Execution date'} {...props}/>,
      }]}/>
    </FormSection>
    <ControlledModalFormFooter hide={hide} submitting={formState.isSubmitting}/>
  </form>
};
