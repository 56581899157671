import React from "react";
import {Controller} from "react-hook-form";
import {LineIdInputCard} from "../controls/LineIdInputCard";
import {AddressInput} from "../controls/AddressInput";
import {InputSliceProps} from "./common";

export const AddressOrLineIdInputSlice: React.FC<InputSliceProps & {
  isUsingLineId: boolean;
  setIsUsingLineId: (value: boolean) => void;
}> = ({control, errors, isUsingLineId, setIsUsingLineId}) => {
  return <>
    {isUsingLineId ? <>
      <LineIdInputCard control={control}/>
    </> : <>
      <Controller
        control={control}
        name={'address'}
        render={props => <AddressInput errors={errors} {...props}/>}
      />
    </>}
    <label style={{display: 'flex', alignItems: 'center'}}>
      {isUsingLineId ?
        <button type={'button'} className={'link-button'} onClick={() => setIsUsingLineId(false)}>Use full address instead</button> :
        <button type={'button'} className={'link-button'} onClick={() => setIsUsingLineId(true)}>Use
          Reggefiber-issued <strong>lineId</strong></button>}
    </label>
  </>;
};
