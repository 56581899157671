import React, {useCallback} from "react";
import {gql, useLazyQuery} from "@apollo/client";
import {NavLink} from "react-router-dom";
import {Alert, Breadcrumb, Col, Row, Skeleton} from "antd";

import {BreadcrumbsBar} from "../parts/BreadcrumbsBar";
import {HeaderBar} from "../parts/HeaderBar";
import {AvailabilityLookupType, AvailabilityQueryInput, AvailabilityResult} from "../../shared-types/availabilityTypes";
import {Json} from "../parts/Json";
import {AvailabilityForm} from "../forms/AvailabilityForm";

const LookupResult: React.FC<{ fromCache: boolean, result: AvailabilityResult }> = ({fromCache, result}) => {
  return <>
    {fromCache ? <Alert
      type={"info"}
      message={"Result retrieved from cache (previous lookup)"}
      showIcon={true}
      style={{marginBottom: '1rem'}}
    /> : null}
    <Json json={result}/>
  </>
}

/**
 * Shows the 'availability checker' form with the corresponding result
 */
const AvailabilityChecker: React.FC = () => {

    const [doQuery, r] = useLazyQuery(gql`query($query: AvailabilityQueryInput!, $lookupType: AvailabilityLookupType!, $useCache: Boolean!) {
        availability(query: $query, lookupType: $lookupType, useCache: $useCache) { result fromCache }
    }`, {
      fetchPolicy: "network-only",
    });

  const {data, error, loading} = r;

  const onCheck = useCallback((queryInput: AvailabilityQueryInput, lookupType: AvailabilityLookupType, cache?: boolean) => {
    doQuery({variables: {query: queryInput, lookupType, useCache: cache}});
  }, [doQuery]);

  return <>
    <Alert
      message={null}
      description={<>
        <AvailabilityForm loading={loading} onCheck={onCheck}/>
      </>}
      style={{marginBottom: '2rem', paddingBottom: '1rem'}}
    />
    {loading ? <Skeleton/> : (  /* TODO this block is messy */
      error ? <Alert message={error.message} type={"error"} showIcon={true}/> : <>
        {data ? <LookupResult {...data.availability}/> : null}
      </>
    )}
  </>;
};

export const Availability: React.FC = () => {
  return <>
    <BreadcrumbsBar>
      <Breadcrumb>
        <Breadcrumb.Item><NavLink to={"/availability"}>Availability</NavLink></Breadcrumb.Item>
      </Breadcrumb>
    </BreadcrumbsBar>
    <HeaderBar title={'Availability checker'}/>
    <Row gutter={20}>
      <Col sm={24} lg={16} style={{marginBottom: '2.5rem'}}>
        <div style={{maxWidth: 500}}>
          <AvailabilityChecker/>
        </div>
      </Col>
      <Col sm={24} lg={8} style={{marginBottom: '2.5rem'}}>
        {/*<h3>Recent results</h3>*/}
        {/*<p>(coming soon)</p>*/}
      </Col>
    </Row>
  </>;
};
