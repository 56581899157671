import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

export const SaveState: React.FC<{ saved: boolean }> = ({saved}) => {
  return <div style={{textAlign: 'right', marginBottom: -5}}>
      <span style={{
        opacity:    saved ? .45 : 0,
        fontSize:   '78%',
        display:    'block',
        transition: 'opacity .2s',
      }}>
        <FontAwesomeIcon icon={faCheck}/> saved
      </span>
  </div>;
};
