import React, {useState} from "react";
import {ControllerRenderProps} from "react-hook-form";
import {AutoComplete} from "antd";
import {gql, useQuery} from "@apollo/client";

import {OrderTypeTag} from "../../../parts/orders/OrderTypeTag";
import {OrderStatusTag} from "../../../parts/orders/OrderStatusTag";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import {ApiOrderEntity} from "../../../orders/types";

const ServiceUidAutocompletePreview: React.FC<{ accessOrder: Partial<ApiOrderEntity> }> = ({accessOrder}) => {

  const dispatch = useDispatch();

  return <div style={{padding: '5px 0'}}>
    <code style={{display: 'block', marginBottom: 0}}>{accessOrder.serviceUid}</code>
    <div style={{display: 'flex', alignItems: 'center'}}>
      <OrderTypeTag type={accessOrder.type!}/>
      <OrderStatusTag status={accessOrder.orderStatus!}/>
      <button className={'link-button'} style={{marginLeft: 'auto'}} onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(push(`/orders/${accessOrder.id}`));
      }}>View order
      </button>
    </div>
  </div>;
};

export const ServiceUidInput: React.FC<{ renderProps: ControllerRenderProps, placeholder?: string, id?: string }> = (
  {
    renderProps,
    placeholder = 'Type to search by service UIDs or address',
    id,
  }) => {

  const [options, setOptions] = useState<{ key: string, value: string, label: React.ReactNode }[]>([]);

  const showAutocomplete = renderProps.value?.length >= 3;

    /* const {loading, error, data} = */
    useQuery(gql`query findServiceUid($query: String) {
        accessOrders(serviceUidPartial: $query) {id, serviceUid orderStatus type createdAt type}
    }`, {
      variables:   {query: renderProps.value},
      skip:        !showAutocomplete,
      onCompleted: ({accessOrders}) => {
        setOptions(accessOrders.map((accessOrder: any, index: number) => ({
          key:   `${index}-${accessOrder.serviceUid}`,
          value: accessOrder.serviceUid,
          label: <ServiceUidAutocompletePreview accessOrder={accessOrder}/>,
        })));
      },
    });

  return <AutoComplete
    {...renderProps}
    onChange={value => renderProps.onChange(value)}
    placeholder={placeholder}
    id={id}
    options={showAutocomplete ? options : []}
  />;
};
