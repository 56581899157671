import joi from "joi";
import {changeCodes, dateSchemaRequired} from "./common";

export const rescheduleOrderSchema = joi.object({

  wishDate: dateSchemaRequired,
  code:     joi.string().allow(...Object.keys(changeCodes)),
  note:     joi.string().min(1),

});
