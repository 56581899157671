import {ApolloError, gql, useQuery} from "@apollo/client";
import {useMemo} from "react";

import {BpCode, BpCodeType} from "../shared-types/compiled/bpTypes";
import {getApplicableBpCodes} from "../util/taskStatusConditionals";

/**
 * Given the BP Code Type (hold, plan, etc), and the taskSetId, this will retrieve the types of the
 * tasks under the given taskSet (using GQL) and then figure out which of the available BP Codes of
 * the given Code Type are eligible to at least one of the task types within the taskSet.
 * @param codeType
 * @param taskSetId
 */
export function useAvailableCodes(codeType: BpCodeType, taskSetId: number): { codes: BpCode[] | undefined; loading: boolean; error: ApolloError | undefined } {

    const typesQuery = useQuery(gql`query TaskSetTaskTypes($id: Int!) {
        taskSet(id: $id) { tasks { type } }
    }`, {variables: {id: taskSetId}});

  const taskTypes: string[] | undefined = typesQuery?.data?.taskSet.tasks.map((task: { type: string }) => task.type);

  const availableCodes: BpCode[] | undefined = useMemo(() => {
    if (!taskTypes) return undefined;
    return getApplicableBpCodes(taskTypes, codeType);
  }, [codeType, taskTypes]);

  return {
    loading: typesQuery.loading,
    error:   typesQuery.error,
    codes:   availableCodes,
  };
}
