import React from "react";
import {Table} from "antd";
import {TableProps} from "antd/lib/table/Table";

/**
 * Wrapper around antd `Table` component that applies a few defaults
 * @param props
 * @constructor
 */
export function ExpandableGrid<RecordType extends object = any>(props: TableProps<RecordType>) {
  return <Table
    rowClassName={props.defaultExpandAllRows ? '' : 'clickable-row'}
    expandIconColumnIndex={props.columns?.length || undefined}
    expandIcon={() => null}
    expandRowByClick={!props.defaultExpandAllRows}
    size={"middle"}
    {...props}
  />;
}
