import React from "react";
import {TaskSetStateMutationModal} from "./TaskSetStateMutationModal";
import {taskSetPlanSchema} from "../../schemas/taskSetCommon";
import {FormattedDatePicker} from "../forms/order/controls/FormattedDatePicker";
import {Controller} from "react-hook-form";

export const TaskSetPlanModal: React.FC<{
  visible: boolean;
  taskSetId: number;
  hide: () => void;
}> = ({visible, taskSetId, hide}) => {
  return <TaskSetStateMutationModal
    summary={"Plan / replan TaskSet"}
    mutation={"taskSetPlan"}
    visible={visible}
    hide={hide}
    form={{
      taskSetId:               taskSetId,
      codeType:                "(Re)Plan code",
      schema:                  taskSetPlanSchema,
      mutationParameterType:   'TaskSetStatePlanMutation',
      additionalDefaultValues: {
        planDate: '',
      },
      additionalFields:        control => <Controller
        name={'planDate'}
        control={control}
        render={props => <FormattedDatePicker
          {...props}
          placeholder={'Plan date'}
          style={{width: '100%', marginBottom: '.75rem'}}
        />}
      />
    }}
  />;
};
