import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

import {clearFormIntent, selectFormIntent} from "../app/formIntentSlice";

/**
 * Invoke the update callback every time the form intent state is updated and matches the given form
 */
export function useOnFormIntentUpdated<TValues = object>(form: string, onUpdated: (values: TValues) => void): void {

  const dispatch = useDispatch();
  const values   = useSelector(selectFormIntent(form));

  useEffect(() => {
    if (values !== null) {
      onUpdated(values as any);
      dispatch(clearFormIntent());
    }
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps
}
