import React, {ComponentProps, CSSProperties} from "react";
import {Controller} from "react-hook-form";

import {ErrorsByFields} from "./ValidationErrors";
import {capitalize} from "../../util/case";

type RenderWithControl = ComponentProps<typeof Controller>['render'];
type RenderWithoutControl = (column: InputRowColumn<RenderWithoutControl>) => React.ReactNode;

export type InputRowColumn<TRender> = {
  id: string,
  width?: string,
  label?: string | null,
  style?: CSSProperties,
  optional?: boolean,
  render: TRender,
};

type PropsWithControl = {
  control: ComponentProps<typeof Controller>['control'],
  errors?: any | Record<string, string>,
  columns: InputRowColumn<RenderWithControl>[],
};

type PropsWithoutControl = {
  control: false,
  errors?: any | Record<string, string>,
  columns: InputRowColumn<RenderWithoutControl>[],
};

export const InputRow: React.FC<PropsWithControl | PropsWithoutControl> = ({control, errors = {}, columns}) => {

  // Fallback width (if not supplied for an individual column) is 100% divided by the number
  // of columns, rounded up to one decimal point.
  const defaultWidth = `${Math.ceil((1000 / columns.length)) / 10}%`;

  const columnIds = (columns as InputRowColumn<any>[]).map(column => column.id);

  return <div className={"input-row-wrapper"}>
    <div className={"input-row"}>
      {(columns as InputRowColumn<any>[]).map(column => {

        // Default for "label" is just the capitalized ID, but allow explicit `null` to hide it as well.
        const label = column.label === null ? null :
          (column.label ?? capitalize(column.id));

        const width = column.width ?? defaultWidth;

        // TODO improve the label 'for' attribute so it always automatically references the input field
        return <div key={column.id} style={{width, ...column.style}}>
          {label !== null ? <label htmlFor={column.id}>
            {label} {column.optional ? <span style={{opacity: .5}}>opt.</span> : null}
          </label> : null}
          {control ?
            <Controller name={column.id} control={control} render={column.render as any}/> :
            column.render({column})
          }
        </div>;
      })}
    </div>
    <ErrorsByFields fields={columnIds} errors={errors}/>
  </div>;
};
