"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
exports.__esModule = true;
exports.validate = exports.isValid = exports.attributeErrors = void 0;
var escapeStringRegexp = require("escape-string-regexp");
var lodash_1 = require("lodash");
/**
 * "Unflatten" (extract) an array of structural attributes from the given input data.
 * E.g. if the key is "FOO" and the input data looks something like
 * ```js
 * {
 *   "FOOBAR": "blah",
 *   "FOO[0][BAR]": "baz",
 *   "FOO[1][BAR]": "barbaz",
 * }
 * ```
 * The return value would look like
 * ```js
 * {
 *   output: [{
 *     "BAR": "baz"
 *   }, {
 *     "BAR": "barbaz"
 *   }],
 *   consumedKeys: [
 *     "FOO[0]BAR",
 *     "FOO[1]BAR",
 *   ]
 * }
 * ```
 * @param data
 * @param key
 */
function unflatten(data, key) {
    var output = [];
    var consumedKeys = [];
    var pattern = new RegExp(escapeStringRegexp(key) + '\\[(\\d+)\\]\\[([^\\]]+)\\]');
    for (var field in data) {
        var matches = pattern.exec(field);
        if (matches) {
            consumedKeys.push(field);
            lodash_1.set(output, [matches[1], matches[2]], data[field]);
        }
    }
    return { output: output, consumedKeys: consumedKeys };
}
/**
 * "Unflatten" / "inflate" all structural attributes within the given flat map of data.
 * @param data
 * @param schema
 */
function unFlattenAll(data, schema) {
    var errors = {};
    data = __assign({}, data); // shallow copy data
    // Iterate over schema and find all 'collection' validators. Use `unflatten` and replace the original value
    // with the `unflattened` value, removing all `consumedKeys` from the input data
    for (var _i = 0, schema_1 = schema; _i < schema_1.length; _i++) {
        var attribute = schema_1[_i];
        var key = attribute.key;
        var isCollection = !!attribute.format.collection;
        if (isCollection) {
            if (key in data) {
                errors[key] = "Illegal key at structural attribute root.";
            }
            var _a = unflatten(data, key), output = _a.output, consumedKeys = _a.consumedKeys;
            for (var _b = 0, consumedKeys_1 = consumedKeys; _b < consumedKeys_1.length; _b++) {
                var consumedKey = consumedKeys_1[_b];
                delete data[consumedKey];
            }
            data[key] = output;
        }
    }
    return {
        data: data, errors: errors
    };
}
/**
 * Count the number of "attribute errors" / schema violations in the given validation result
 * @param result
 */
function attributeErrors(result) {
    return Object.keys(result.illegalValues).length +
        result.illegalMutations.length +
        result.missing.length +
        Object.keys(result.errors).length;
}
exports.attributeErrors = attributeErrors;
/**
 * Check if the validation result indicates an entirely valid set of attributes
 * @param result
 */
function isValid(result) {
    return !attributeErrors(result);
}
exports.isValid = isValid;
/**
 * Merge `overlay` instance of `SchemaValidationResult` into `base` (does not modify either; returns a new object)
 */
function mergeValidationRootResult(base, overlay) {
    return {
        illegalValues: __assign(__assign({}, base.illegalValues), overlay.illegalValues),
        illegalMutations: __spreadArray(__spreadArray([], base.illegalMutations), overlay.illegalMutations),
        missing: __spreadArray(__spreadArray([], base.missing), overlay.missing),
        errors: __assign(__assign({}, base.errors), overlay.errors),
        optional: __spreadArray(__spreadArray([], base.optional), overlay.optional)
    };
}
/**
 * Validate a set of values based on the given schema (array of process attributes descriptors)
 */
function validate(schema, newValue, oldValue) {
    // Iterate over the schema
    // every rule "gathers" its relevant value(s) and moves it to a processed collection
    // and the rule itself is either marked as "completed", "error" or "missing"
    // the remaining values can be marked "unexpected" or "illegal"
    // "Unflatten" both new and old value using the schema
    oldValue = oldValue ? unFlattenAll(oldValue, schema).data : oldValue;
    var ufNew = unFlattenAll(newValue, schema);
    newValue = ufNew.data;
    // Result 'carry' object that we'll populate along the way and return at the end of this procedure
    var result = {
        // Fields which are not familiar in the schema
        illegalValues: __assign({}, newValue),
        // Fields which aren't allowed to be mutated by the contractor
        illegalMutations: [],
        // Fields for which no value was encountered - i.e. these values are still missing and need to be filled
        missing: [],
        // Fields that the user may choose to populate but are not required
        optional: [],
        errors: ufNew.errors
    };
    // First, let's remove any values from the "illegalValues" that weren't modified or added by us (the contractor)
    if (oldValue) {
        for (var key in newValue) {
            if (oldValue[key] === newValue[key]) {
                delete result.illegalValues[key];
            }
        }
    }
    for (var _i = 0, schema_2 = schema; _i < schema_2.length; _i++) {
        var attribute = schema_2[_i];
        var key = attribute.key;
        // This attribute is at least recognized, remove it from the "illegalValues" collection
        delete result.illegalValues[key];
        if (oldValue) {
            if (!attribute.mutableByContractor && JSON.stringify(newValue[key]) !== JSON.stringify(oldValue[key])) {
                result.illegalMutations.push(key);
            }
        }
        if (!attribute.mutableByContractor) {
            // We can't mutate it anyways, don't bother with the remaining validation routine
            continue;
        }
        if (!(key in newValue)) {
            if (attribute.requiredOnClose) {
                // It's not yet present, but required on close.
                result.missing.push(attribute.key);
            }
            else {
                // The use may choose to populate this field, but it's not required.
                result.optional.push(attribute.key);
            }
            continue;
        }
        var collectionResult = attribute.format(newValue[key], key);
        if (collectionResult === null) {
            continue;
        }
        if (typeof collectionResult === 'string') {
            result.errors[key] = collectionResult;
        }
        else {
            // It's a `SchemaValidationResult` instance from a structure attribute (collection) validator which we'll
            // merge in with our root validation state. The instance we received from the collection validator is
            // expected to already have prefixed or nested keys so it'll be evident which of the elements has
            // resulted in any of the specific validation errors.
            result = mergeValidationRootResult(result, collectionResult);
        }
    }
    return result;
}
exports.validate = validate;
