import {InputSliceProps} from "./common";
import React from "react";
import {InputRow} from "../../InputRow";
import {FormattedDatePicker} from "../controls/FormattedDatePicker";

export const WishDateInputSlice: React.FC<InputSliceProps> = ({errors, control}) => {
  return <div>
    <InputRow errors={errors} control={control} columns={[{
      id:     'wishDate', label: 'Wish Date',
      render: props => <FormattedDatePicker
        {...props}
        placeholder={'Wish Date'}
        style={{width: '100%'}}
      />,
    }]}/>
  </div>;
}
