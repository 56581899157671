import React, {CSSProperties} from "react";
import {faLink} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {EquipmentEndpointSummary} from "../EquipmentEndpointSummary";
import {OdfAccessOrderServerServiceSoapTypes} from "../../../shared-types/OdfAccessOrderServerService/OdfAccessOrderServerServiceSoap";
import {LocationSummary} from "../LocationSummary";
import {Tag} from "antd";
import {UnplugIcon} from "../../UnplugIcon";
import {translateOrderType} from "../../data/orderTypeMap";
import {capitalize} from "../../../util/case";
import {ApiOrderEntity} from "../../orders/types";

function getAttributes<TAttributes>(order: ApiOrderEntity): TAttributes | undefined {
  return order.attributes as any as TAttributes | undefined;
}

export const OrderSummary: React.FC<{ order: ApiOrderEntity, style?: CSSProperties }> = ({order, style}) => {
  if (order.type === "construction") {
    const attributes = getAttributes<OdfAccessOrderServerServiceSoapTypes.IConstruction>(order);
    if (!attributes) return <div style={{opacity: .5, ...style}}>(attributes missing)</div>;
    return <div style={style}>
      <EquipmentEndpointSummary endpoint={attributes.activeEquipmentEndpoint}/>
      <FontAwesomeIcon icon={faLink} style={{marginRight: 8, transform: 'scale(.8)'}}/>
      {attributes.location ? <LocationSummary location={attributes.location}/> : null}
      {attributes.lineId ? <Tag>line {attributes.lineId}</Tag> : null}
    </div>;
  }
  if (order.type === "deconstruction") {
    const attributes = getAttributes<OdfAccessOrderServerServiceSoapTypes.IDeconstruction>(order);
    if (!attributes) return <div style={{opacity: .5, ...style}}>(attributes missing)</div>;
    return <div style={style}>
      <EquipmentEndpointSummary
        overrideIcon={<div style={{transform: 'translate(-3px, 3px)', display: 'inline-block'}}><UnplugIcon/></div>}
        endpoint={attributes.activeEquipmentEndpoint}
        color={"red"}
      />
    </div>;
  }
  return <span style={{display: 'block', ...style}}>{capitalize(translateOrderType(order.type))} order</span>;
};
