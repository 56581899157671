import {gql, useMutation, useQuery} from "@apollo/client";
import React, {useCallback, useMemo, useState} from "react";
import {Alert, Button, Col, notification, Row, Skeleton} from "antd";
import {InteractionProps} from "react-json-view";
import ReactDiffViewer from 'react-diff-viewer';

import {BpProcessRootAttribute} from "../../../shared-types/compiled/bpTypes";
import {Json} from "../../parts/Json";

import "./AttributeWorkspace.scss";
import {isValid, validate} from "../../../shared-types/compiled/bpAttributeRoutines";
import {getSchemaForTaskType} from "../../../shared-types/compiled/bpProcessAttributes";
import {keysToUpper, listValues, stringifyValues} from "./helpers";
import {SchemaValidationResultPresenter} from "./SchemaValidationResultPresenter";
import {deepDiff} from "../../../shared-types/compiled/deepDiff";

export type Attributes = Record<string, string>;

/**
 * Determine whether the given update to an attribute set is allowed according to the given schema. If the
 * update is "illegal", this will return a string describing the error. if allowed, it will return `null`.
 */
function isChangeAllowed(schema: BpProcessRootAttribute[], current: Attributes, modified: Attributes): string | null {
  const diff = deepDiff(current, modified) || {};
  console.log({diff});
  for (const key in diff) {
    const attribute = schema.find(a => a.key === key);
    if (!attribute) {
      return `Unknown attribute "${key}"`;
    }
    if (!attribute.mutableByContractor) {
      return `Attribute "${key}" may not be mutated by the contractor.`;
    }
    // We'll only perform the above rudimentary checks. If the attribute is required, or has been set to
    // an "invalid" value, we'll still "allow" the change as the user may want to leave it at this value
    // temporarily for the time being until the final value is available.
  }
  return null;
}

export type AttributeWorkspaceProps = {
  schema: BpProcessRootAttribute[];
  value: Attributes;
  onSave: (value: Attributes) => Promise<void>;
  hide?: () => void;
};

export const AttributeWorkspace: React.FC<AttributeWorkspaceProps> = (
  {
    schema,
    value: initialValue,
    onSave,
    hide,
  }
) => {

  const [value, setValue]   = useState(initialValue);
  const [saving, setSaving] = useState(false);

  const update = useCallback((interaction: InteractionProps) => {
    const mutableKeys = schema.filter(a => a.mutableByContractor).map(a => a.key);
    const sanitized   = keysToUpper(stringifyValues(interaction.updated_src, mutableKeys));
    const changeError = isChangeAllowed(schema, value, keysToUpper(interaction.updated_src) as {});
    if (changeError) {
      notification.error({
        message:     'Can\'t apply this change.',
        description: changeError,
      });
      setValue({...value}); // If we set it to the same object, the update will still go through.
    } else {
      setValue(sanitized);
    }
  }, [setValue, value, schema]);

  const initialList = useMemo(() => listValues(initialValue), [initialValue]);
  const currentList = useMemo(() => listValues(value), [value]);

  const hasChanges = useMemo(() => initialList !== currentList, [initialList, currentList]);
  const validation = useMemo(() => validate(schema, value, initialValue), [schema, value, initialValue]);
  const valid      = useMemo(() => isValid(validation), [validation]);

  return <>
    <Row gutter={16}>
      <Col xs={24} md={12}>
        <div className={'attribute-workspace-json-editor'}>
          <Json
            json={value}
            viewProps={{
              defaultValue: "",
              onAdd:        update,
              onEdit:       update,
              onDelete:     update
            }}
          />
        </div>
      </Col>
      <Col xs={24} md={12}>
        {hasChanges ? <>
          <h3>Changes</h3>
          <div className={'attribute-workspace-diff'}>
            <ReactDiffViewer
              disableWordDiff={true}
              splitView={false}
              oldValue={listValues(initialValue)}
              newValue={listValues(value)}
            />
          </div>
        </> : (valid ? <Alert
          message={"Changes you make in the editor will appear here alongside the corresponding validation results."}
        /> : <Alert
          type={"warning"}
          message={<span style={{fontSize: '95%'}}>
            You may store any changes you make by clicking "Save".
            Once there are no validation errors you'll be able to synchronize the attributes.
          </span>}
        />)}
        <SchemaValidationResultPresenter
          style={{margin: '1rem 0'}}
          result={validation}
        />
      </Col>
    </Row>
    <div style={{
      marginTop:      '1rem',
      display:        'flex',
      justifyContent: 'flex-end',
    }}>
      {hide ? <Button
        style={{marginRight: '.5rem'}}
        onClick={hide}
      >Cancel</Button> : null}
      <Button
        type={'primary'}
        loading={saving}
        onClick={async () => {
          setSaving(true);
          await onSave(value);
          setSaving(false);
        }}
        disabled={!hasChanges}
      >
        {valid ? 'Save and synchronize' : 'Save'}
      </Button>
    </div>
  </>;
};

/**
 * Abstraction around AttributeWorkspace that takes care of retrieving the task data
 * and saving the attributes
 */
export const ConnectedAttributeWorkspace: React.FC<{
  taskId: number;
  hide: () => void;
}> = ({taskId, hide}) => {

    const {loading, error, data} = useQuery(gql`query TaskAttributes ($id: Int!) {
        task(id: $id) {id projectedAttributes type}
    }`, {
      variables: {id: taskId},
    });

    const [save, {error: mutationError}] = useMutation(gql`mutation SaveTaskAttributes($id: Int!, $attributes: String!) {
        saveTaskAttributes(id: $id, attributes: $attributes) { id  }
    }`, {
      onError(error) {
        // don't throw - we'll render it instead
        console.warn(error);
      },
      refetchQueries: ['TaskAttributes', 'SubTaskList'],
    });

  const schema = useMemo(() => {
    return data ? getSchemaForTaskType(data.task.type) : [];
  }, [data]);

  if (loading) return <Skeleton/>;
  if (error || mutationError) return <Alert message={(error || mutationError)!.message} type={"error"}/>;

  return <AttributeWorkspace
    schema={schema}
    value={data.task.projectedAttributes}
    onSave={async (attributes) => {
      const result  = await save({variables: {id: taskId, attributes: JSON.stringify(attributes)}});
      const success = result && !result.errors;
      if (success) hide();
    }}
    hide={hide}
  />;
};
