import {CompositeInputProps, subFieldProps} from "./compositeFields";
import React from "react";
import {InputRow} from "../../InputRow";
import {Input} from "antd";

export type ContactValue = {
  firstName: string;
  intercalation: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

export const contactDefaults: ContactValue = {
  firstName:     "",
  intercalation: "",
  lastName:      "",
  phoneNumber:   "",
  email:         "",
}

export const ContactInput: React.FC<CompositeInputProps<ContactValue>> = React.forwardRef((
  {name, value, onChange, errors}, ref
) => {
  // This is only wrapped in forwardRef because the component needs to accept
  // a ref in order to be compatible with the `react-hook-form` `<Controller/>`.
  const fieldProps = subFieldProps(value, onChange);
  return <div>
    <InputRow errors={errors?.[name] || {}} control={false} columns={[{
      id:     'firstName', label: 'First Name', width: '40%',
      render: () => <Input placeholder={'First Name'} {...fieldProps('firstName')}/>,
    }, {
      id:     'intercalation', label: 'Infix', width: '20%', optional: true,
      render: () => <Input placeholder={'Infix'} {...fieldProps('intercalation')}/>,
    }, {
      id:     'lastName', label: 'Last Name', width: '40%',
      render: () => <Input placeholder={'Last Name'} {...fieldProps('lastName')}/>,
    }]}/>

    <InputRow errors={errors?.[name] || {}} control={false} columns={[{
      id:     'phoneNumber', label: 'Phone Number',
      render: () => <Input placeholder={'+31 12345678'} {...fieldProps('phoneNumber')}/>,
    }, {
      id:     'email', optional: true,
      render: () => <Input placeholder={'email@domain.com'} {...fieldProps('email')}/>,
    }]}/>
  </div>
});
