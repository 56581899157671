import React from "react";
import {gql, useQuery} from "@apollo/client";

import {AutoSyncNotesEditor} from "../parts/NotesEditor";
import {Skeleton} from "antd";

export const TaskSetNotes: React.FC<{
  taskSetId: number;
}> = ({taskSetId}) => {

    const {loading, error, data} = useQuery(gql`query TaskSetNotes ($taskSetId: Int!) {
        taskSet(id: $taskSetId) {notes}
    }`, {
      variables: {taskSetId},
    });

  if (error) return <>{error.message}</>;

  if (loading) return <Skeleton title={false}/>;

    return <AutoSyncNotesEditor
      initialValue={data.taskSet.notes}
      createMutation={() => gql`mutation UpdateTaskSetNotes($id: Int!, $notes: String!) {
        updateTaskSetNotes(id: $id, notes: $notes) {id notes}
      }`}
      getMutationVariables={notes => ({id: taskSetId, notes})}
      getSavedNotesFromMutationResult={data => data?.updateTaskSetNotes?.notes}
    />;
}
