import React from "react";
import {Modal} from "antd";

import {ModalStateProps} from "./common";
import {TaskSetStateMutationForm, TaskSetStateMutationFormProps} from "../forms/TaskSetStateMutationForm";
import {ControlledModalFormFooter} from "../forms/ControlledModalFormFooter";
import {ModalActionHint} from "./ModalActionHint";

export type TaskSetStateMutationModalProps = ModalStateProps & {

  mutation: string;
  summary: string;
  form: Omit<TaskSetStateMutationFormProps, 'hide' | 'renderFooter' | 'mutation'>;

};

export const TaskSetStateMutationModal: React.FC<TaskSetStateMutationModalProps> = (
  {visible, hide, form, mutation, summary, children}
) => {

  return <Modal
    title={summary}
    visible={visible}
    footer={null}
    closable={false}
  >
    <TaskSetStateMutationForm
      {...form}
      mutation={mutation}
      hide={hide}
      renderFooter={submitting => <>
        <ModalActionHint title={summary}>
          This will dispatch a <code>{mutation}</code> message to Reggefiber.
          Please note that it might not immediately take effect.
        </ModalActionHint>
        <ControlledModalFormFooter
          hide={hide}
          submitting={submitting}
        />
      </>}
    >
      {children}
    </TaskSetStateMutationForm>
  </Modal>;
};
