import React from "react";
import {StylableInputSliceProps} from "./common";
import {Alert, Checkbox} from "antd";
import {InputRow} from "../../InputRow";

import "./TaskHandlingInputSlice.scss";

/**
 * Provides the markup and input component that allows for enabling the "handle" flag on the request which will
 * cause the order to include an automatically generated appointmentId with the appropriate prefix such that the
 * order will result in a taskset that will be issued to us and is, as such, to be handled by us.
 */
export const TaskHandlingInputSlice: React.FC<StylableInputSliceProps> = ({control, errors, style}) => {
  return <Alert
    style={{
      margin: '.5rem 0 .75rem',
      ...style,
    }}
    message={<strong>TaskHandling assignment</strong>}
    description={<div className={"task-handling-input-slice-description"}>
      <p className={"hint"}>
        By providing a generated appointmentId, we can signify to Reggefiber that this is an order for which we would
        also like to receive the corresponding TaskSet (ConstructionPortal operatives) so that we'll be the appointed
        party for the physical execution of this order.
      </p>
      <InputRow control={control} errors={errors} columns={[
        {
          id:     'handle', label: null,
          render: ({value, onChange, ...props}) => <Checkbox
            checked={value}
            onChange={e => onChange(e.target.checked)}
            {...props}
          >Add generated appointmentId</Checkbox>,
        }
      ]}/>
    </div>}
  />;
};
