import React, {CSSProperties, useCallback, useState} from "react";
import {Button, Modal} from "antd";
import {gql, useMutation} from "@apollo/client";

import {
  isAcceptable,
  isCancelAckalable,
  isClosable,
  isHoldable,
  isPlannable,
  isResumeable
} from "../../util/taskStatusConditionals";
import {ApiTaskSetEntity, ModalType} from "./types";
import {notifyErrorGeneric} from "../../util/notifications";

import "./TaskSetActions.scss";
import {TaskSetStateMutationModal} from "../modals/TaskSetStateMutationModal";
import {BpCodeType} from "../../shared-types/compiled/bpTypes";
import {SmallButtonIcon} from "../SmallButtonIcon";
import {
  faCalendarDay,
  faCheck,
  faCheckDouble,
  faPauseCircle,
  faPlayCircle,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {TaskSetPlanModal} from "../modals/TaskSetPlanModal";
import {TaskSetStateMutationModalWarning} from "./TaskSetStateMutationModalWarning";

/**
 * Display a confirmation modal with the given arguments for a task state change
 * @param verb
 * @param message
 * @param confirm
 */
function confirmTaskSetState(verb: string, message: string, confirm: () => void) {
  Modal.confirm({
    title:   `Really ${verb} this task set?`,
    content: `This will dispatch a "${message}" message to Reggefiber. Please note that it might not immediately take effect.`,
    onOk:    confirm,
  });
}

/**
 * Modals with identical "shape" -- all modals (taskset state mutation forms) except "plan"
 */
const genericModals: [string, BpCodeType, string, ModalType][] = [
  ['taskSetHold', "Hold code", "Hold TaskSet", "hold"],
  ['taskSetClose', "Close code", "Close TaskSet", "close"],
  ['taskSetCancelAck', "Cancel Accept", "Confirm TaskSet cancellation", "cancelAck"],
  ['taskSetResume', "Resume code", "Resume TaskSet", "resume"],
];

export const TaskSetActions: React.FC<{
  taskSet: ApiTaskSetEntity;
  style?: CSSProperties;
}> = ({taskSet, style}) => {

  const [activeModal, setActiveModal] = useState<ModalType | null>(null);

  const hideModal = useCallback(() => setActiveModal(null), [setActiveModal]);

    const [accept] = useMutation(gql`mutation acceptTaskSet($id: Int!) {
        acceptTaskSet(id: $id) {id}
    }`, {
      variables: {id: taskSet.id},
      onError:   error => notifyErrorGeneric(error.message),
    });

  const acceptable               = isAcceptable(taskSet);
  const plannable                = isPlannable(taskSet);
  const holdable                 = isHoldable(taskSet);
  const {closable, pendingTasks} = isClosable(taskSet);
  const resumeable               = isResumeable(taskSet);
  const cancelAckable            = isCancelAckalable(taskSet);

  if (!acceptable && !plannable && !holdable && !closable && !resumeable && !cancelAckable) return null;

  return <>

    {genericModals.map(([mutation, codeType, summary, modalType]) => {
      return <TaskSetStateMutationModal
        key={modalType}
        mutation={mutation}
        form={{
          codeType,
          taskSetId: taskSet.id,
        }}
        summary={summary}
        visible={activeModal === modalType}
        hide={hideModal}
      >
        <TaskSetStateMutationModalWarning type={modalType} context={{pendingTasks}}/>
      </TaskSetStateMutationModal>
    })}

    <TaskSetPlanModal visible={activeModal === 'plan'} taskSetId={taskSet.id} hide={hideModal}/>

    <div style={style} className={'task-set-actions'}>

      {acceptable ? <Button
        type={'primary'}
        onClick={() => confirmTaskSetState('accept', 'taskSetAccept', accept)}
      >
        <SmallButtonIcon icon={faCheck}/>
        Accept
      </Button> : null}

      {plannable ? <Button onClick={() => setActiveModal('plan')}>
        <SmallButtonIcon icon={faCalendarDay}/> Plan / replan
      </Button> : null}

      {holdable ? <Button type={'dashed'} onClick={() => setActiveModal('hold')}>
        <SmallButtonIcon icon={faPauseCircle}/> Hold
      </Button> : null}

      {resumeable ? <Button type={'primary'} onClick={() => setActiveModal('resume')}>
        <SmallButtonIcon icon={faPlayCircle}/> Resume
      </Button> : null}

      {cancelAckable ? <Button onClick={() => setActiveModal('cancelAck')}>
        <SmallButtonIcon icon={faTimesCircle}/> Confirm cancel
      </Button> : null}

      {closable ? <Button type={'primary'} onClick={() => setActiveModal('close')}>
        <SmallButtonIcon icon={faCheckDouble}/>
        Close
      </Button> : null}
    </div>
  </>;
}
