import React, {CSSProperties, ReactNode} from "react";
import {Col, Row} from "antd";

export const EntityCard: React.FC<{
  title: ReactNode;
  afterTitle?: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
  headingStyle?: CSSProperties;
}> = ({
        title,
        afterTitle,
        left,
        right,
        headingStyle
      }) => {
  return <div style={{margin: '1rem 0'}}>
    <Row gutter={16}>
      <Col xs={24} md={12} style={{marginBottom: '1rem'}}>
        <div style={{margin: '0 0 1rem', display: 'flex', alignItems: 'center'}}>
          <h3 style={{margin: 0, ...headingStyle}}>{title}</h3>
          {afterTitle ? afterTitle : null}
        </div>
        {left}
      </Col>
      <Col xs={24} md={12}>
        {right}
      </Col>
    </Row>
  </div>;
};
