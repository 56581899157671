import React, {CSSProperties} from "react";
import {Badge, Button} from "antd";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";

import {ApiTaskEntity} from "./types";
import {EntityCard} from "../parts/EntityCard";
import {TaskStatusTag} from "./TaskStatusTag";
import {Json} from "../parts/Json";
import {ClipboardItem} from "../parts/ClipboardItem";
import {AttributeModal} from "./attributes/AttributeModal";

import "./SubTaskDetails.scss";
import {TaskAttributesSummary} from "./TaskAttributesSummary";

/**
 * Based on the current path, determine if we should "focus on" (view attributes for) the given task
 * @param taskId
 */
function useIsViewingTask(taskId: number): boolean {
  const params = useParams<{
    taskSetId: string;
    taskId?: string;
  }>();
  return !!(params.taskId && (params.taskId === taskId.toString()));
}

/**
 * Return a tuple of two functions: one that will "navigate" to the path such that the given task will be "focused on"
 * (attribute workspace / modal will appear), the other will "navigate" back up to hide the modal.
 * @param taskId
 */
function useViewTask(taskId: number): [() => void, () => void] {
  const dispatch = useDispatch();
  const params   = useParams<{ taskSetId: string; taskId?: string; }>();
  const basePath = `/task-sets/${params.taskSetId}`;
  return [
    () => dispatch(push(`${basePath}/${taskId}`)),
    () => dispatch(push(basePath)),
  ];
}

export const EditAttributesButton: React.FC<{
  taskId: number;
  errors?: number;
  style?: CSSProperties;
}> = ({taskId, style, errors}) => {

  const modalVisible = useIsViewingTask(taskId);
  const [show, hide] = useViewTask(taskId);

  return <>
    <AttributeModal visible={modalVisible} taskId={taskId} hide={hide}/>
    <Button style={style} onClick={show} className={'edit-attributes-button'}>
      View / edit
      {errors ? <Badge className={'button-label-badge'} count={errors} size={'small'}/> : null}
    </Button>
  </>
};

export const SubTaskDetails: React.FC<{ task: ApiTaskEntity }> = ({task}) => {
  const isSolved = task.status === "SOLVED";
  return <EntityCard
    headingStyle={{fontSize: '100%'}}
    title={<span style={{
      textDecoration: isSolved ? 'line-through' : undefined,
    }}>{`${task.type} task`}</span>}
    afterTitle={<TaskStatusTag style={{marginLeft: '1rem'}} status={task.status}/>}
    left={<>
      <TaskAttributesSummary task={task}/>
      <Json json={task.attributes} collapsed={0}/>
    </>}
    right={<>
      <p style={{marginBottom: '.2rem'}}>Created: <strong>{task.createdAt}</strong></p>
      <p style={{marginBottom: '.2rem'}}>Updated: <strong>{task.updatedAt}</strong></p>
      <ClipboardItem style={{marginBottom: '1rem'}} label={'External UID'} value={task.uid}/>
    </>}
  />;
};
