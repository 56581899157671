import joi, {ObjectSchema} from "joi";
import {pickErrorCodes} from "../util/pickErrorCodes";

export const equipmentEndpointSchema = joi.object({
  popName: joi.string().regex(/([a-z])+-([a-z0-9])+/).max(15),
  row:     joi.number().min(1).max(99),
  frame:   joi.number().min(1).max(9999),
  block:   joi.string().regex(/^[A-Z]$/),
  module:  joi.string().allow(''),
  port:    joi.string(),
});

export const contactSchema = joi.object({
  firstName:     joi.string().max(128),
  intercalation: joi.string().allow('').max(64),
  lastName:      joi.string().max(128),
  phoneNumber:   joi.string().regex(/[+]?[- 0-9]{4,24}/),
  email:         joi.string().allow('').regex(/([a-zA-Z0-9_\-&.])+@[a-zA-Z0-9]([a-zA-Z0-9_\-&amp;])*.([a-zA-Z0-9_\-&](.)?)*([a-zA-Z0-9])+/),
});

export const addressSchema = joi.object({
  zipCode:              joi.string().regex(/[1-9][0-9]{3}[A-Z]{2}/),
  houseNumber:          joi.number().min(1).max(99999),
  houseNumberExtension: joi.string().allow('').max(5),
  room:                 joi.string().allow('').max(64),
});

export const dateSchemaRequired = joi.string().regex(/^\d{4}-\d{2}-\d{2}$/);

export const dateSchemaOptional = dateSchemaRequired.allow(null);

export const serviceUidFieldSchema = joi.string().min(1);

export const cancellationCodes = pickErrorCodes(key => key.startsWith('CAN-'));

export const changeCodes = pickErrorCodes(key => key.startsWith('CH-'));

const schemaPartAddress = {
  address: addressSchema,
};

const schemaPartLineId = {
  lineId: joi.number(),
};

/**
 * Given the "base schema", generate two descendent schemas - one with the address fields
 * and one with the lineId field.
 * @param baseSchema
 */
export function splitSchemaLocationType(baseSchema?: ObjectSchema): [ObjectSchema, ObjectSchema] {
  if (!baseSchema) baseSchema = joi.object({});
  return [
    baseSchema.append(schemaPartAddress),
    baseSchema.append(schemaPartLineId),
  ];
}
