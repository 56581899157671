import React from "react";
import {gql, useQuery} from "@apollo/client";

import {Json} from "../Json";
import {QueryResult} from "../../QueryResult";

export const OrderEventDetails: React.FC<{ id: number }> = ({id}) => {

    const {loading, data, error} = useQuery(gql`query($id: Int!) {
        accessOrderEvent(id: $id) {
            attributes
        }
    }`, {
      variables: {id},
    });

  return <QueryResult
    loading={loading}
    error={error}
  >
    {() => <Json json={data.accessOrderEvent.attributes}/>}
  </QueryResult>;

};
