import React from "react";
import ReactJson, {ReactJsonViewProps} from "react-json-view";

export const Json: React.FC<{
  json: any;
  collapsed?: number;
  viewProps?: Partial<ReactJsonViewProps>;
}> = ({json, collapsed, viewProps}) => {
  if (!json) return <span style={{opacity: .5}}>(no data)</span>
  return <ReactJson
    theme={"bright:inverted"}
    name={null}
    collapsed={collapsed}
    src={json}
    style={{
      borderRadius: 2,
      padding:      8,
      fontSize:     12,
      border:       '1px solid #eee',
    }}
    displayDataTypes={false}
    displayObjectSize={false}
    enableClipboard={false}
    {...viewProps}
  />;
};
