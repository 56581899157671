import React from "react";
import {Alert} from "antd";
import {ApiOrderEntity} from "../../orders/types";

export const HandoverStatus: React.FC<{ order: ApiOrderEntity }> = ({order}) => {
  if (!order.handover) return null;
  return <Alert
    message={<strong>Handover received</strong>}
    type={"success"}
    description={`Delivery date set for ${order.handover.deliveryDateTime}`}
    showIcon={true}
  />;
};
