import React, {ComponentProps} from "react";
import {Controller} from "react-hook-form";
import {InputNumber} from "antd";
import {InputRow} from "../../InputRow";

export const LineIdInputCard: React.FC<{ control: ComponentProps<typeof Controller>['control'] }> = ({control}) => {

  return <InputRow control={control} columns={[{
    id:     "lineId",
    label:  "LineID",
    width:  '100px',
    render: props => <InputNumber style={{
      width: '100%',
    }} {...props}/>,
  }]}/>
};
