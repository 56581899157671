"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.deepDiff = exports.unchanged = void 0;
var lodash_1 = require("lodash");
exports.unchanged = Symbol('unchanged');
/**
 * Find the recursive diff between two objects. When a property is present in `input` but absent in `output`,
 * it will show up as an explicitly defined property with the value of `undefined` in the diff.
 */
function deepDiff(input, output) {
    if (input === output) {
        // If the input values are exactly equal, return a special symbol indicating no change.
        return exports.unchanged;
    }
    if (typeof input === 'object' && typeof output === 'object') {
        // Union all keys so we know which ones to consider, and invoke `deepDiff` (recursively) for every key.
        var allKeys = lodash_1.union(Object.keys(input), Object.keys(output));
        var diff = allKeys.reduce(function (carry, key) {
            var _a;
            var diff = deepDiff(input[key], output[key]);
            // If none of the nested values are different, move on to the next key
            if (diff === exports.unchanged)
                return carry;
            // Otherwise assign the diff to the respective key
            else
                return __assign(__assign({}, carry), (_a = {}, _a[key] = diff, _a));
        }, {});
        if (Object.keys(diff).length === 0)
            return exports.unchanged;
        else
            return diff;
    }
    // If neither of the above conditions are met, we can be sure the value has changed.
    return output;
}
exports.deepDiff = deepDiff;
