import joi from "joi";

import {dateSchemaRequired} from "./common";
import {portConstructionOrMigration} from "./constructionOrder";

const extraFields = {
  singleConsumer: joi.boolean(),
  executionDate:  dateSchemaRequired,
};

export const operatorMigrationOrderSchemaWithAddress = portConstructionOrMigration[0].append({...extraFields});

export const operatorMigrationOrderSchemaWithLineId = portConstructionOrMigration[1].append({...extraFields});
