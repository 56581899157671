import React, {CSSProperties} from "react";
import {OrderType} from "../../../shared-types/orderDefinitions";
import {Tag} from "antd";
import {capitalize} from "../../../util/case";
import {translateOrderType} from "../../data/orderTypeMap";

export const OrderTypeTag: React.FC<{ type: OrderType, style?: CSSProperties }> = ({type, style}) => {
  return <Tag style={{
    fontWeight: 'bold',
    ...style,
  }}>{capitalize(translateOrderType(type))}</Tag>;
};
