import dayjs from "dayjs";

/**
 * Order the given set of items by their `createdAt` string fields as parsed by `dayjs`.
 * @param order
 * @param data
 */
export function sortByCreatedAt<T extends { createdAt: string }>(data: T[], order: 'ASC' | 'DESC' = "DESC"): T[] {
  const orderAdjust = order === "DESC" ? -1 : 1;
  // Results from GQL are immutable and this is the most common use case so we'll just shallow clone the array below.
  return [...data].sort((a, b) => {
    const unixA = dayjs(a.createdAt).unix();
    const unixB = dayjs(b.createdAt).unix();
    return orderAdjust * ((unixA > unixB) ? 1 : ((unixB > unixA) ? -1 : 0));
  });
}
