import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import {Button} from "antd";
import {gql, useMutation} from "@apollo/client";
import {notifyErrorGeneric} from "../../util/notifications";

export const TaskSetOverviewSyncButton: React.FC<{}> = ({children}) => {

  const [loading, setLoading] = useState(false);

    const [run] = useMutation(gql`mutation SyncTaskSetOverview {
        syncTaskSets(fromEpoch: true)
    }`, {
      onError:        error => notifyErrorGeneric(error.message),
      refetchQueries: ['TaskSetOverview'],
    });

  return <Button
    onClick={async () => {
      if (loading) return;
      setLoading(true);
      await run();
      setLoading(false);
    }}
    loading={loading}
  >
    <FontAwesomeIcon style={{
      fontSize:    '90%',
      marginRight: '.4rem',
    }} icon={faSync}/>
    Sync all
  </Button>;
};
