"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.collection = exports.ftu = exports.pattern = exports.range = exports.max = void 0;
var bpAttributeRoutines_1 = require("./bpAttributeRoutines");
/**
 * String max length validator
 * @param limit
 */
function max(limit) {
    return function (input) {
        return input.length > limit ? "Max. " + limit + " characters" : null;
    };
}
exports.max = max;
/**
 * Numeric range but may also be inputted as a string
 * @param min
 * @param max
 */
function range(min, max) {
    return function (input) {
        if (input === '' || !Number.isFinite(Number(input)))
            return 'Must be a number';
        if (Number(input) < min || Number(input) > max)
            return "Must be between " + min + " and " + max + " (inclusive)";
        return null;
    };
}
exports.range = range;
/**
 * Regex pattern match validator
 * @param pattern
 */
function pattern(pattern) {
    return function (input) {
        return pattern.test(input) ? null : "Expected to match pattern: " + pattern.toString();
    };
}
exports.pattern = pattern;
function ftu() {
    return function (input) {
        // TODO implement strictly (for now we'll just defer to the Reggefiber validation for this check)
        return null;
    };
}
exports.ftu = ftu;
function transformKeys(object, transformKey) {
    return Object.keys(object).reduce(function (carry, key) {
        var _a;
        return __assign(__assign({}, carry), (_a = {}, _a[transformKey(key)] = object[key], _a));
    }, {});
}
/**
 * Update the given validation result object to represent an e.g. nested validation result by transforming
 * the field keys using the given transformation function.
 * Eg.
 * ```
 * transformKey = field => `COLLECTION[0][${field}]`;
 * ```
 * @param result
 * @param transformKey
 */
function transformResultNested(result, transformKey) {
    return {
        illegalValues: transformKeys(result.illegalValues, transformKey),
        illegalMutations: result.illegalMutations.map(transformKey),
        missing: result.missing.map(transformKey),
        errors: transformKeys(result.errors, transformKey),
        optional: result.optional.map(transformKey)
    };
}
/**
 * Attribute validator for a collection of "structured attributes". Structure attributes are always
 * given as "arrays" in the input (even when there is none, or just one) and cast to attributes as such:
 * `CONTACT[0]ZIPCODE = 1234AB`.
 * @param min Min number of occurrences or 0 for entirely optional
 * @param max Max number of occurrences (inclusive) or undefined for no upper bound
 * @param schema Schema for a single occurrence
 */
function collection(min, max, schema) {
    var validator = function (collection, key) {
        if (!Array.isArray(collection))
            return "Expected an array";
        if (collection.length < min)
            return "Expected at least " + min + " element(s) with at least one sub-attribute (e.g. \"" + key + "[0][" + schema[0].key + "]\")";
        if (max !== undefined && collection.length > max)
            return "Expected no more than " + max + " element(s)";
        var _loop_1 = function (i) {
            var result = bpAttributeRoutines_1.validate(schema, collection[i]);
            if (!bpAttributeRoutines_1.isValid(result))
                return { value: transformResultNested(result, function (field) { return key + "[" + i + "][" + field + "]"; }) };
        };
        for (var i = 0; i < collection.length; i++) {
            var state_1 = _loop_1(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        return null;
    };
    validator.collection = true;
    return validator;
}
exports.collection = collection;
