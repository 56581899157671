import React from "react";
import {faCheck, faHistory} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {Alert, Button, ButtonProps, Modal} from "antd";

import {ApiTaskEntity} from "./types";
import {SmallButtonIcon} from "../SmallButtonIcon";
import {EditAttributesButton} from "./SubTaskDetails";
import {gql, useMutation} from "@apollo/client";
import {notifyErrorGeneric} from "../../util/notifications";
import {capitalize} from "../../util/case";

const SolveButton: React.FC<ButtonProps & {
  mutation: string;
  task: ApiTaskEntity;
  enabled: boolean;
  targetStatus: string;
  actionName: string;
  icon: IconProp;
}> = ({mutation, task, enabled, targetStatus, actionName, icon, ...buttonProps}) => {

    const [run] = useMutation(gql`mutation ${capitalize(mutation)}($id: Int!) {
        ${mutation}(id: $id) {id}
    }`, {
      variables: {id: task.id},
      onError:   error => notifyErrorGeneric(error.message),
    });

  return <Button
    {...buttonProps}
    disabled={!enabled}
    onClick={() => {
      Modal.confirm({
        title:   'Are you sure?',
        content: <>This {task.type} task will be marked "{targetStatus}", by dispatching
          a <code>{actionName}</code> message to Reggefiber. Please note that it might not
          immediately take effect.</>,
        onOk:    () => run(),
      });
    }}
  >
    <SmallButtonIcon icon={icon}/>
    Mark {targetStatus}
  </Button>;
}

export const TaskAttributesSummary: React.FC<{ task: ApiTaskEntity }> = ({task}) => {

  const pendingAttributes = Object.keys(task.pendingAttributes || {});
  const canSolve          = !task.actualAttributeErrors && !pendingAttributes.length;

  return <div className={'task-attributes-summary'}>
    <div className={'buttons'}>
      <EditAttributesButton
        taskId={task.id}
        errors={task.projectedAttributeErrors}
        style={{marginRight: '.5rem'}}
      />
      {(() => {
        if (task.status === "CLOSED") return null;
        if (task.status === "SOLVED") return <SolveButton
          mutation={'markTaskUnsolved'}
          task={task}
          enabled={true}
          targetStatus={'unsolved'}
          actionName={'taskUnSolve'}
          type={'dashed'}
          icon={faHistory}
        />;
        else return <SolveButton
          type={'primary'}
          mutation={'markTaskSolved'}
          actionName={'taskSolve'}
          task={task}
          enabled={canSolve}
          targetStatus={'solved'}
          icon={faCheck}
        />;
      })()}
    </div>
    {pendingAttributes.length ? <Alert
      className={'pending-info'}
      style={{marginTop: '.7rem'}}
      message={'Attributes awaiting synchronization'}
      description={<>
        <p className={"hint"}>Changes, additions or deletions which have been saved locally, but have not yet been
          acknowledged by Reggefiber might prevent you from marking this task solved.</p>
        <div className={'list'}>
          {pendingAttributes.map(attribute => <code key={attribute}>{attribute}</code>)}
        </div>
      </>}
    /> : null}
  </div>;
};
