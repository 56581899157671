import React from "react";
import {StylableInputSliceProps} from "./common";
import {InputRow} from "../../InputRow";
import {Radio} from "antd";

export const RadioGroupInputSlice: React.FC<{ options: string[], label?: string, id: string, width?: string, } & StylableInputSliceProps> = (
  {
    options, label, id, width, errors, control,
  }) => <InputRow
  errors={errors}
  control={control}
  columns={[{
    id, label, width,
    render: ({onChange, ...props}) => <Radio.Group
      onChange={e => onChange(e.target.value)}
      {...props}
    >
      {options.map(option => <Radio.Button key={option} value={option}>{option}</Radio.Button>)}
    </Radio.Group>,
  }]}
/>;
