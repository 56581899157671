import React from "react";
import {ApolloProvider} from "@apollo/client";
import {Redirect, Route, Switch} from "react-router-dom";
import {ConnectedRouter} from "connected-react-router";
import {Provider} from "react-redux";

import {history, store} from "./app/store";
import {apolloClient} from "./apolloClient";
import {LoginPage} from "./components/pages/Login";
import {Dashboard} from "./components/Dashboard";
import {useIsLoggedIn} from "./hooks/useIsLoggedIn";

import "./styles/app.scss";

const LoginGuard: React.FC = ({children}) => {
  const loggedIn = useIsLoggedIn();
  return <Switch>
    <Route path={"/login"}>
      <LoginPage/>
    </Route>
    <Route>
      {loggedIn ? children : <Redirect push={true} to={"/login"}/>}
    </Route>
  </Switch>;
};

const ContextWrapper: React.FC = ({children}) => {
  return <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        {children}
      </ConnectedRouter>
    </Provider>
  </ApolloProvider>;
};

const App: React.FC = () => (
  <ContextWrapper>
    <LoginGuard>
      <Dashboard/>
    </LoginGuard>
  </ContextWrapper>
);

export default App;
