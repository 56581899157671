"use strict";
exports.__esModule = true;
exports.BpProcessStructureAttributes = void 0;
var bpAttributeValidators_1 = require("./bpAttributeValidators");
var BpProcessStructureAttributes;
(function (BpProcessStructureAttributes) {
    /*
     Structure attributes are 'nested' objects occurring as arrays and will have several fields of their
     own with properties similar to those of top level process attributes. Here we'll declare them so
     they can be validated in a similar way to the top level attributes.
     */
    /**
     * Generate a "loose" declaration for an attribute. The attributes using this are to be specified more strictly.
     * Once I got past the "CONTACT" structure attribute, I decided to do these "loose" declarations first for the
     * remaining structure attribute specifications until we're certain they're going to be actively used.
     */
    function fastAttr(key, reqOpen, reqClose, mutInit, mutContr, descr) {
        return {
            key: key,
            requiredOnOpen: !!reqOpen,
            requiredOnClose: !!reqClose,
            mutableByInitiator: !!mutInit,
            mutableByContractor: !!mutContr,
            format: function () { return null; },
            description: descr || key
        };
    }
    BpProcessStructureAttributes.CONTACT = [{
            key: 'CONTACT_TYPE',
            requiredOnOpen: true,
            requiredOnClose: false,
            mutableByInitiator: false,
            mutableByContractor: false,
            format: bpAttributeValidators_1.pattern(/^(Aanvrager|Netbeheerder)$/),
            description: "“Aanvrager” (uit A.nl/LIP/Gemma aanvraag), ”Netbeheerder” (details van de Werk Voorbereider)"
        }, {
            key: 'COMPANYNAME',
            requiredOnOpen: false,
            requiredOnClose: false,
            mutableByInitiator: true,
            mutableByContractor: true,
            format: bpAttributeValidators_1.max(50),
            description: 'Bedrijfsnaam'
        }, {
            key: 'SALUTATION',
            requiredOnOpen: false,
            requiredOnClose: false,
            mutableByInitiator: true,
            mutableByContractor: true,
            format: bpAttributeValidators_1.max(10),
            description: 'Aanhef'
        }, {
            key: 'FIRSTNAME',
            requiredOnOpen: false,
            requiredOnClose: false,
            mutableByInitiator: true,
            mutableByContractor: true,
            format: bpAttributeValidators_1.max(128),
            description: 'Naam'
        }, {
            key: 'INTERCALATION',
            requiredOnOpen: false,
            requiredOnClose: false,
            mutableByInitiator: true,
            mutableByContractor: true,
            format: bpAttributeValidators_1.max(64),
            description: 'Tussenvoegsel'
        }, {
            key: 'LASTNAME',
            requiredOnOpen: false,
            requiredOnClose: false,
            mutableByInitiator: true,
            mutableByContractor: true,
            format: bpAttributeValidators_1.max(128),
            description: 'Achternaam'
        }, {
            key: 'ZIPCODE',
            requiredOnOpen: false,
            requiredOnClose: false,
            mutableByInitiator: true,
            mutableByContractor: true,
            format: bpAttributeValidators_1.max(7),
            description: 'Postcode'
        }, {
            key: 'HOUSENUMBER',
            requiredOnOpen: false,
            requiredOnClose: false,
            mutableByInitiator: true,
            mutableByContractor: true,
            format: bpAttributeValidators_1.range(1, 99999),
            description: 'Achternaam'
        }, {
            key: 'EXTENSION',
            requiredOnOpen: false,
            requiredOnClose: false,
            mutableByInitiator: true,
            mutableByContractor: true,
            format: bpAttributeValidators_1.max(10),
            description: 'Toevoeging'
        }, {
            key: 'STREET',
            requiredOnOpen: false,
            requiredOnClose: false,
            mutableByInitiator: true,
            mutableByContractor: true,
            format: bpAttributeValidators_1.max(50),
            description: 'Straat'
        }, {
            key: 'CITY',
            requiredOnOpen: false,
            requiredOnClose: false,
            mutableByInitiator: true,
            mutableByContractor: true,
            format: bpAttributeValidators_1.max(100),
            description: 'Woonplaats'
        }, {
            key: 'PHONENUMBER',
            requiredOnOpen: false,
            requiredOnClose: false,
            mutableByInitiator: true,
            mutableByContractor: true,
            format: bpAttributeValidators_1.max(100),
            description: 'Telefoonnummer'
        }, {
            key: 'ADDITIONALPHONENUMBER',
            requiredOnOpen: false,
            requiredOnClose: false,
            mutableByInitiator: true,
            mutableByContractor: true,
            format: bpAttributeValidators_1.max(100),
            description: 'Telefoonnummer 2'
        }, {
            key: 'EMAIL',
            requiredOnOpen: false,
            requiredOnClose: false,
            mutableByInitiator: true,
            mutableByContractor: true,
            format: bpAttributeValidators_1.max(130),
            description: 'Email adres'
        }];
    BpProcessStructureAttributes.CONNECTION = [
        fastAttr('ZIPCODE', 1, 0, 0, 0),
        fastAttr('HOUSENUMBER', 1, 0, 0, 0),
        fastAttr('EXTENSION', 0, 0, 0, 0),
        fastAttr('ROOM', 1, 0, 0, 0),
        fastAttr('WISHEDWEEK', 1, 0, 0, 0),
        fastAttr('WISHEDYEAR', 1, 0, 0, 0),
    ];
    BpProcessStructureAttributes.GPS = [
        fastAttr('LATITUDE', 1, 0, 0, 0),
        fastAttr('LONGITUDE', 1, 0, 0, 0),
    ];
    BpProcessStructureAttributes.CABLE = [
        fastAttr('CABLE_ID', 1, 0, 0, 0),
        fastAttr('CABLE_TYPE', 1, 0, 0, 0),
    ];
    BpProcessStructureAttributes.RELATEDTASKSET = [
        fastAttr('CONTRACTOR', 1, 0, 0, 0),
        fastAttr('TASKSETID', 1, 0, 0, 0),
        fastAttr('TASKSETYPE', 1, 0, 0, 0),
        fastAttr('SERVICETYPE', 1, 0, 0, 0),
        fastAttr('REGISTRATION_DATE', 1, 0, 0, 0),
        fastAttr('CLOSURE_DATE', 1, 0, 0, 0),
        fastAttr('SOLUTIONCODE_LEVEL1', 1, 0, 0, 0),
        fastAttr('SOLUTIONCODE_LEVEL2', 1, 0, 0, 0),
        fastAttr('SOLUTIONCODE_LEVEL3', 1, 0, 0, 0),
        fastAttr('CLOSECODE', 1, 0, 0, 0),
    ];
    BpProcessStructureAttributes.SOLUTION = [
        fastAttr('CODE_LEVEL1', 0, 1, 0, 1),
        fastAttr('CODE_LEVEL2', 0, 1, 0, 1),
        fastAttr('CODE_LEVEL3', 0, 1, 0, 1),
        fastAttr('DETAILS1', 0, 0, 0, 1),
        fastAttr('DETAILS2', 0, 0, 0, 1),
        fastAttr('DETAILS3', 0, 0, 0, 1),
        fastAttr('DETAILS4', 0, 0, 0, 1),
    ];
    BpProcessStructureAttributes.ORGANISATION = [
        fastAttr('COMPANYNAME', 1, 0, 0, 0),
        fastAttr('COMPANYDIVISION', 1, 0, 0, 0),
        fastAttr('PHONENUMBER', 0, 0, 0, 0),
        fastAttr('ADDITIONALPHONENUMBER', 0, 0, 0, 0),
        fastAttr('EMAIL', 0, 0, 0, 0),
    ];
    BpProcessStructureAttributes.REPORTER = [
        fastAttr('LASTNAME', 0, 0, 0, 0),
        fastAttr('COMPANY', 0, 0, 0, 0),
        fastAttr('ZIPCODE', 0, 0, 0, 0),
        fastAttr('HOUSENUMBER', 0, 0, 0, 0),
        fastAttr('EXTENSION', 0, 0, 0, 0),
        fastAttr('STREET', 0, 0, 0, 0),
        fastAttr('CITY', 0, 0, 0, 0),
        fastAttr('PHONENUMBER', 0, 0, 0, 0),
    ];
    BpProcessStructureAttributes.PROJECT = [
        fastAttr('NUMBER', 1, 0, 0, 0),
        fastAttr('NAME', 1, 0, 0, 0),
    ];
    BpProcessStructureAttributes.POP = [
        fastAttr('NAME', 1, 0, 0, 0),
    ];
})(BpProcessStructureAttributes = exports.BpProcessStructureAttributes || (exports.BpProcessStructureAttributes = {}));
