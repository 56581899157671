import React from "react";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const SmallButtonIcon: React.FC<{
  icon: IconProp;
}> = ({icon}) => {
  return <FontAwesomeIcon
    icon={icon}
    style={{
      marginRight: '.5rem',
      fontSize:    '90%',
    }}
  />
};
