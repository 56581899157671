import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {RootState} from "./store";

interface AuthState {
  token: string | null,
}

const initialState: AuthState = {
  token: sessionStorage.getItem('session_token'),
};

const authSlice = createSlice({
  name:     'auth',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<{ token: string }>) {
      state.token = action.payload.token;
      sessionStorage.setItem('session_token', action.payload.token);
    },
    logOut(state) {
      state.token = null;
      sessionStorage.removeItem('session_token');
    },
  },
});

export const setToken = authSlice.actions.setToken;
export const logOut   = authSlice.actions.logOut;

export const selectIsLoggedIn = (state: RootState) => !!state.auth.token;

export const authReducer = authSlice.reducer;
