import {useCreateOrder} from "./useCreateOrder";
import {OrderType} from "../shared-types/orderDefinitions";
import {useHandleOrderMutationResult} from "./useHandleOrderMutationResult";

/**
 * Return a callback that can be passed to `react-hook-form`'s `handleSubmit`. The callback will run
 * the appropriate GQL mutation with the form parameters and handle the result. The payload argument
 * given to the callback should adhere to the given input and may contain an additional "handle" key
 * to specify we want to add a generated appointmentId. This variable will be extracted from the
 * input object and passed separately from the parameters.
 * @param inputType
 * @param orderType
 */
export function useSubmitOrderForm(inputType: string, orderType: OrderType) {

  const run = useCreateOrder(inputType, orderType);

  const handleResult = useHandleOrderMutationResult<{
    createOrder: { id: number }
  }>(data => data.createOrder.id);

  return async ({handle, ...fieldValues}: any) => {

    const result = await run({variables: {parameters: fieldValues, handle}});

    handleResult(result);
  }
}
