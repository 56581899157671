import React from "react";
import {ApolloError} from "@apollo/client";
import {Alert, Skeleton} from "antd";

export function QueryResult({loading, error, children: Render}: {
  loading: boolean, error?: ApolloError, children: React.FC,
}) {
  if (loading) return <Skeleton/>;
  if (error) return <Alert message={error.message} type={"error"}/>;
  return <Render/>;
}
