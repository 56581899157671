import {useDispatch} from "react-redux";
import {push} from "connected-react-router";

import {AddressValue} from "../components/forms/order/controls/AddressInput";
import {setFormIntent} from "../app/formIntentSlice";
import {FORM_AVAILABILITY} from "../components/forms/AvailabilityForm";

export function useTriggerAvailabilityLookup(): (address: AddressValue) => void {
  const dispatch = useDispatch();
  return (address: AddressValue) => {
    dispatch(setFormIntent({form: FORM_AVAILABILITY, values: {address}}));
    dispatch(push('/availability'));
  };
}
